import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client-service.service';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';
import { windowTime } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown'
import { JsonPipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';


declare var jQuery: any;

class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-ctsrequest',
  templateUrl: './ctsrequest.component.html',
  styleUrls: ['./ctsrequest.component.css']
})
export class CtsrequestComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  mindate: any;
  maxdate: any;
  term: any;
  threemonthsdate: any;
  oneyeardate: any;
  viewID: any;
  getallcontactperson: any[];
  public alldata = [];
  public validation = 'any';
  public siteData = [];
  public subcatdata = []
  public subcat = [];
  public accountdata = [];
  public addres = [];
  public show = false;
  public history = [];
  public alluser: any = [];
  public complain = false;
  product: any = [];
  assignuser: any = [];
  getCategoryList: any = [];
  services = false;
  divisonid: any;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;
  ctstsiteid = '';
  completeasperservices: any = [];
  ticketlistuserlist: any = [];
  showassign: boolean = true;
  siteidonsavesite: any = [];
  sitename = false;
  issitename = '';
  contactpersonlist: any = [];
  viewcontactperson: any = [];
  existingsite = false;
  newsite = false;
  substatus: any = [];
  influencerCategories = [
    {id:28, name: 'Architect'},
    {id:29, name: 'Engineer'},
    {id:30, name: 'Contractor'},
    {id:31, name: 'Dealer'},
    {id:32, name: 'Retailer'},
  ];
  errorMsgForAssignToUser = '';

  constructor(private http: HttpClientService, private spinner: NgxSpinnerService, private route: Router) {
    this.divisonid = sessionStorage.getItem('divison');
    this.getCategoryfun();
    this.getAllCtsTicket();
    //  this.getCategory();
    //this. getInfluncerBrand();
    this.getSubCategory();
    this.getAllUserHierarchy();
    this.previousDateDisbaled();
    this.threemonthsfun();
    this.oneyeardatefun();
    //this.getAllSubstatus();
    //this.getAllInfluncer();
  }

  // ************************* this is for add site modal
  pincodedata: any = [];
  site:any = {
    name: '',
    constuctionStage: '',
    geographyMasterId: '',
    geogId: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    pinCode: '',
    cityName: '',
    districtName: '',
    stateName: '',
    stateId: '',
    ihbName2: '',
    ihbContact2: '',
    ihbCategory2: '',
    ihbName3: '',
    ihbContact3: '',
    ihbCategory3: '',
    ihbName4: '',
    ihbContact4: '',
    ihbCategory4: '',
    contactpersondetails: [
      // { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: 'Architect' },
      { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' },
      // { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: 'IHB' },
      // { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: 'Contractor' }
    ],
    contactpersoncategory: [
      { id: 1, name: 'Architect' },
      { id: 2, name: 'IHB' },
      { id: 3, name: 'Contractor' },
      { id: 4, name: 'Engineer' },
      { id: 5, name: 'Dealer' },
      { id: 6, name: 'Retailer' }
    ],
    assign_to_user:{
      name:'',
      userId:'',
      loginId:'',
    },
    primarySsaDmo:'',
    createdById:'',
    primaryCts:'',
  }

  readonly = false;
  rescheduledate = false;

  //********************** This for Influencer
  influncer = {
    categoryId: '',
    name: '',
    tier: '',
    preferredBrand: '',
    monthlyPotentialMt: '',
    tradeSitesNumber: '',
    contact: '',
    email: '',
    status: '',
    pincode: '',
    state: '',
    city: '',
    district: '',
    statecode: '',
    created_by_user:{
      name:'',
      userId:'',
      loginId:'',
    }
  }

  // getAllSubstatus(){
  //   this.http.get('/ctslead/ctssubstatus').subscribe(data=>{
  //     this.substatus=data.data;
  //     alert(this.substatus)
  //   })
  // }





  // substatus = [
  //  // { id: 4, name: 'Completed as per request' }, 
  //   { id: 5, name: 'Completed With Services' },
  //   { id: 6, name: 'Rescheduled' }, 
  //   { id: 7, name: 'Invalid Request' }, 

  //   { id: 25, name: 'Resolved but not retained'},
  //   { id: 26, name: 'Resolved and retained'}
  // ];

  assignCatlist = [
    { id: 1, name: 'Trade' },
    { id: 2, name: 'Non Trade' },
    { id: 3, name: 'CTS' },
  ];


  category = [{ id: 1, name: 'Service' }, { id: 2, name: 'Complaint' }]

  degree = {
    platform:'',
    assignToId: '',
    assignto: '',
    // addressLine1: "650",
    services: '',
    categoryId: '',
    comment: '',
    contactName: '',
    contact: '',
    sitecategory: '',
    contactCategory: '',
    subCategoryId: '',
    stageConstruction: '',
    preferredDate: '',
    ctsSiteId: '',
    siteid: '',
    addressLine1: '',
    departmentid: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    pincode: '',
    stateId: '',
    cityId: '',
    ctsGeogId: '',
    districtId: '',
    ihbName: '',
    ihbContatct: '',
    name: '',
    StatusId: '',
    subStatusId: '',
    statusId: 1,
    ticketId: '',
    batchNumber: '',
    batchProducts: '',
    purchaseDate: '',
    supplierName: '',
    supplierNumber: '',
    rescheduledate: '',
    plantname: '',
    complainquantity: ''
  }

  viewticket = {
    ticketnm: '',
    assignto: '',
    createdby: '',
    status: '',
    substatus: '',
    ticketcategory: '',
    ticketsubcat: '',
    accountcontact: '',
    accountname: '',
    sitedetails: '',
    state: '',
    city: '',
    contactperson: '',
    phno: '',
    cnstrstage: '',
    desc: '',
    prefferdDate: '',
    catsubcat: '',
    ticketlistid: '',
    plantname: '',
    complainquantity: '',
    distrct: '',
    accountCategory: '',
    status_substtus: '',
    constuctionStage: '',
    description: '',
    catid: ''
  }

  disbtn = false;

  // getCategory(){
  //   this.http.get('/ctscategory/category').subscribe(data=>{
  //     if(data.status=='200'){
  //       this.cat=data.data
  //    alert(this.cat)
  //     }

  //     });
  // }

  allchilduser: any = [];
  getAllUserHierarchy() {
    this.http.get('/user/get-user-hierarchy/' + sessionStorage.getItem("parentpositioncode")).subscribe(data => {
      if (data.status == '200') {
        this.allchilduser = data.data;
        // console.log(this.allchilduser);
      }
    });

  }


  readonlycondition() {
    if (this.influncer.categoryId == '33') {
      this.influncer.tradeSitesNumber = '1';
      this.readonly = true;
    } else {
      this.readonly = false;
    }
  }

  addcontactperson() {
    this.site.contactpersondetails.push({ ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' });
    // console.log(this.site.contactpersondetails);
  }

  removecontactperson(index) {
    if (index > 2) {
      this.site.contactpersondetails.splice(index, 1);
    }
  }

  showupdatebutton = true;
  updatevalidationmodal() {
    this.showupdatebutton = true;
  }

  getSubCategory() {
    this.http.get('/ctscategory/sub-category').subscribe(data => {
      if (data.status == '200') {
        this.subcatdata = data.data
      }

    });
  }

  getAllSite() {
    if (this.degree.contact.length == 10)
      this.http.get('/site/get-allsites/' + this.degree.contact).subscribe(data => {
        if (data.status == '200') {
          this.siteData = data.data;
          // this.addres=data.data.address;
        }
      });
  }


  // past date disabled
  previousDateDisbaled() {
    let todaysDate = new Date();
    let year = todaysDate.getFullYear();
    let month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);
    let day = ("0" + todaysDate.getDate()).slice(-2);
    this.maxdate = (year + "-" + month + "-" + day);
    this.mindate = (year + "-" + month + "-" + day);
  };

  threemonthsfun() {
    let todaysDate = new Date();
    todaysDate.setMonth(todaysDate.getMonth() - 3);
    let year = todaysDate.getFullYear();
    let month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);
    let day = ("0" + todaysDate.getDate()).slice(-2);
    this.threemonthsdate = (year + "-" + month + "-" + day);
  }

  oneyeardatefun() {
    let todaysDate = new Date();
    todaysDate.setMonth(todaysDate.getMonth() - 12);
    let year = todaysDate.getFullYear();
    let month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);
    let day = ("0" + todaysDate.getDate()).slice(-2);
    this.oneyeardate = (year + "-" + month + "-" + day);
  }

  resetupdate() {
    this.degree.preferredDate = '';
  }

  // getInfluncerBrand(){
  //   this.http.get('/ctslead/getBrand').subscribe(data=>{
  //     this.dropdownList= data.data;
  //   })
  // }
  ngOnInit() {
    this.dropdownList = [
      { item_id: 1, item_text: 'JK' },
      { item_id: 2, item_text: 'AMBUJA' },
      { item_id: 3, item_text: 'ACC' },
      { item_id: 4, item_text: 'SANGHI' },
      { item_id: 5, item_text: 'ULTRATECH' },
      { item_id: 6, item_text: 'WONDER' },
      { item_id: 7, item_text: 'BIRLA' },
      { item_id: 8, item_text: 'PERFECT PLUS' },
      { item_id: 9, item_text: 'NUVOCO' },
      { item_id: 10, item_text: 'MYCEM' },
      { item_id: 11, item_text: 'PRISM' },
      { item_id: 12, item_text: 'BANGUR' },
      { item_id: 13, item_text: 'MANGALAM' },
      { item_id: 14, item_text: 'ROCK' },
      { item_id: 15, item_text: 'SHREE' },
      { item_id: 16, item_text: 'SIDHEE' },
      { item_id: 17, item_text: 'INDIA' },
      { item_id: 18, item_text: 'SHRIRAM' },
      { item_id: 19, item_text: 'NIRMAX' },
      { item_id: 20, item_text: 'VASODATTA' },
      { item_id: 21, item_text: 'ZUARI' },
      { item_id: 22, item_text: 'RAMCO' },
      { item_id: 23, item_text: 'DALMIA' },
      { item_id: 24, item_text: 'KESORAM' },
      { item_id: 25, item_text: 'JSW' },
      { item_id: 26, item_text: 'CHETTINAD' },
      { item_id: 27, item_text: 'PENNA' },
      { item_id: 28, item_text: 'JAYPEE' },
      { item_id: 29, item_text: 'DURATON' },
      { item_id: 30, item_text: 'KAMAL' },
      { item_id: 31, item_text: 'Other' }
    ];
    // // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

  }

  onItemSelect(item: any) {
    // for(let i=0;i<item.length;i++){
    //   this.influncer.preferredBrand=  item[i].item_text+","+ this.influncer.preferredBrand
    //   }
    this.influncer.preferredBrand = JSON.stringify(item['brand']) + "," + this.influncer.preferredBrand;
    // console.log("onItemSelect == " + this.influncer.preferredBrand);
  }
  onSelectAll(items: any) {
    for (let i = 0; i < items.length; i++) {
      this.influncer.preferredBrand = items[i].brand + "," + this.influncer.preferredBrand
    }
    // console.log("onSelectAll == " + this.influncer.preferredBrand)
  }

  //sessionStorage.getItem("loginId")
  getAllCtsTicket() {
    const ParseHeaders = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=UTF-8'
      })
  };
  const that = this;
  this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      responsive: true,
      processing: true,
      searching:false,
      ajax: (dataTablesParameters: any, callback) => {
          that.http.post('/ctsticket/ticket-datatables-view', dataTablesParameters, ParseHeaders).subscribe(resp => {
            
              this.alldata = [];
              this.alldata = resp.data;
              callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: []
              });
          });
      },
      order:[[0, "desc"]],
      columns: [
          { data: "id", name: "S.no" },
          { data: "ticketNumber", name: "Ticket No/Date" },
          // { data: "creationTime", name: "Date" },
          { data: "accountName", name: "Account Name/Number/Cateogory" },
          // { data: "accountContact", name: "Number" },
          // { data: "accountCategory", name: "Cateogory" },
          // { data: "user.firstName", name: "Create by" },
          { data: "category.name", name: "Ticket Cateogory/Sub Cateogory" },
          // { data: "subCategory.name", name: "Sub Cateogory" },
          { data: "ctsSiteContact.address.city", name: "City" },
          { data: "ctsStatus.name", name: "Status" },
          { data: "ctsSubStatus.name", name: "Sub Status" },
          { data: "createdByUser.firstName", name: "Created By/Assign To" },
          // { data: "assignedToUser.firstName", name: "Assign To" },
      ]
  };
  // console.log("vlbs",this.dtOptions);
  
    // this.spinner.show();
    // this.http.get('/ctsticket/get-assign-created-web/' + sessionStorage.getItem('loginId')).subscribe(data => {
    //   console.log("dfsdvbd", data);
      
    //   this.spinner.hide();
    //   if (data.status == '200') {
    //     this.alldata = data.data;
    //   }
    // });
  }

  checkContact(contact) {
    if (contact.length == 10) {
      this.http.get('/ctsinfluencer/verify-accounts-influencer/' + contact + '/contact/3').subscribe(data => {
        if (data.status == '200') {
          this.degree.contactName = '';
          this.degree.contactCategory = '';
          alert("Influencer Already Exists On This Number.");
          this.influncer.contact = null;
        }
      });
    }
  }

  radioyes = false;
  radiono = false;
  // For Create Ticket on / exiting or new
  checkRedioLead1(e: string): void {
    if (e === 'existing') {
      this.radiono = false;
      this.radioyes = true;
      this.newsite = false;
      this.existingsite = true;
      this.sitename = false;
    } else if (e === 'new') {
      this.radioyes = false;
      this.radiono = true;
      this.newsite = true;
      this.existingsite = false;
      this.sitename = false;
    }
  }



  // checkContact(contact,type) {
  //   if(contact.length==10){
  //   this.http.get("/ctsinfluencer/get-influencer/"+contact).subscribe(data => {
  //     if (data.status != '204' && type=='contact') {
  //       this.degree.contactName = '';
  //       this.degree.contactCategory = '';
  //       alert("Influencer Not Exists, Please Create Influencer."); 
  //     }
  //     else if(data.status != '200' && type!='contact'){
  //       alert("Influencer Already Exists On This Number"); 
  //       this.influncer.contact =  null;

  //     }
  //   });
  // }
  //}

  getDataContact(contact, type) {
    this.degree.categoryId=null;
    this.validationform();
    if (contact != null && type == 'contact' && contact.length == 10) {
      // this.checkContact(contact,type);
      this.http.get('/ctsinfluencer/verify-accounts-influencer/' + contact  + '/' + type + '/3').subscribe(data => {
        if (data.status == '200') {
          this.degree.contact = data.data[0].contact;
          this.degree.contactCategory = data.data[0].category;
          this.degree.contactName = data.data[0].name;

        }
        else if (data.status == '204') {
          this.degree.contactName = '';
          //this.degree.contactCategory = '';
          alert("Influencer Not Exists, Please Create Influencer.");
        }
      });
      this.getAllSite();

    }
    // else if (contact.length > 2) {
    //   this.http.get('/ctsinfluencer/verify-accounts-influencer/' + contact + '/' + type).subscribe(data => {
    //     if (data.status == '200') {
    //       this.degree.contact = data.data[0].contact;
    //       this.degree.contactCategory = data.data[0].category;
    //       this.degree.contactName = data.data[0].name;
    //     }

    //   });
    //   this.getAllSite();
    // }

  }

  getDataContact2(contact, type) {
    this.degree.categoryId=null;
    this.validationform();
    if (contact != null && type == 'contact' && contact.length == 10) {
      // this.checkContact(contact,type);
      this.http.get('/ctsinfluencer/verify-accounts-influencer/' + contact  + '/' + type + '/3').subscribe(data => {
        if (data.status == '200') {
          this.degree.contact = data.data[0].contact;
          this.degree.contactCategory = data.data[0].category;
          this.degree.contactName = data.data[0].name;

        }
        else if (data.status == '204') {
          // this.degree.contactName = '';
          //this.degree.contactCategory = '';
          // alert("Influencer Not Exists, Please Create Influencer.");
        }
      });
      this.getAllSite();

    }
    // else if (contact.length > 2) {
    //   this.http.get('/ctsinfluencer/verify-accounts-influencer/' + contact + '/' + type).subscribe(data => {
    //     if (data.status == '200') {
    //       this.degree.contact = data.data[0].contact;
    //       this.degree.contactCategory = data.data[0].category;
    //       this.degree.contactName = data.data[0].name;
    //     }

    //   });
    //   this.getAllSite();
    // }

  }

  saveticketdisabled = true;
  validationform() {

    if (this.degree.categoryId != '2' && this.degree.contact && this.degree.contactName && this.degree.contactCategory && this.degree.categoryId && this.degree.subCategoryId && this.degree.ctsSiteId) {

      this.saveticketdisabled = false;
    }

    else if (this.degree.categoryId == '2' && this.degree.contact && this.degree.contactName && this.degree.contactCategory && this.degree.categoryId && this.degree.subCategoryId && this.degree.ctsSiteId && this.degree.supplierName && this.degree.supplierNumber && this.degree.complainquantity && this.degree.plantname) {
      this.saveticketdisabled = false;
    }

    else {
      this. saveticketdisabled = true
    }

  }

  creatTicket(form) {
    if (this.degree.contact != null && this.degree.contact.length == 10) {
      this.degree.platform = "web";
      this.http.post('/webctsticket/save', this.degree).subscribe(data => {
        jQuery("#myModal").modal("hide");
        if (data.status == '200') {
          Swal.fire({
            title: 'Ticket Created successfully',
            timer: 3000
          });
          this.show = false;
          this.siteData = null;
          this.degree.categoryId = null;
          this.getAllCtsTicket();
          window.location.reload();
        }
        else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!'
          });
        }
      }, (error) => {
        if (error.status == '500') {
          Swal.fire({
            icon: 'error',
            title: 'Ticket Not Create',
            text: 'Please fil the all (*)Mandatory fields.'
          });
          return false;
        }
      });
      form.reset();
      this.resetForm();
    }
    else if (this.degree.contact != null && this.degree.contact.length < 10) {
      alert("Account Contact number should be 10 digits")
      jQuery("#myModal").modal("show");
      this.degree.categoryId = null
    }
  }


  completeasperrequestservices() {
    this.http.get('/ctslead/all-service/' + sessionStorage.getItem('divison')).subscribe(data => {
      if (data.status == '200') {
        this.completeasperservices = data.data;
        //console.log(this.completeasperservices)
      }
    });
  }



  viewTicket(id) {
    this.updatedata(id);
    this.degree.ticketId = id;
    this.ctstsiteid = id;
    this.http.get('/ctsticket/get/' + id).subscribe(data => {
      if (data.status == '200') {
        this.viewcontactperson = data.data.ctsSiteContact.ctsSiteContactDetails;
        // console.log('view==', this.viewcontactperson);
        this.history = null;
        this.viewticket.ticketnm = data.data.ticketNumber;
        this.viewID = data.data.id;
        this.viewticket.assignto = data.data.assignedToUser.firstName
        this.viewticket.createdby = data.data.assignedToUser.firstName + data.data.assignedToUser.lastName + ' (' + data.data.assignedToUser.employeeId + ')';
        this.viewticket.status = data.data.ctsStatus.name
        this.viewticket.ticketcategory = data.data.category.name;


        this.viewticket.ticketsubcat = data.data.subCategory.name;
        this.viewticket.accountname = data.data.accountName
        this.viewticket.accountcontact = data.data.accountContact
        this.viewticket.accountCategory = data.data.accountCategory;
        this.viewticket.plantname = data.data.plantname;
        this.viewticket.description = data.data.description;
        this.degree.ctsSiteId = data.data.ctsSiteId;
        if (data.data.preferredDate != null)

          this.viewticket.prefferdDate = new Date(data.data.preferredDate).toLocaleDateString();
        this.viewticket.complainquantity = data.data.complainquantity
        this.viewticket.sitedetails = data.data.ctsSiteContact.address.addressLine1
        this.viewticket.catsubcat = this.viewticket.ticketcategory + "/" + this.viewticket.ticketsubcat
        if (data.data.ctsSiteContact.address) {

          this.site.constuctionStage = data.data.ctsSiteContact.constuctionStage;

          this.viewticket.state = data.data.ctsSiteContact.address.state
          this.viewticket.city = data.data.ctsSiteContact.address.city
          this.viewticket.distrct = data.data.ctsSiteContact.address.district
        }
        this.history = data.data.ctsTickeHistories;

        if (sessionStorage.getItem('loginId') != 'admin') {
          let userarray = this.allchilduser;

          if (userarray.find(x => x.id == data.data.assignToId) || sessionStorage.getItem('userid') == data.data.assignToId) {
            // console.log("userherirchy found")
            this.showassign = true;
          }
          else
            this.showassign = false;
        }
        else if (sessionStorage.getItem('loginId') == 'admin')
          this.showassign = true;

      }

      if (data.data.ctsSubStatus)
        this.viewticket.status_substtus = this.viewticket.status + " / " + data.data.ctsSubStatus.name;
      this.categoryWiseStatus(data.data.category.id);
    });
    //alert(this.showassign)


  }

  updatedata(id) {
    if (this.siteData != null) {
      let adddata = this.siteData.filter(i => i.id == id)
      this.degree.ticketId = id;
      // this.degree.categoryId=adddata.category.name;
      // this.degree.StatusId=adddata.ctsStatus.name;
    }



  }

  updateTickte(form) {

    this.http.post('/webctsticket/update', this.degree).subscribe(data => {
      if (data.status == '200') {

        Swal.fire({
          title: 'Ticket Updated successfully',
          timer: 3000
        });
        jQuery("#editModal").modal("hide");
        this.getAllCtsTicket();

      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!'
        });
      }

    });

    this.resetupdateform();

  }


  showaddress(ctsSiteId) {
    // this.siteData.forEach(function(items){
    //   if(ctsSiteId == items.id) {
    //     console.log(items.name);
    //   }
    // });

    this.show = true;
    this.http.get('/site/get-address-sites-web/' + ctsSiteId).subscribe(data => {
      if (data.status == '200') {
        this.contactpersonlist = data.data;
        this.degree.addressLine1 = data.data[0].addressLine1;
        this.degree.addressLine2 = data.data[0].addressLine2;
        this.degree.addressLine3 = data.data[0].addressLine3;
        this.degree.addressLine4 = data.data[0].addressLine4;
        //  this.degree.ctsGeogId = data.data.geographyMaster.id;
        //  this.degree.stageConstruction=data.data[0].constructionstage
        this.degree.pincode = data.data[0].pinCode
        this.degree.stateId = data.data[0].state
        this.degree.districtId = data.data[0].district
        this.degree.cityId = data.data[0].city
        this.degree.ihbName = data.data[0].name
        this.degree.ihbContatct = data.data[0].contact
        this.degree.name = data.data[0].catName
        this.getCreatedByUser_bypincode(this.degree.pincode);
      }
    });


  }

  updateAssign() {
    if (this.ticketlistuserlist.find(x => x.id == this.degree.assignToId).firstName == this.viewticket.assignto) {
      Swal.fire({
        title: 'Ticket is already assign to this user',
        timer: 3000
      });
      jQuery('#assignModel').modal('show');
    }
    else {
      jQuery('#assignModel').modal('hide');
      this.http.post('/webctsticket/update', this.degree).subscribe(data => {
        if (data.status == '200') {

          this.alluser = data.data;
          Swal.fire({
            title: 'Ticket Assigned successfully',
            timer: 3000
          });

          this.getAllCtsTicket();
        }
      });
    }
    this.getAllCtsTicket();
  }


  getAllProduct() {
    this.http.get("/ctslead/all-product/" + sessionStorage.getItem("divison")).subscribe(data => {

      this.product = data.data;
    })
  }

  subcatFilter(id) {
    this.showComplaint(id);
    this.subcat = this.subcatdata.filter(i => i.parentId == id);
  }

  showComplaint(id) {
    if (id == 2) {
      this.complain = true;
      this.getAllProduct();
    }
    else
      this.complain = false;
  }

  assignUser() {
    this.http.get("/ctsinfluencer/get-assign-user/" + sessionStorage.getItem('loginId')).subscribe(data => {
      this.assignuser = data.data;
    });
  }

  assignTochange(name) {
    // console.log('name', name);
    // console.log('ctstsiteid', this.degree.ctsSiteId);
    // console.log('divion', sessionStorage.getItem('divison'));
    this.http.get('/ctslead/getUser/' + name + '/' + this.degree.ctsSiteId + '/' + sessionStorage.getItem('divison')).subscribe(data => {
      if (data.status == '200') {
        if (data.data.length > 0) {
          // console.log('userdsata', data.data)
          this.ticketlistuserlist = data.data;
          this.degree.departmentid = data.data;
        }
        else {
          alert("No User Found")
          this.ticketlistuserlist = null;
        }
      }
    });
  }

  redirectAddSite() {
    //window.open('umber/addsite')
    this.route.navigate(["umber/addsite"]);
    jQuery('#myModal').modal('hide');
  }


  redirectInfluncer() {
    this.route.navigate(["umber/influencer"]);
    jQuery('#myModal').modal('hide');
  }


  getCreatedByUser_bypincode(pincode) {
    this.http.get('/lead-admin/get-se-and-re-user-by-pincode/' + pincode).subscribe(data => {
      if (data.status == '200') {
        // console.log("created by user", data);
        this.influncer["created_by_user"] = data
        this.site.assign_to_user = data;
        this.degree.assignToId = data.userId;
        this.degree.assignto = data.name;
        // console.log("data site", data);
        // console.log("data site2", this.site.assign_to_user);
        this.errorMsgForAssignToUser = "";
      }
      else{
          this.errorMsgForAssignToUser = "User not found";
      }
    });
  }


  state: any = [];
  // This is for add site modal
  getDataByPinCode(pincode) {
    if (pincode.length == 6)
      this.http.get('/geography-master/get-by-pin-code/' + pincode).subscribe(data => {
        if (data.status == '200') {
          this.getCreatedByUser_bypincode(pincode);
          if (data.data.length == 0) {
            Swal.fire({
              title: 'Pincode Is Incorrect',
              timer: 3000
            });
          }

          this.pincodedata = data.data;

          this.site.stateName = data.data[0].stateName;
          this.site.cityName = data.data[0].cityName;
          this.site.districtName = data.data[0].districtName;
          this.influncer.pincode = data.data[0].pinCode;
          this.influncer.state = data.data[0].stateName;
          this.influncer.city = data.data[0].cityName;
          this.influncer.district = data.data[0].districtName;
          this.influncer.statecode = data.data[1].stateCode;

        }


      });
  }

  getCategoryfun() {
    this.http.get('/ctscategory/influencer-category').subscribe(data => {
      if (data.status == '200') {
        this.getCategoryList = data.data;
        // console.log('lonload getcategory',this.getCategoryList)
      }
    });
  }


  createSite(form) {
    for (let i = 0; i < this.site.contactpersondetails.length; i++) {

      if (this.site.contactpersondetails[i].ihbContatct != '' && this.site.contactpersondetails[i].ihbContatct != null && this.site.contactpersondetails[i].ihbContatct.length < 10) {
        alert(this.site.contactpersondetails[i].ihbContatct + " number is less than 10 digit")
        // if(i < this.site.contactpersondetails.length)
        // continue;
        // else
        return;
      }
    }

    this.site.stateId = this.site.stateName;
    this.http.post('/site/save', this.site).subscribe(data => {
      if (data.status == '200') {
        this.issitename = data.data.name;
        this.degree.ctsSiteId = data.data.id
        this.degree.assignToId = this.site.assign_to_user.userId
        this.degree.assignto = this.site.assign_to_user.name
        Swal.fire({
          title: 'Site Created successfully',
          timer: 3000
        });
        jQuery('.closebtn').click();
        this.getAllSite();
        // after create site 
        this.siteidonsavesite = data.data.id;
        this.show = true;
        this.sitename = true;

        this.http.get('/site/get-address-sites-web/' + this.siteidonsavesite).subscribe(data => {
          if (data.status == '200') {
            this.contactpersonlist = data.data;
            this.degree.addressLine1 = data.data[0].addressLine1;
            this.degree.addressLine2 = data.data[0].addressLine2;
            this.degree.addressLine3 = data.data[0].addressLine3;
            this.degree.addressLine4 = data.data[0].addressLine4;
            //  this.degree.ctsGeogId = data.data.geographyMaster.id;
            this.degree.pincode = data.data[0].pinCode
            this.degree.stateId = data.data[0].state
            this.degree.districtId = data.data[0].district
            this.degree.cityId = data.data[0].city

            this.degree.ihbName = data.data[0].name
            this.degree.ihbContatct = data.data[0].contact
            this.degree.name = data.data[0].catName

          }
        });
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          // footer: '<a href>Why do I have this issue?</a>'
        })
      }
    });
    form.reset();
  }

  filterInfluencerCategory_byCategoryId(categoryId) {
    if(categoryId){
      const categoryName = this.influencerCategories.filter(category => category.id == categoryId);
      return categoryName
    }
    else{
      console.log("ERRRRROR");
    }
}

  // This for influencer modal

  createInfluncer(form) {
    const category_name = this.filterInfluencerCategory_byCategoryId(this.influncer.categoryId);
    this.influncer.status = '1';
    this.degree.contact = this.influncer.contact;
    this.degree.contactName = this.influncer.name;
    this.degree.contactCategory = category_name[0].name;
    // console.log("influencer", this.influncer);
    // console.log("site", this.site);
    const assignToName = this.influncer.created_by_user.name
    
    // return
    this.http.post('/ctsinfluencer/save', this.influncer).subscribe(data => {
      if (data.status == '200') {
        this.getDataContact2(data.data.contact, 'contact');
        //alert('Influencer Created successfully');
        Swal.fire({
          title: 'Influencer Created successfully',
          timer: 3000
        });
        
        // this.site.stateName = '';
        // this.site.cityName = '';
        // this.site.districtName = '';
        this.site.name = '';
        this.site.pinCode = this.influncer.pincode;
        this.site.stateName = this.influncer.state;
        this.site.districtName = this.influncer.district;
        this.site.cityName = this.influncer.city;
        this.site.assign_to_user.name = assignToName;
        this.site.primaryCts = this.site.assign_to_user['userId'];
        this.site.createdById = this.site.assign_to_user['userId'];
        this.site.primarySsaDmo = this.site.assign_to_user['userId'];
        // this.lead.assigntoid = this.influncer.created_by_user['loginId'];
        //this.getAllInfluncer();
        jQuery("#influncerModal").modal("hide");
      }
      else {
        Swal.fire({
          title: 'Something Went Wrong',
          timer: 3000
        });
      }
    });
    form.reset();
  }

  checkvalidataion() {

  }

  updatereset() {
    this.degree.subStatusId = '',
      this.degree.comment = ''
  }

  resetForm() {
    this.radioyes = null;
    this.radiono = null;
    this.sitename = false;
    this.show = false;
    this.degree.assignToId = '',
      this.degree.categoryId = '',
      this.degree.comment = '',
      this.degree.contactName = '',
      this.degree.contact = '',
      this.degree.departmentid = '',
      this.degree.contactCategory = '',
      this.degree.subCategoryId = '',
      this.degree.stageConstruction = '',
      this.degree.preferredDate = '',
      this.degree.ctsSiteId = '',
      this.degree.addressLine1 = '',
      this.degree.addressLine2 = '',
      this.degree.addressLine3 = '',
      this.degree.addressLine4 = '',
      this.degree.pincode = '',
      this.degree.stateId = '',
      this.degree.cityId = '',
      this.degree.ctsGeogId = '',
      this.degree.districtId = '',
      this.degree.ihbName = '',
      this.degree.ihbContatct = '',
      this.degree.name = '',
      this.degree.StatusId = '',
      this.degree.subStatusId = '',
      this.degree.statusId = 1,
      this.degree.ticketId = '',
      this.degree.batchNumber = '',
      this.degree.batchProducts = '',
      this.degree.purchaseDate = '',
      this.degree.supplierName = '',
      this.degree.supplierNumber = '',
      this.existingsite = false;
    this.newsite = false;
    this.contactpersonlist = [];
    this.degree.categoryId=null;

  }

  resetInfluencer() {
    this.influncer.categoryId = '',
      this.influncer.name = '',
      this.influncer.tier = '',
      this.influncer.preferredBrand = '',
      this.influncer.monthlyPotentialMt = '',
      this.influncer.tradeSitesNumber = '',
      this.influncer.contact = '',
      this.influncer.email = '',
      this.influncer.status = '',
      this.influncer.preferredBrand = ''
  }

  setFirstRowData() {
    // console.log("site", this.site)
    this.degree.sitecategory = this.degree.contactCategory;

    this.site.contactpersondetails[0] = { ihbName: this.degree.contactName, isprimary: '', ihbContatct: this.degree.contact, ihbCategory: this.degree.contactCategory };
  }

  siteResetForm() {
    this.site.name = '',
      this.site.constuctionStage = '',
      this.site.geographyMasterId = '',
      this.site.geogId = '',
      this.site.addressLine1 = '',
      this.site.addressLine2 = '',
      this.site.addressLine3 = '',
      this.site.addressLine4 = '',
      this.site.pinCode = '',
      this.site.cityName = '',
      this.site.districtName = '',
      this.site.stateName = '',
      this.site.ihbName2 = '',
      this.site.ihbContact2 = '',
      this.site.ihbCategory2 = '',
      this.site.ihbName3 = '',
      this.site.ihbContact3 = '',
      this.site.ihbCategory3 = '',
      this.site.ihbName4 = '',
      this.site.ihbContact4 = '',
      this.site.ihbCategory4 = '',
      this.site.contactpersondetails = [
        { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' },
        { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' },
        { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' }
      ]

  }

  subStatusonchange() {
    if (this.degree.subStatusId == '4' && this.divisonid == '2') {
      this.completeasperrequestservices();
      this.services = true;
      this.rescheduledate = false;
      this.showupdatebutton = false;
    }
    else if (this.degree.subStatusId == '6' || this.degree.subStatusId == '22') {
      this.services = false;
      this.rescheduledate = true;
      this.showupdatebutton = true;
    }
    else if (this.degree.subStatusId != '5') {
      this.showupdatebutton = true;
      this.services = false;
      this.rescheduledate = false;
    }
    else {
      this.services = false;
      this.rescheduledate = false;
    }

  }

  //check ihb contact duplicacy
  checkIhbDuplicate(contact, i=0) {
    if (contact != null && contact.length == 10 && this.site.contactpersondetails[i].ihbCategory == 'IHB')
      this.http.get('/ctsinfluencer/verify-contact-details/' + contact + "/ihb").subscribe(data => {
        if (data.status == 200) {
          if (data.data.length > 0) {
            this.site.contactpersondetails[i].ihbContatct = ''
            alert("Ihb Number already Exist")
          }
        }
      })
  }


  categoryWiseStatus(catid) {
    this.http.get('/ctslead/categoryWiseStatus/' + catid).subscribe(data => {

      if (data.status == 200) {
        this.substatus = data.data;
        // console.log(this.substatus);
        
      }
    })
  }
  resetupdateform() {
    this.degree.preferredDate = '',
      this.degree.services = '',
      this.degree.comment = '',
      this.degree.subStatusId = ''
    this.services = false;
    this.rescheduledate = false;
  }

}
