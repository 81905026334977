import { Component, OnInit } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { AppserviceService } from './appservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
// import { IDropdownSettings } from 'ng-multiselect-dropdown'; 
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public hide=false;
  title = 'umber-web';
public class="content";
public data="";

dropdownList = [];
selectedItems = [];
dropdownSettings = {};


constructor(private router:Router,private apservice:AppserviceService){


this.router.events.subscribe(data=>{
  if (data instanceof NavigationEnd){
if(data.url=='/login'){
  this.data=data.url
  this.hide=true; 
  this.class="inventory-body"
}
  }
});
}

ngOnInit() {
  this.dropdownList = [
    { item_id: 1, item_text: 'Mumbai' },
    { item_id: 2, item_text: 'Bangaluru' },
    { item_id: 3, item_text: 'Pune' },
    { item_id: 4, item_text: 'Navsari' },
    { item_id: 5, item_text: 'New Delhi' }
  ];
  this.selectedItems = [
    { item_id: 3, item_text: 'Pune' },
    { item_id: 4, item_text: 'Navsari' }
  ];
  this.dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
}

onItemSelect(item: any) {
  console.log(item);
}
onSelectAll(items: any) {
  console.log(items);
}





// ngOnInit (){
//   this.spinner.show();

//    setTimeout(() => {
//        // spinner ends after 5 seconds */
//        this.spinner.hide();
//    },2000);
//  }

  
}
