import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CtsInfluncerComponent } from '../cts-influncer/influncer.component';
import { CtsLeadComponent } from '../cts-lead/lead.component';
import { CtsWhiteRequestComponent } from '../ctswhite-request/ctsrequest.component';
import { CtsEventComponent } from '../cts-event/event.component';
import { CtsWhiteReportComponent } from '../cts-white-report/cts-white-report.component';
import { CtswhiteIndividualReportComponent } from '../ctswhite-individual-report/ctswhite-individual-report.component';
import { CtsKycComponent } from '../cts-kyc/cts-kyc.component';
import { CtsKycViewaccountComponent } from '../cts-kyc-viewaccount/cts-kyc-viewaccount.component';

const routes: Routes = [
  {path: '', data: {title: 'Test'},
  children: [
    //{ path: '', component: TicketComponent},
    { path: 'ctsLead', component: CtsLeadComponent},
    { path: 'ctsWhiteRequest', component: CtsWhiteRequestComponent},
    { path: 'ctsinfluencer', component: CtsInfluncerComponent},
    { path: 'events', component: CtsEventComponent},
    { path: 'ctsReports', component: CtsWhiteReportComponent},
    { path: 'ctswhite-individual-report', component: CtswhiteIndividualReportComponent},
    { path: 'ctsKyc', component: CtsKycComponent},
    { path: 'ctsKyc-viewAccount', component: CtsKycViewaccountComponent},
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CtsWhiteRoutingModule { }
