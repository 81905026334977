import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { TicketComponent } from './pages/ticket/ticket.component';
import { UserComponent } from './pages/user/user.component';
import { InfluncerComponent } from './pages/influncer/influncer.component';
import { LeadComponent } from './pages/lead/lead.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { CtsrequestComponent } from './pages/ctsrequest/ctsrequest.component';
import { FulllayoutComponentComponent } from './pages/fulllayout-component/fulllayout-component.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { PrivacyPolicyNewComponent } from './pages/privacy-policy-new/privacy-policy-new.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';


const routes: Routes = [


  {
    path:'login',component:LoginComponent
  },
  {
    path:'forgot',component:ForgotPasswordComponent
  },
  {
    path:'privacy-policy',component:PrivacyPolicyComponent
  },
  {
    path:'privacy-policy-new',component:PrivacyPolicyNewComponent
  },
    {
      path: 'umber',component:FulllayoutComponentComponent, loadChildren:()=>import('./pages/master/master.module').then(m=>m.MasterModule)
    },
    {
      path: 'umber',component:FulllayoutComponentComponent, loadChildren:()=>import('./pages/cts-subadmin/cts-subadmin.module').then(m=>m.CtsSubadminModule)
    },
    {
      path: 'umber',component:FulllayoutComponentComponent, loadChildren:()=>import('./pages/Inflencer/influencer/influencer.module').then(m=>m.InfluencerModule)
    },
    {
      path: 'umber',component:FulllayoutComponentComponent, loadChildren:()=>import('./pages/site/site.module').then(m=>m.SiteModule)
    },
    {
      path: 'umber',component:FulllayoutComponentComponent, loadChildren:()=>import('./pages/van/van.module').then(m=>m.VanModule)
    },
    {
      path: 'umber',component:FulllayoutComponentComponent, loadChildren:()=>import('./pages/Priortization/prioritization/prioritization.module').then(m=>m.PrioritizationModule)
    },
    {
      path: 'umber',component:FulllayoutComponentComponent, loadChildren:()=>import('./pages/JCP/jcp.module').then(m=>m.JcpModule)
    },
  //  {
  //   path: 'profile', component: ProfileComponent
  //  },
     {
      path:'',redirectTo:'login',pathMatch:'full'    },
     { path: '**', component: LoginComponent }
  
 

  
  ]




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
