import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpClientService } from '../http-client-service.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  currentuserid : any;
  profiledetial = {
    employeename: '',
    emailid: '',
    officecontact: '',
    contactno: '',
    presentaddress1: '',
    presentaddress2: ''
  };
  profileInfo: any;



  constructor(private http: HttpClientService, private spinner: NgxSpinnerService) {

    this.profileinfo();
  }

  ngOnInit() {

  }

  profileinfo() {
    this.http.get('/user/byUserId/' + sessionStorage.getItem('userid')).subscribe(data => {
      if (data.status == '200') {
        this.profileInfo = data.data;
        sessionStorage.setItem("username", data.data.firstName)
        // console.log('=====', this.profileInfo);
        this.profiledetial.employeename = data.data.firstName;
        this.profiledetial.emailid = data.data.officialEmail;
        this.profiledetial.officecontact = data.data.officePhone;
        this.profiledetial.contactno = data.data.phone;
        this.profiledetial.presentaddress1 = data.data.personnelAddress.addressLine1;
        this.profiledetial.presentaddress2 = data.data.personnelAddress.addressLine2;
      }
    });
  }

  parseDatanew() {
    return {
      "firstName": this.profiledetial.employeename,
      "officeEmail": this.profiledetial.emailid,
      "personalMobile": this.profiledetial.contactno,
      "officeMobile": this.profiledetial.officecontact,
      "id": 0,
      "isWithoutAdAllowed": 1,
      "employeeId": this.profileInfo.employeeId,
      "lastName": this.profileInfo.lastName,
      "email": this.profileInfo.email,
      "dob": this.profileInfo.dob,
      "parentPositionCode": this.profileInfo.parentPositionCode,
      "designationId": this.profileInfo.designationId,
      "divisionId": this.profileInfo.divisionId,
      "distributionChannelId": this.profileInfo.distributionChannelId,
      "jkState": this.profileInfo.jkState,
      "state": this.profileInfo.personnelAddress.state,
      "status": this.profileInfo.status,
      "territeryCode": this.profileInfo.territoryCode,
      "departmentId": this.profileInfo.departmentId,
      "addressId": 189, 
      "addressLine1": this.profiledetial.presentaddress1,
      "addressLine2": this.profiledetial.presentaddress2,
    }
  }

  mobileValidation = () => {
    let arr = ["6", "7", "8", "9"]    
    if (this.profiledetial.officecontact) {
      if (arr.includes(this.profiledetial.officecontact[0]) && arr.includes(this.profiledetial.contactno[0]) && this.profiledetial.officecontact.length == 10 && this.profiledetial.contactno.length == 10) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }

  emailValidation = () => {
    
    // const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})/;
    // const emailval = this.profiledetial.emailid.endsWith("@gmail.com");
    const emailval = this.profiledetial.emailid.match(validRegex);
    if(emailval){
      return true
    }
    else{
      return false
    }
  }

  validation = () => {
    if(this.emailValidation() && this.mobileValidation() && this.profiledetial.employeename !=""){
      return true
    }
    else{
      return false
    }
  }

  // Update user profile
  
  updateUserProfile() {
    const reqData = { ...this.parseDatanew(), id: this.profileInfo.id };
    if(this.profiledetial.employeename !=""){
      if(this.emailValidation()){
        if (this.mobileValidation()) {
          this.spinner.show();
          this.http.post(`/ctslead/updateUser`, reqData).subscribe(data => {
            Swal.fire({
              title: data.message,
              timer: 150000,
              showConfirmButton:false,
              icon: 'success',
            });
            sessionStorage.setItem("username",data.data.firstName)
            this.spinner.hide();
          }, err => {
            Swal.fire({
              title: err.error ? err.error.message : 'Error saving details',
              timer: 150000,
            });
            this.spinner.hide();
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        else {
          alert("Please fill valid mobile number.");
          this.spinner.hide();
        }
      }
      else {
        alert("Please fill valid emailID.");
        this.spinner.hide();
      }
    }
    else {
      alert("Please fill Employee name");
      this.spinner.hide();
    }
  }
}