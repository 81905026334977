import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtsLeadComponent } from '../cts-lead/lead.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PaginationModule } from '../pagination/pagination.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { TokenInterceptor } from 'src/app/TokenInterceptor';
import { HttpClientService } from '../http-client-service.service';
import { CtsWhiteRoutingModule } from './cts-white-routing.module';
import { CtsWhiteRequestComponent } from '../ctswhite-request/ctsrequest.component';
import { CtsInfluncerComponent } from '../cts-influncer/influncer.component';
import { CtsEventComponent } from '../cts-event/event.component';
import { NgDatepickerModule } from 'ng2-datepicker';
import { NgxPaginationModule } from 'ngx-pagination';
import { CtsWhiteReportComponent } from '../cts-white-report/cts-white-report.component';
import { CtswhiteIndividualReportComponent } from '../ctswhite-individual-report/ctswhite-individual-report.component';
import { CtsKycComponent } from '../cts-kyc/cts-kyc.component';
import { CtsKycViewaccountComponent } from '../cts-kyc-viewaccount/cts-kyc-viewaccount.component';

@NgModule({
  declarations: [
    CtsLeadComponent,
    CtswhiteIndividualReportComponent,
    CtsWhiteRequestComponent,
    CtsInfluncerComponent,
    CtsEventComponent,
    CtsWhiteReportComponent,
    CtsKycComponent,
    CtsKycViewaccountComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    DataTablesModule,
    SharedModule,
    CtsWhiteRoutingModule,
    NgDatepickerModule,
    NgxPaginationModule
  ],
  providers: [HttpClientService, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
})
export class CtsWhiteModule { }
