// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    //  BASE_ENDPOINT: "https://umberuat1.jkcement.com/umber-rest"
    
    // devUrl
    //BASE_ENDPOINT: "https://devumber.jkcement.com/umber-rest"

    // UatUrl
    BASE_ENDPOINT: "https://umber.jkcement.com/umber-rest"

    // prodUrl
    //BASE_ENDPOINT: "https://umber1.jkcement.com/umber-rest"

    //BASE_ENDPOINT: "https://umberuat2.jkcement.com/umber-rest"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
