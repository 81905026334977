import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClientService } from '../http-client-service.service';
import { HttpClient } from '@angular/common/http';
import { HeaderComponent } from 'src/app/layout/header/header.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  insertForm:FormGroup;
  Email:FormControl;
  constructor(private route: Router, private http: HttpClientService, private htt: HttpClient,private header:HeaderComponent) { }

  ngOnInit() {
  }

  forgot:any = {
    email: '',
    password:'',
    confirmpassword:''
  }
}
