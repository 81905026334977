import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from '../http-client-service.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Component({
    selector: 'app-cts-kyc',
    templateUrl: './cts-kyc.component.html',
    styleUrls: ['./cts-kyc.component.css']
})

export class CtsKycComponent implements OnInit {
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
    usersList: any = [];
    public filterQuery = "";
    public mfRowsOnPage = 10;
    public mfActivePage: any;
    public sortOrder = "asc";
    public page: number;
    data: any[];
    customerType: any = [];
    allOwnersList: any = [];
    divisonList: any = [];
    customerGrupJson;
    divisonJson;
    BASE_ENDPOINT = environment.BASE_ENDPOINT;
    primaryOwnerFullName;
    constructor(private http: HttpClient, private https: HttpClientService, private route: Router) { }

    ngOnInit(): void {
        const ParseHeaders = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=UTF-8'
            })
        };
        const that = this;
        this.dtOptions = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            responsive: true,
            processing: true,
            ajax: (dataTablesParameters: any, callback) => {
                that.https.post('/kyc/datatables-view/', dataTablesParameters, ParseHeaders).subscribe(resp => {
                    this.usersList = [];
                    this.usersList = resp.data;
                    callback({
                        recordsTotal: resp.recordsTotal,
                        recordsFiltered: resp.recordsFiltered,
                        data: []
                    });
                });
            },
            columns: [
                { data: "accountName", name: "Name" },
                { data: "customerEmail", name: "Email ID" },
                { data: "customerPhone", name: "Mobile No" },
                { data: "gstNumber", name: "GST" },
                { data: "customerGroupId", name: "Type" },
                { data: "divisionId", name: "Division" },
                { data: "sapCustomerCode", name: "SAP Code" },
                { data: "accountOwner.loginId", name: "Primary Owner" },
                { data: "status", name: "Account Status" },
                { data: "KycDocStatus.statusName", name: "KYC Status" }
            ]
        };
        sessionStorage.removeItem('view-account');
        sessionStorage.removeItem('kyc-status')
    }

    onFileSelect(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            this.http.post(`${environment.BASE_ENDPOINT}/kyc/excel-upload-for-kyc-bulk-download`, formData).subscribe((res: any) => {
                let str = '';
                event.srcElement.value = null;
                if (res.status === 200) {
                    window.location.href = `${environment.BASE_ENDPOINT}/kyc/kyc-bulk-download/${res.message}`;
                    res.message = 'Success';
                    str += "<h4>Data is uploaded successfully. Your File will be downloaded shortly.</h4>"
                }
                if (res.status === 400) {
                    str += res.message;
                    res.message = 'Error';
                }
                Swal.fire({
                    title: res.message,
                    html: str,
                    timer: 150000,
                });
            }, (err) => {
                event.srcElement.value = null;
                Swal.fire({
                    title: 'Error',
                    html: err.error ? err.error.message : 'Error saving account',
                    timer: 150000,
                });
            });
        }
    }

    downloadFile() {
        window.location.href = `${environment.BASE_ENDPOINT}/kyc/kyc-bulk-template-download`;
    }

    editAccount(user) {
        sessionStorage.setItem('view-account', user.id);
        if (user.kycDocStatus == null) sessionStorage.setItem('kyc-status', 'Pending')
        else sessionStorage.setItem('kyc-status', user.kycDocStatus.statusName)
        this.route.navigate(['/umber/ctsKyc-viewAccount']);
    }
}
