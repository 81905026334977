import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../http-client-service.service';

@Component({
  selector: 'app-ticketcreation',
  templateUrl: './ticketcreation.component.html',
  styleUrls: ['./ticketcreation.component.css']
})
export class TicketcreationComponent implements OnInit {
  ticketcreationmodule = {
    accountId: '',
    categoryId: '',
    subCategoryId: '',
    assignedToId: '',
    subject: '',
    description: '',
    creationTime: '',
    createdById: sessionStorage.getItem('userid'),
    createdByPositionCode: '',
    statusId: '',
    userid : sessionStorage.getItem('userid'),
    assignedPositionCode: sessionStorage.getItem('positionCode'),
  };

  idcategory: any;
  idaccount: any;
  accountnamedata: any;
  categorydata: any;
  subcategorydata: any;
  assigntouser: any;

  constructor(private http: HttpClientService) { 
    this.getaccountname();
    this.getcategory();
  }

  getaccountname() {
    this.http.get('/account/get-account-matrix/' + this.ticketcreationmodule.userid).subscribe(data => {
      if (data.status == '200') {
        this.accountnamedata = data.data;
      }
    });
  }
  getaccountid(id) {
    this.idaccount = id;
    console.log('account id', this.idaccount);
  }

  getcategory() {
    console.log('userid in category', this.ticketcreationmodule.userid);
    this.http.get('/ticket/get-category/').subscribe(data => {
      if (data.status == '200') {
        this.categorydata = data.data;
      }
    });
  }
  getcategoryid(id) {
    this.idcategory = id;
    console.log('category id', this.idcategory);
    this.getassignto();
    this.getsubcategory();
  }

  getsubcategory() {
    this.http.get('/ticket/get-sub-category/' + this.idcategory).subscribe(data => {
      if (data.status == '200') {
        this.subcategorydata = data.data;
      }
    });
  }

  getassignto() {
    this.http.get(`/ticket/get-assignedto/${this.ticketcreationmodule.userid}/${this.idcategory}/${this.idaccount}`).subscribe(data => {
      if (data.status == '200') {
        this.assigntouser = data.data;
        console.log('assignto',this.assigntouser);
      }
    });
  }

  onsave() {
    this.http.post('/ticket/save', this.ticketcreationmodule).subscribe(data => {
      if(data.status == '200') {
        console.log('on save', data.data);
      }
    });
  }

  formreset() {
    this.ticketcreationmodule.accountId= '';
    this.ticketcreationmodule.categoryId= '',
    this.ticketcreationmodule.subCategoryId= '',
    this.ticketcreationmodule.assignedToId= '',
    this.ticketcreationmodule.subject= '',
    this.ticketcreationmodule.description= ''
  }

  ngOnInit() {
  }

}
