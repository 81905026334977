import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from 'src/app/layout/header/header.component';
import { SidebarComponent } from 'src/app/layout/sidebar/sidebar.component';
import { TicketComponent } from '../ticket/ticket.component';
import { CtsrequestComponent } from '../ctsrequest/ctsrequest.component';
import { LeadComponent } from '../lead/lead.component';
import { InfluncerComponent } from '../influncer/influncer.component';
import { NotificationComponent } from '../notification/notification.component';
import { ReportsComponent } from '../reports/reports.component';
import { UserComponent } from '../user/user.component';
import { MasterRoutingModule } from './master-routing.module';
import { HttpClientService } from '../http-client-service.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from 'src/app/TokenInterceptor';
import { AddsiteComponent } from '../addsite/addsite.component';
import { TicketcreationComponent } from '../ticketcreation/ticketcreation.component';
import { ProfileComponent } from '../profile/profile.component';
import { AccountsComponent } from '../accounts/accounts.component';
import { AllticketsComponent } from '../alltickets/alltickets.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
// import { UniquePipePipe } from '../ctsrequest/uniquePipe.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from "../pagination/pagination.module";

import { DataTablesModule } from 'angular-datatables';
import { CtsWhiteModule } from '../cts-white/cts-white.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SapCodeComponent } from '../sap-jobs/sap-jobs.component';
import { EventProfileComponent } from '../event-profile/event-profile.component';
import { GiftMasterComponent } from '../gift-master/gift-master.component';
import { LeadPriorityComponent } from '../lead-priority/lead-priority.component';

@NgModule({
  declarations: [

    TicketComponent,
    CtsrequestComponent,
    LeadComponent,
    InfluncerComponent,
    NotificationComponent,
    ReportsComponent,
    UserComponent,
    AddsiteComponent,
    ProfileComponent,
    AccountsComponent,
    AllticketsComponent,
    TicketcreationComponent,
    SapCodeComponent,
    EventProfileComponent,
    GiftMasterComponent,
    LeadPriorityComponent,
    // UniquePipePipe
  ],
  providers: [HttpClientService,{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
  imports: [
    CommonModule,
    MasterRoutingModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    DataTablesModule,
    CtsWhiteModule,
    NgxPaginationModule
  ],

  exports: [
    ProfileComponent
  ],
  
})
export class MasterModule { }
