import { Component, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/pages/http-client-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GolbalserviceService } from 'src/app/pages/golbalservice.service';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent  {

 

 
  public username = sessionStorage.getItem("username");
  constructor(private route:Router,private http:HttpClientService,private https:HttpClient,private service:GolbalserviceService) {
    this.service.name.subscribe(data=>{
      this.username=data.toString();
    });

   }


  logout(){ 
    this.http.get("/auth/logout/token").subscribe(data => {
      if(data.status === 200){
        // console.log("data", data);
        sessionStorage.clear()
        this.route.navigate(['/login'])
      }
    }),(error) => {
      console.log("Error", error);
    }
  }

  profileredirect(){
    if (this.route.url.indexOf('/umber') > -1) {
      this.route.navigate(['/umber/profile']);
    }
  }

  sidebarToggler(){
    var x = document.getElementById("sidebarToggler");
    if(x.style.transform = "translateX(-220px)"){
      x.style.transform = "translateX(0)"
    }
    else{
      x.style.transform = "translateX(0px)"
    }
    // transform: translateX(-220px);
  }
}
