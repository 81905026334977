import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../http-client-service.service';

@Component({
  selector: 'app-alltickets',
  templateUrl: './alltickets.component.html',
  styleUrls: ['./alltickets.component.css']
})
export class AllticketsComponent implements OnInit {

  userid: any;
  divisonid: any;
  loginid: any;
  accountid: any;
  categoryid: any;
  ticketid: any;
  assignedPositionCode: any;
  assignedToId: any;
  allticketdata: any = [];
  viewticketlistdata: any = [];
  viewhistorydata: any = [];
  assigntouser: any = [];
  resolvedticketdata: any = [];
  updateticketdata: any = [];
  reopendata: any = [];
  pickdata: any = [];
  viewticketmodule = {
    customerid: '',
    customername: '',
    category: '',
    subcategory: '',
    ticketno: '',
    date: '',
    priority: '',
    status: '',
    subject: '',
    description: ''
  };
  assigntomodule = {
    id: '',
    assignedPositionCode: sessionStorage.getItem('positionCode'),
    assignedToId: ''
  };
  modaliskey = {
    categoryid: '',
    accountid: '',
    ticketid: '',
    assignedPositionCode: '',
    assignedToId: ''
  };
  resolvedticket = {
    id: '',
    statusId: '21',
    updateComment: ''
  };
  updateticket = {
    id: '',
    updateComment: '',
    lastFileId: ''
  };
  reopenticket = {
    id: '',
    statusId: '22'
  };
  pick = {
    id: '',
    pickedById: '',
    pickedByPositionCode: ''
  };


  constructor(private http: HttpClientService) {
    this.getalltickets();
  }

  getalltickets() {
    this.userid = sessionStorage.getItem('userid');
    this.http.get('/ticket/assigned-tickets-by-id/' + this.userid).subscribe(data => {
      if (data.status == '200') {
        this.allticketdata = data.data;
      }
    });
  }

  viewTicket(id) {
    this.assigntomodule.id = id;
    this.http.get('/ticket/get/' + id).subscribe(data => {
      if (data.status == '200') {
        this.viewticketlistdata = data.data;
        this.modaliskey.categoryid = data.data.categoryId;
        this.modaliskey.accountid = data.data.accountId;
        this.modaliskey.ticketid = data.data.id;
        this.modaliskey.assignedPositionCode = data.data.assignedPositionCode;
        this.modaliskey.assignedToId = data.data.assignedToId;

        this.viewticketmodule.customerid = data.data.account.sapCustomerCode;
        this.viewticketmodule.customername = data.data.account.customerName;
        this.viewticketmodule.category = data.data.ticketCategory.name;
        this.viewticketmodule.subcategory = data.data.ticketSubCategory.name;
        this.viewticketmodule.ticketno = data.data.ticketNumber;
        this.viewticketmodule.date = data.data.creationTime;
        this.viewticketmodule.status = data.data.ticketStatus.statusName;
        this.viewticketmodule.subject = data.data.subject;
        this.viewticketmodule.description = data.data.description;
        this.viewhistorydata = data.data.ticketLogs;

        this.getassignto(data.data.categoryId, data.data.accountId);
        console.log('view', this.viewticketlistdata);
      }
    });
  }

  getassignto(categoryid, accountid) {
    this.http.get('/ticket/get-assignedto/' + this.userid + '/' + categoryid + '/' + accountid).subscribe(data => {
      if (data.status == '200') {
        this.assigntouser = data.data;
        //console.log('assignto',this.assigntouser);
      }
    });
  }

  putassignto() {
    this.http.put('/ticket/ticket-changes', this.assigntomodule).subscribe(data => {
      if (data.status == '200') {
        //console.log('pul-assignto',data.data);
      }
    });
  }

  resolvedticketfun() {
    this.resolvedticket.id = this.modaliskey.ticketid;
    this.http.put('/ticket/ticket-changes', this.resolvedticket).subscribe(data => {
      if (data.status == '200') {
        this.resolvedticketdata = data.data;
        this.cleardata();
      }
    });
  }
  cleardata() {
    this.resolvedticket.updateComment = '';
  }

  updateticketfun() {
    this.updateticket.id = this.modaliskey.ticketid;
    this.http.put('/ticket/ticket-changes', this.updateticket).subscribe(data => {
      if (data.status == '200') {
        this.updateticketdata = data.data;
        this.clearupdatedata();
      }
    });
  }
  clearupdatedata() {
    this.updateticket.updateComment = '';
    this.updateticket.lastFileId = '';
  }

  reopenticketfun() {
    this.reopenticket.id = this.modaliskey.ticketid;
    this.http.put('/ticket/ticket-changes', this.reopenticket).subscribe(data => {
      if (data.status == '200') {
        this.reopendata = data.data;
      }
    });
  }

  pickfun() {
    this.pick.id = this.modaliskey.ticketid;
    this.pick.pickedById = sessionStorage.getItem('userid');
    this.pick.pickedByPositionCode = sessionStorage.getItem('positionCode');
    this.http.put('/ticket/ticket-changes', this.pick).subscribe(data => {
      if (data.status == '200') {
        this.pickdata = data.data;
      }
    });
  }

  ngOnInit() {
  }

}
