import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniquePipePipe } from '../pages/ctsrequest/uniquePipe.pipe';
import { HeaderComponent } from '../layout/header/header.component';
import { SidebarComponent } from '../layout/sidebar/sidebar.component';
import { FooterComponent } from '../layout/footer/footer.component';



@NgModule({
  declarations: [
    UniquePipePipe,
    HeaderComponent,
    SidebarComponent,
    FooterComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    UniquePipePipe,
    HeaderComponent,
    SidebarComponent
  ]
})
export class SharedModule { }
