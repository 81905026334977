import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { HttpClientService } from '../http-client-service.service';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
// import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-sap-code',
  templateUrl: './sap-jobs.component.html',
  styleUrls: ['./sap-jobs.component.css']
})
export class SapCodeComponent implements OnInit {

  constructor(private http: HttpClientService, private httpc: HttpClient, private spinner: NgxSpinnerService, public datepipe: DatePipe) { }

  ngOnInit() {
  }
  downloadDate: any = "";

  downloadJobExcel(_case) {
    let url;
    if (this.downloadDate) {
      switch (_case) {
        case "payment":
          url = `${environment.BASE_ENDPOINT}/jobsreport/paymentdueexcle-download/${this.downloadDate}`
          break;
        case "sale_performance":
          url = `${environment.BASE_ENDPOINT}/jobsreport/salesperformanceexcle-download/${this.downloadDate}`
          break;
        case "stock":
          url = `${environment.BASE_ENDPOINT}/jobsreport/stockexcle-download/${this.downloadDate}`
          break;
        case "sales_order":
          url = `${environment.BASE_ENDPOINT}/jobsreport/salesorderexcle-download/${this.downloadDate}`
          break;
        case "account":
          url = `${environment.BASE_ENDPOINT}/jobsreport/accountdueexcle-download/${this.downloadDate}`
          break;

        default:
          break;
      }
      this.spinner.show();
      const header = {
        "x-auth-token": sessionStorage.getItem("tkn"),
        "responseType": "blob",
        "observe": "body",
      }

      this.httpc.get(url, { headers: header }).subscribe(data =>
        this.downloadFile(data),

        (error) => {
          if (error.error.message) {
            Swal.fire({
              icon: 'error',
              title: error.error.message,
              timer: 150000,
            });
          }
          else {
            window.open(url);
          }
        });
      // this.downloadDate = ""
      this.spinner.hide();
    }
    else {
      Swal.fire({
        icon: "error",
        title: "Please select the Date !",
        timer: 150000,
      });
    }
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }
}
