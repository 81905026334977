import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InfluencerRoutingModule } from './influencer-routing.module';
import { InfluencerComponent } from './influencer.component';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientService } from '../../http-client-service.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
// import { UniquePipePipe } from '../ctsrequest/uniquePipe.pipe';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { PaginationModule } from "../../pagination/pagination.module";
import { CtsWhiteModule } from '../../cts-white/cts-white.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [InfluencerComponent],
  imports: [
    CommonModule,
    InfluencerRoutingModule,
    DataTablesModule,
    CommonModule,
    HttpClientModule,
    Ng2SearchPipeModule,
    SharedModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    DataTablesModule,
    CtsWhiteModule,
    NgxPaginationModule
  ]
})
export class InfluencerModule { }
