import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../http-client-service.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-addsite',
  templateUrl: './addsite.component.html',
  styleUrls: ['./addsite.component.css']
})

export class AddsiteComponent implements OnInit {
  constructor(private http:HttpClientService,private spiner:NgxSpinnerService) { 
 
    this.getAllSite()
  
  }

  public allsite:any=[];

  pincodedata: any=[];

  site={
    name: '',
    constuctionStage: '',
    geographyMasterId: '',
    geogId: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    pinCode: '',
    cityName: '',
    districtName: '',
    stateName: '',
    ihbName: '',
    ihbContatct: '',
    ihbCategory: '',
    ihbName2: '',
    ihbContact2:'',
    ihbCategory2: '',
    ihbName3: '',
    ihbContact3: '',
    ihbCategory3: '',
    ihbName4:'',
    ihbContact4: '',
    ihbCategory4: '',
    contactpersondetails: [
      { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' },
      { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' },
      { ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' }
    ],
    contactpersoncategory: [
      {cate: 'Architect'},
      {cate: 'IHB'},
      {cate: 'Contractor'},
      {cate: 'Engineer'},
      {cate: 'Dealer'},
      {cate: 'Retailer'},
    ]
  }

  

  ngOnInit() {
  }

  addcontactperson(i) {
    this.site.contactpersondetails.push({  ihbName: '', isprimary: '', ihbContatct: '', ihbCategory: '' });
    console.log(this.site.contactpersondetails);
  }  

  removecontactperson(index) {
    if (index > 0) {
      this.site.contactpersondetails.splice(index, 1);
    }
  }

  getAllSite(){
    this.http.get('/site/get-allsites-web').subscribe(data=>{
      if(data.status=='200')
      this.allsite=data.data;
      
    });
  }

  getDataByPinCode(pincode){
    if(pincode.length==6)
    this.http.get('/geography-master/get-by-pin-code/'+pincode).subscribe(data=>{
      if(data.status=='200')
    this.pincodedata=data.data;
    });
  }


  createSite(form){
    this.http.post('/site/save',this.site).subscribe(data=>{
      if(data.status=='200'){
      Swal.fire({
        title: 'Site Created successfully',
        timer: 3000
     });
    
      this.getAllSite();
      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
         // footer: '<a href>Why do I have this issue?</a>'
        })
      }

    })

    form.reset();
  }

}
