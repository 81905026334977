import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HttpClientService } from '../../http-client-service.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

declare var jQuery: any;

@Component({
  selector: 'app-influencer',
  templateUrl: './influencer.component.html',
  styleUrls: ['./influencer.component.css']
})
export class InfluencerComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

dtOptions: DataTables.Settings = {};
    cityList: any = [];
    districtList: any = [];
    ownerList: any = [];
    stateList: any = [];
    divisonList: any = [];
    statusList: any = [
        {
            id: 1,
            value: 'Active'
        },
        {
            id: 0,
            value: 'InActive'
        }
    ];
    accountData: any = {
        infCategory: "",
        city: "",
        infContact: "",
        district: "",
        infName: "",
        pincode: "",
        state: "",
        divisionId:"3",
        inf_city: "",
        inf_district: "",
        inf_pincode: "",
        inf_state: "",
    };
    influenceData: any = {
        city: "",
        district: "",
        pincode: "",
        state: "",
        divisionId:"3"
    };
    customerType: any = [];
    term: string = '';
    whiteDivision: boolean = false;
    dropdownSettings: any;
    usersList: any = [];
    createAcct: any = {};
    view: boolean = false;
    edit: boolean = false;
    // ABCClassificationList: any = [];
    InfluencerCategory: any = [];
    InfluencerTier: any = [];
    preferredBrand: any = [];
    disableSAPCode: boolean;
    pager: { itemsPerPage: any; currentPage: any; totalItems: any; };
    perPage: any = 10;
    pageNumber: any = 1;
    addcreateAcct: any;
    mobileError: boolean = true;
    allOwnersList: any = [];
    pincodedata: any;
    pincodeError: string;
    sapcodeError: string = "";
    sapcodeVError: boolean = false;
    dropdownSettingsFilter: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
    dropdownSettingsForTransferUser: {
        singleSelection: boolean; 
        idField: string; 
        textField: string; 
        selectAllText: string; 
        unSelectAllText: string; 
        itemsShowLimit: number; 
        allowSearchFilter: boolean; 
        closeDropDownOnSelection: boolean; 
    };
    primaryOwnerIdError: boolean;
    page: number = 1;
    itemsPerPage: number = 10;
    totalItems: any;
    timeout: any = null;
    errMsg: string = "";
    fromDate: any = new Date();
    toDate: any = new Date();
    downloadExcelFilter: any = {};
    dt: any;
    ct: any;
    taluka: any;
    locality: any;
    oldPincode: any;
    showAbcClassificationList:boolean = false;
    showCustomerTypeList:boolean = false;
    departmentList: any;
    validationErrorMsg: any;
    transferList = {
        createdBy:[],
        ids:[]
    }
    transferUserList = [];
    userlist=[];
    phno_Error:"";
    selectAll:boolean = false;

    constructor(private http: HttpClientService, private spinner: NgxSpinnerService, public datepipe: DatePipe) { }

    ngOnInit() {
        this.downloadExcelFilter = {
            startDate: this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'),
            endDate: this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),
        }

        this.getAllOwners();
        this.dtOptions = {
            pagingType: 'full_numbers',
            ordering: false
        };

        this.getStateList();
        this.getDivisonList();
        // this.getABCClassification(2);
        // this.getCustomerType(2);
        this.getDepartmentList();
        this.getInfluencerCategory();
        this.getInfluencerTier();
        this.getBrands();
        this.getTransferUserList();
        this.find();
        this.createAcct = {
            status: 1,
            divisionId:"3"
        };
        // this.dropdownSettings = {
        //   singleSelection: false,
        //   text:"Select Owners",
        //   selectAllText:'Select All',
        //   unSelectAllText:'UnSelect All',
        //   enableSearchFilter: true,
        //   classes:"myclass custom-class"
        // };
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'brand',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            allowSearchFilter: true,
            closeDropDownOnSelection: true,
        };
        this.dropdownSettingsFilter = {
            singleSelection: true,
            idField: 'loginID',
            textField: 'userName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            allowSearchFilter: true,
        };
        this.dropdownSettingsForTransferUser = {
            singleSelection: true,
            idField: 'employeeid',
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 5,
            allowSearchFilter: true,
            closeDropDownOnSelection: true,
        };

        //  this.accountData.state = "";
    }

    objectToArray(obj) {
        const arr = [];
        Object.keys(obj).forEach(key => {
            arr.push(
                {
                    id: key,
                    value: obj[key]
                }
            );
        });
        return arr;
    }

    objectToArrayForTransferUser(obj) {
        const arr = [];
        Object.keys(obj).forEach(key => {
            arr.push(
                {
                    employeeid: obj[key]["employeeid"],
                    name: obj[key]["name"] + "(" + obj[key]["employeeid"] + ")"
                }
            );
        });
        return arr;
    }

    objectToArrayOwner(obj) {
        const arr = [];
        if (obj) {
            Object.keys(obj).forEach(key => {
                arr.push(
                    {
                        userId: obj[key][0],
                        userName: obj[key][1] + "(" + obj[key][3] + ")",
                        territoryCode: obj[key][2],
                        loginID:obj[key][3]
                    }
                );
            });
            return arr;
        }
    }

    getStateList() {
        this.spinner.show();
        this.http.get('/account-management/get-state').subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.stateList = this.objectToArray(data.data);
                // this.accountData.state = sessionStorage.getItem("stateCode");
                // this.find();
            }
        });
    }

    getCustomerType(id) {
        this.spinner.show();
        this.http.get(`/account-management/get-customer-group/${id}`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.customerType = this.objectToArray(data.data);
            }
        });
    }

    getDivisonList() {
        this.spinner.show();
        this.http.get('/account-management/get-division').subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.divisonList = this.objectToArray(data.data);
            }
        });
    }

    stateSelected(stateId) {
        if (stateId) {
            sessionStorage.setItem('selected-state', stateId);
            this.getCityByState(stateId);
            this.getDistrictByState(stateId);
            this.getOwnerByState(stateId);
        }
        this.accountData.inf_allAccountOwner = [];
    }

    getCityByState(id) {
        this.spinner.show();
        this.http.get(`/account-management/get-citybystateid?stateid=${id}`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.cityList = this.objectToArray(data.data);
            }
        });
    }

    getDistrictByState(id) {
        this.spinner.show();
        this.http.get(`/account-management/get-districtbystateid?stateid=${id}`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.districtList = this.objectToArray(data.data);
                // console.log("district",this.districtList);
                
            }
        });
    }

    setOwnetArr(ownerList) {
        let arr = [];
        if (!Array.isArray(ownerList)) {
            arr = [ownerList];
        } else if (Array.isArray(ownerList)) {
            arr = ownerList.map(v => v.id);
        }
        const ownerArr = [];
        arr.forEach(v => {
            this.ownerList.forEach(element => {
                if (element.id == v) {
                    ownerArr.push(element.id);
                }
            });
        });
        return ownerArr;
    }

    getOwnerByState(id) {
        // console.log("-----------",this.createAcct.divisionId)
        this.spinner.show();
        this.http.get(`/account-management/get-primaryOwnerbyStateid?stateid=${id}&div_id=${this.createAcct.divisionId}`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                //this.createAcct.territoryCode = data.data["user details"][0][2];
                this.ownerList = this.objectToArrayOwner(data.data["user details"]);
                console.log("this.ownerList",this.ownerList);
                
            }
        });
    }

    // getABCClassification(id) {
    //     this.spinner.show();
    //     this.http.get(`//account-management/get-abc-classification/${id}`).subscribe(data => {
    //         this.spinner.hide();
    //         if (data.status == 200) {
    //             this.ABCClassificationList = this.objectToArray(data.data);
    //         }
    //     });
    // }

    getInfluencerCategory() {
        this.spinner.show();
        this.http.get("/ctscategory/nwtcategory").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.InfluencerCategory = this.objectToArray(data.data);
            }
        });
    }

    getTransferUserList() {
        this.spinner.show();
        this.http.get("/ctswhiteinfluencer/get-transfer-user").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.userlist = this.objectToArrayForTransferUser(data.data);
                this.transferUserList = this.objectToArray(data.data);
                          
            }
        });
    }

    getInfluencerTier() {
        this.spinner.show();
        this.http.get("/ctswhiteinfluencer/fetchInfluencerTier/3/3").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.InfluencerTier = this.objectToArray(data.data);
            }
        });
    }
    getBrands() {
        this.spinner.show();
        this.http.get("/nwtlead/getBrand").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                // this.preferredBrand = this.objectToArray(data.data);
                this.preferredBrand = data.data;
                
            }
        });
    }

    searchUser() {
        if (this.term && this.term.length > 2) {
            this.spinner.show();
            this.http.get(`/account-management/get-by-customer-name/${this.term}`).subscribe(data => {
                this.spinner.hide();
                if (data.status == 200) {
                    this.usersList = data.data;
                }
            });
        } else if (this.term.length == 0) {
            this.find();
        }
    }

    getUserDetails() {
        this.spinner.show();
        this.usersList = [];
        this.http.get(`/account-management/all-account`).subscribe(data => {
            if (data) {
                this.usersList = data;
            }
            this.spinner.hide();
        });
    }

    reset() {
        this.edit = false;
        this.view = false;
        this.createAcct = {
            status: 1,
            divisionId:"3"
        };
        $('#createInfluencerModal').trigger('reset');
        this.cityList = [];
        this.districtList = [];
        this.ownerList = [];
        this.pincodedata = [];
        this.ct = [];
        this.dt = [];
        this.locality = [];
        this.taluka = [];
        this.oldPincode = '';
        this.showAbcClassificationList = false;
        this.showCustomerTypeList = false;
        this.primaryOwnerIdError = false;
        this.transferList.createdBy=[]
        this.stateSelected(sessionStorage.getItem('selected-state'));
    }

    onItemSelect(val) {
        this.transferList.createdBy.push(val)
        // console.log(val, 'valueee');
    }

    onDropDownClose(val) {
        if (!this.createAcct.allAccountOwner || !this.createAcct.allAccountOwner.length) {
            this.primaryOwnerIdError = true;
        } else {
            this.primaryOwnerIdError = false;
        }
    }

    setPrimaryOwner(prmowner, otheracc) {
        const arrayV = [];
        if (prmowner) {
            arrayV.push({ userId: prmowner.id, userName: prmowner.firstName + " " + prmowner.lastName + "(" + prmowner.employeeId + ")", territoryCode: prmowner.territoryCode })
        }
        if (otheracc) {
            otheracc.forEach(function (value) {
                arrayV.push({ userId: Number(value.userId), userName: value.userName + "(" + value.accountOwner.employeeId + ")", territoryCode: value.territoryCode })
            });
        }
        return arrayV;
    }

    getStateCode(state){
        const code = this.stateList.filter(ele=>{
            if(ele.value.toLowerCase() === state.toLowerCase()){
                return ele
            }
        })
        return code[0].id        
    }

    mobileValidation = () => {
        let arr = ["6","7","8","9"]
        if(this.createAcct.contact){
            if(arr.includes(this.createAcct.contact[0]) && this.createAcct.contact.length == 10){
                return true
            }
            else{
                return false
            }
        }
        else{
            return false
        }
    }

    emailValidation = () => {
        const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})/;
        if(this.createAcct.email){
            const emailval = this.createAcct.email.match(validRegex);
            if(emailval){
                return true
            }
            else{
                return false
            } 
        }
        else{
            return true
        }
      }

    checkPhno() {
        // https://umber.jkcement.com/umber-rest/ctsinfluencer/getInfluencerByContact/9999999999
        if(this.createAcct.contact.length === 10){
            this.spinner.show();
            this.http.get(`/ctsinfluencer/getInfluencerByContact/${this.createAcct.contact}`).subscribe(data => {
                if (data.status === 200) {
                    this.phno_Error= data.message
                }
                else{
                    this.phno_Error=""
                }
                this.spinner.hide();
            });
        }
        else{
            this.phno_Error=""
        }
    }
    
    validation() {
        const mobValid = this.mobileValidation();
        if (!this.createAcct.name || this.createAcct.name == null) {
            this.validationErrorMsg="Please Enter Influencer Name!"
            // console.log('1')
            return true;
        }
        if (this.createAcct.name.length < 3) {
            this.validationErrorMsg="Please Enter Valid Influencer Name!"
            // console.log('1')
            return true;
        }
        if (!this.createAcct.contact) {
            this.validationErrorMsg="Please Enter Contact Number!"
            return true;
        }
        if (this.createAcct.contact.length < 10){
            this.validationErrorMsg="Please Enter Valid Contact Number!"
            return true;
        }
        if (!mobValid){
            this.validationErrorMsg="Please Enter Valid Contact Number!"
            return true;
        }
        if (!this.emailValidation()) {
            this.validationErrorMsg="Please Enter Valid Email ID."
            return true;
        }
        if (!this.createAcct.divisionId) {
            this.validationErrorMsg="Please Enter Division."
            return true;
        }
        if (!parseInt(this.createAcct.departmentId)) {
            this.validationErrorMsg="Please Enter Department."
            // console.log('1')
            return true;
        }
        if (!this.createAcct.categoryId) {
            this.validationErrorMsg="Please Enter Category."
            return true;
        }
        if (!this.createAcct.pincode) {
            this.validationErrorMsg="Please Enter Pincode."
            return true;
        }
        if (!this.createAcct.state) {
            this.validationErrorMsg="Please Enter State."
            return true;
        }
        if (!this.createAcct.district) {
            this.validationErrorMsg="Please Enter District."
            return true;
        }
        if (!this.createAcct.city) {
            this.validationErrorMsg="Please Enter City."
            return true;
        }
        // if (!this.createAcct.primaryOwner || !this.createAcct.primaryOwner.split("(")[1].slice(0,-1)) {
        if (!this.createAcct.primaryOwner || !this.createAcct.primaryOwner) {
            this.validationErrorMsg="Please Enter Primary Owner."
            return true;
        }
        if (!this.createAcct.brand) {
            this.primaryOwnerIdError = true;
            this.validationErrorMsg="Please Enter Preferred Brand."
            return true;
        }
        if (!this.createAcct.tier) {
            this.validationErrorMsg="Please Enter Tier."
            return true;
        }
        if (!this.createAcct.tradeSitesNumber) {
            // console.log('1')
            this.validationErrorMsg="Please Enter Trade Site."
            return true;
        }
        if (!this.createAcct.monthlyPotentialMt) {
            this.validationErrorMsg="Please Enter Monthly Potential MT."
            // console.log('1')
            return true;
        }
        return false;
    }

    createUser(opertionType) {
        if(this.validation()){
            alert(this.validationErrorMsg);
        }
        else{
            this.spinner.show();
            this.filterPincode();
            // const reqData = { ...this.createAcct };
            let brandStr = [];
            this.createAcct.brand.forEach(ele => brandStr.push(ele.brand));
            if(opertionType === "C"){
                const reqData = {
                    categoryId: this.createAcct.categoryId?parseInt(this.createAcct.categoryId):"",
                    city: this.createAcct.city?this.createAcct.city:"",
                    contact: this.createAcct.contact?this.createAcct.contact:"",
                    createdBy: this.createAcct.primaryOwner?this.createAcct.primaryOwner:"",
                    // createdBy: this.createAcct.primaryOwner?this.createAcct.primaryOwner.split("(")[1].slice(0,-1):"",
                    departmentId: this.createAcct.departmentId?parseInt(this.createAcct.departmentId):"",
                    district: this.createAcct.district?this.createAcct.district:"",
                    divisionId: this.createAcct.divisionId?this.createAcct.divisionId:"",
                    email: this.createAcct.email?this.createAcct.email:"",
                    // locality: this.createAcct.locality?this.createAcct.locality:"",
                    monthlyPotentialMt: this.createAcct.monthlyPotentialMt?this.createAcct.monthlyPotentialMt:"",
                    name: this.createAcct.name?this.createAcct.name:"",
                    pincode: this.createAcct.pincode?this.createAcct.pincode:"",
                    preferredBrand: brandStr.join(",") ? brandStr.join(",") : "",
                    state: this.createAcct.state?this.createAcct.state:"",
                    statecode: this.getStateCode(this.createAcct.state),
                    status: this.createAcct.status?this.createAcct.status:"",
                    taluka: this.createAcct.taluka?this.createAcct.taluka:"",
                    tier: this.createAcct.tier?this.createAcct.tier:"",
                    tradeSitesNumber: this.createAcct.tradeSitesNumber?this.createAcct.tradeSitesNumber:""
                }
                // console.log("reqDate",reqData);

                reqData.status = reqData.status ? reqData.status : '1';
                this.http.post(`/ctsinfluencer/create-web-influencer`, reqData).subscribe(data => {
                    Swal.fire({
                        icon:"success",
                        // title: reqData.id ? 'Account edited successfully' : 'Account created successfully',
                        title: 'Influencer Created Successfully',
                        timer: 150000,
                    });
                    this.reset();
                    this.find();
                    this.spinner.hide();
                    (jQuery("#createAccount") as any).modal("hide");
                }, err => {
                    Swal.fire({
                        title: err.error ? err.error.message : 'Error saving details',
                        timer: 150000,
                    });
                    this.spinner.hide();
                    (jQuery("#createAccount") as any).modal("hide");
                });
            }
            else{
                const reqData = {
                    id:this.createAcct.id?this.createAcct.id:"",
                    categoryId: this.createAcct.categoryId?parseInt(this.createAcct.categoryId):"",
                    city: this.createAcct.city?this.createAcct.city:"",
                    contact: this.createAcct.contact?this.createAcct.contact:"",
                    createdBy: this.createAcct.primaryOwner?this.createAcct.primaryOwner:"",
                    // createdBy: this.createAcct.primaryOwner?this.createAcct.primaryOwner.split("(")[1].slice(0,-1):"",
                    departmentId: this.createAcct.departmentId?parseInt(this.createAcct.departmentId):"",
                    district: this.createAcct.district?this.createAcct.district:"",
                    divisionId: this.createAcct.divisionId?this.createAcct.divisionId:"",
                    email: this.createAcct.email?this.createAcct.email:"",
                    // locality: this.createAcct.locality?this.createAcct.locality:"",
                    monthlyPotentialMt: this.createAcct.monthlyPotentialMt?this.createAcct.monthlyPotentialMt:"",
                    name: this.createAcct.name?this.createAcct.name:"",
                    pincode: this.createAcct.pincode?this.createAcct.pincode:"",
                    preferredBrand: brandStr.join(",") ? brandStr.join(",") : "",
                    state: this.createAcct.state?this.createAcct.state:"",
                    statecode: this.getStateCode(this.createAcct.state),
                    status: this.createAcct.status?this.createAcct.status:"",
                    taluka: this.createAcct.taluka?this.createAcct.taluka:"",
                    tier: this.createAcct.tier?this.createAcct.tier:"",
                    tradeSitesNumber: this.createAcct.tradeSitesNumber?this.createAcct.tradeSitesNumber:""
                }
                // console.log("reqDate",reqData);
                // return;
                reqData.status = reqData.status ? reqData.status : '1';
                this.http.put(`/ctsinfluencer/update-influencer/${reqData.id}`, reqData).subscribe(data => {
                    Swal.fire({
                        icon:"success",
                        title: 'Influencer Updated Successfully',
                        timer: 150000,
                    });
                    this.reset();
                    this.find();
                    this.spinner.hide();
                    (jQuery("#createAccount") as any).modal("hide");
                }, err => {
                    Swal.fire({
                        icon:"error",
                        title: err.error ? err.error.message : 'Error saving details',
                        timer: 150000,
                    });
                    this.spinner.hide();
                    (jQuery("#createAccount") as any).modal("hide");
                });
            }
        }

    }

    filterPincode() {
        let self = this;
        const data = this.pincodedata.filter(item => {
            if (item.cityName == this.createAcct.city && item.districtName == this.createAcct.district && item.locality == this.createAcct.locality && item.taluka == this.createAcct.taluka
            ) {
                self.createAcct.geographyMasterId=item.id
                return item
            }
        })
    }

    getDisable(name) {               
    this.disableSAPCode = false
       // if (this.view || (this.edit && (name == 'CustomerType' || name == 'SAP') && (this.createAcct.customerGroupId ==2 || this.createAcct.divisionId ==3))) {
            if (this.view || (this.edit && ((name == 'SAPcode' || name == 'CustomerType' )|| (name == 'SAP' && this.createAcct.customerGroupId !=2 && this.createAcct.divisionId !=3)) )) {
                return true;
            }
            else if(!this.view && !this.edit && name == 'PINCode'){
                if(this.createAcct.divisionId == null || this.createAcct.divisionId == ""){
                    return true
                }
            }
        return false;
    }

    divisionSelected(id) {
        if (id) {
            this.createAcct.parentAccountCode="";
            this.createAcct.sapCustomerCode="";
            this.showAbcClassificationList = true;
            this.showCustomerTypeList = true;
            // this.getABCClassification(id);
            // this.getCustomerType(id);
            const val = id ? this.divisonList.find(o => o.id == id) : {};
            this.whiteDivision = val.value ? val.value.match(/white/gi) : false;
            if (this.whiteDivision) {
                this.dropdownSettings = {
                    singleSelection: false,
                    idField: 'userId',
                    textField: 'userName',
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 5,
                    allowSearchFilter: true
                }
            } else {
                this.dropdownSettings = {
                    singleSelection: true,
                    idField: 'userId',
                    textField: 'userName',
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 3,
                    allowSearchFilter: true
                }
            }
            // this.setSAPCode();
        }
    }

    getDepartmentList() {
        this.http.get('/ctslead/getAllDepartment').subscribe(data => {
            if (data.status == '200') {
                this.departmentList = data.data;
            }
        })
    }

    getDepartmentId(department){
        const departName = this.departmentList.filter(ele=>ele.departmentName===department)
        return departName[0].id
    }
    
    getCategoryId(category){
        const categoryId = this.InfluencerCategory.filter(ele=>ele.value.name===category)
        return categoryId[0].value.id
    }

    getPreferredBrand(brand){
        // console.log(brand,"bfgsdagb",this.preferredBrand);
        const paramArray = brand && brand.split(",");
        // console.log("paramArray",paramArray);
        const p_brand = paramArray && paramArray.map(item => this.preferredBrand.filter(ele => ele.brand.trim() === item.trim())[0]);
        // console.log("p_brand",p_brand.find(item => item === undefined));
        if((p_brand.find(item => item === undefined))){
            return false
        }
        else{
            return p_brand
        }
    }

    editAccount(user, type) {
        this.reset();
        this.edit = type == 'edit' ? true : false;
        this.view = type == 'view' ? true : false;
        this.dropdownSettings.disabled = this.view;
        this.dropdownSettings = {...this.dropdownSettings, allowSearchFilter:this.edit}
        this.spinner.show();
        this.http.get(`/ctsinfluencer/get_influencer-by-id/${user.id}`).subscribe(rdata => {
            this.spinner.hide();
            
            
            const data = { ...rdata.data };
            this.divisionSelected(data.divisionId);
            data.allAccountOwner = this.setPrimaryOwner(data.accountOwner, data.allAccountOwner);
            this.createAcct = data;
            this.createAcct.departmentId = this.getDepartmentId(data.departmentName);
            this.createAcct.categoryId = this.getCategoryId(data.categoryName);
            this.createAcct.brand = this.getPreferredBrand(data.preferredBrand) && this.getPreferredBrand(data.preferredBrand);
            this.createAcct.primaryOwner = data.createdBy
            // this.createAcct.primaryOwner = data.createdByName + "(" + data.createdBy + ")"
            // console.log("edituser",this.createAcct);

            this.getAddressByPincode(data.pincode, 'edit');
            //this.stateSelected(user.state);
            this.checkMObileNum();
            ($('#createAccount') as any).modal('show');
        })

    }

    getCustomerTypeVal() {
        const val = this.customerType.find(o => o.id == this.createAcct.customerGroupId);
        return val ? val.value.match(/Stockist/gi) : false;
    }

    getSAPCode() {
        this.spinner.show();
        this.http.get(`/account-management/get-SAPCode`).subscribe(data => {
            // if (data.status == 200) {
            this.createAcct.sapCustomerCode = data;
            // }
            this.spinner.hide();
        });
    }

    preparePager() {
        this.pager = {
            itemsPerPage: this.perPage,
            currentPage: this.pageNumber,
            totalItems: this.usersList.length
        };
    }

    setSAPCode() {
        if (this.createAcct.customerGroupId != 1 && this.createAcct.customerGroupId !=null && this.createAcct.divisionId == 3) {
            this.disableSAPCode = true;
            if(this.edit==false && this.createAcct.parentAccountCode!=""){
                this.getSAPCode();
            }
        } else {
            this.disableSAPCode = false;
        }
    }

    resetStockiestSAPCodeAndSAPCode(){
        this.setSAPCode();
        this.createAcct.sapCustomerCode = "";
        this.createAcct.parentAccountCode = "";
    }

    filters() {
        const data = this.accountData;
        let filter = false;
        Object.keys(data).forEach(key => {
            if (data[key]) {
                if (Array.isArray(data[key]) && data[key].length > 0) {
                    filter = true;
                }
                if (!Array.isArray(data[key])) {
                    filter = true;
                }
            }
        });
        return filter;
    }

    downloadExcelFormReset() {
        this.errMsg = ''
        this.downloadExcelFilter = {
            startDate: this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'),
            endDate: this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),
        }
    }

    filterObj(fd, td) {
        let url;
        if (this.filters()) {
            let filterParams = '?';
            Object.keys(this.accountData).forEach(key => {
                if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
                    if (!Array.isArray(this.accountData[key])) {
                        if (key == 'divisionId') {
                            filterParams += `div=`;
                        } else {
                            filterParams += `${key}=`;
                        }
                        if (key == 'state') {
                            filterParams += `${this.findstatename(this.accountData[key])}&`;
                        } else {
                            filterParams += `${this.accountData[key]}&`;
                        }
                    } else {
                        if (this.accountData[key].length > 0) {
                            filterParams += `primaryowner=`;
                            Object.keys(this.accountData[key]).forEach(subkey => {
                                filterParams += `${this.accountData[key][subkey]['userId']},`;
                            });
                            //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
                            filterParams = filterParams.substring(0, filterParams.length - 1);
                            filterParams += `&`;
                        }
                    }
                }
            });
            filterParams = filterParams.substring(0, filterParams.length - 1);
            url = `${environment.BASE_ENDPOINT}/ctsinfluencer/download-influencer${filterParams}&fromDate=${fd}&toDate=${td}`;
            return url
        }
        else {
            url = `${environment.BASE_ENDPOINT}/ctsinfluencer/download-influencer?&fromDate=${fd}&toDate=${td}`;
            return url
        }

    }

    downloadExcelData() {
        let fd = this.downloadExcelFilter.startDate;
        let td = this.downloadExcelFilter.endDate;
        let FD = new Date(fd);
        let TD = new Date(td);
        let no_of_days;
        try {
            if (fd !== "" && td !== "") {
                if (FD.getTime() <= TD.getTime()) {
                    no_of_days = (TD.getTime() - FD.getTime()) / (1000 * 3600 * 24);
                    if (no_of_days <= 30) {
                        let url = this.filterObj(fd, td);
                        // window.location.href = environment.BASE_ENDPOINT+"/account-management/filter-download?fromDate="+fd+"&toDate="+td+"&div="+div+"&state="+stateId[0].value+"&city="+city+"&district="+district+"&primaryowner="+primaryowner;
                        // console.log("url",url);
                        
                        window.location.href = url;

                        document.getElementById("downloadExcelClose").click();
                        this.errMsg = ""
                    }
                    else {
                        this.errMsg = "30 days limit exceed !"
                    }
                }
                else {
                    this.errMsg = "No records found in this time period!"
                }
            }
            else {
                this.errMsg = "All Fields are required!";
            }
        } catch (error) {
            console.error("Error", error);
        }

        // this.downloadExcelFormReset();

    }

    downloadFile(value?) {
        let url;
        if (!value && this.filters()) {
            let filterParams = '?';
            Object.keys(this.accountData).forEach(key => {
                if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
                    if (!Array.isArray(this.accountData[key])) {
                        if (key == 'divisionId') {
                            filterParams += `div=`;
                        } else {
                            filterParams += `${key}=`;
                        }
                        if (key == 'state') {
                            filterParams += `${this.findstatename(this.accountData[key])}&`;
                        } else {
                            filterParams += `${this.accountData[key]}&`;
                        }
                    } else {
                        if (this.accountData[key].length > 0) {
                            filterParams += `primaryowner=`;
                            Object.keys(this.accountData[key]).forEach(subkey => {
                                filterParams += `${this.accountData[key][subkey]['userId']},`;
                            });
                            //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
                            filterParams = filterParams.substring(0, filterParams.length - 1);
                            filterParams += `&`;
                        }
                    }
                }
            });
            filterParams = filterParams.substring(0, filterParams.length - 1);
            url = `${environment.BASE_ENDPOINT}/account-management/filter-download${filterParams}`;
        } else {
            // url = value ? `${environment.BASE_ENDPOINT}/account-management/download?key=${value}` :
            url = value ? `${environment.BASE_ENDPOINT}/ctsinfluencer/influencer-template-download` :
                `${environment.BASE_ENDPOINT}/ctsinfluencer/influencer-template-download`;
        }
        window.location.href = url;
    }

    findstatename(stateid) {
        let name = '';
        Object.keys(this.stateList).forEach(key => {
            if (this.stateList[key]['id'] == stateid) {
                name = this.stateList[key]['value'];
            }
        });
        return name;
    }

    getDivisionName(id) {
        if (this.divisonList.length && id) {
            const v = this.divisonList.find(val => val.id == id);
            return v ? v.value : id;
        }
        return id;
    }

    getCustomeTypeName(id) {
        if (this.customerType.length && id) {
            const v = this.customerType.find(val => val.id == id);
            return v ? v.value : id;
        }
        return id;
    }

    onFileSelect(event, type) {
        if (event.target.files.length > 0) {
            this.spinner.show();
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            // this.http.post('/account-management/upload?key=' + type, formData).subscribe(
            this.http.post(`/ctsinfluencer/${type}-influencers/`, formData).subscribe((res) => {
                // console.log("fileSelection", res);
                
                    this.spinner.hide();
                    let str = '';
                    // event.srcElement.value = null;
                    // if (res.map['success']) {
                    //     str += "<h4> success:" + res.map['success'] + " </h4>"
                    // }
                    // if (res.map['failure']) {
                    //     str += "<br/><a href='" + `${environment.BASE_ENDPOINT}/account-management/download-failed` + "'><h4> failure:" + res.map['failure'] + " </h4></a>"
                    // }
                    Swal.fire({
                        title: res.message,
                        html: str,
                        timer: 150000,
                    });
                    this.find();
                    
                    // Resetting file input
                    event.target.value = "";
                },
                (err) => {
                    this.spinner.hide();
                    event.srcElement.value = null;
                    Swal.fire({
                        title: err.error ? err.error.message : 'Error saving account',
                        timer: 150000,
                    });
                }
            );
        }
    }

    checkMObileNum() {
        if (String(this.createAcct.customerPhone).length > 9) {
            this.mobileError = false;
            return true;
            this.http.get(`/account-management/Check-Mob?mob=${this.createAcct.customerPhone}`).subscribe((res) => {
                const exists = 'Mobile Number Exists';
                if (res.message === exists) {
                    this.mobileError = true;
                }
            });
        }
        else{
            this.mobileError = true;
            return true;
        }
    }

    getAllOwners() {
        this.spinner.show();
        this.http.get(`/account-management/get-all-primaryowner?divId=3`).subscribe((res) => {
            this.allOwnersList = this.objectToArrayOwner(res.data["user details"]);
            this.spinner.hide();
        });
    }

    getOwnerName(prmowner) {
        if (prmowner) {
            return prmowner.firstName + " " + prmowner.lastName + " (" + prmowner.employeeId + ")"
        }
        return '';
    }

    getOwnerTCode(id) {
        if (this.allOwnersList.length && id) {
            const arr = Array.isArray(id) ? id : id.split(',');
            const val = arr.map(o => {
                return this.allOwnersList.find(v => v.userId == o.userId) ? this.allOwnersList.find(v => v.userId == o.userId).territoryCode : o.userId;
            });
            return val;
        }
        return id;
    }

    find(event = null) {
        this.spinner.show();
        let filterParams = '?pageNo=' + (this.page - 1) + '&pageSize=' + this.itemsPerPage + '&';
        Object.keys(this.accountData).forEach(key => {
            if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
                if (!Array.isArray(this.accountData[key])) {
                    if (key == 'divisionId') {
                        filterParams += `divId=`;
                    } 
                    else if (key == 'inf_district' || key == 'district') {
                        filterParams += `district=`;
                    }
                    else if (key == 'inf_city' || key == 'city') {
                        filterParams += `city=`;
                    } 
                    else if (key == 'inf_state' || key == 'state') {
                        filterParams += `state=`;
                    } 
                    else {
                        filterParams += `${key}=`;
                    }
                    if (key == 'inf_state') {
                        filterParams += `${this.findstatename(this.accountData[key])}&`;
                        // filterParams += `${this.accountData[key]}&`;
                    } 
                    else if (key == 'state') {
                        // filterParams += `${this.findstatename(this.accountData[key])}&`;
                        filterParams += `${this.accountData[key]}&`;
                    } 
                    else {
                        filterParams += `${this.accountData[key]}&`;
                    }
                    //filterParams += `${this.accountData[key]}&`;
                } else {
                    if (this.accountData[key].length > 0) {
                        filterParams += `primaryowner=`;
                        Object.keys(this.accountData[key]).forEach(subkey => {
                            filterParams += `${this.accountData[key][subkey]['loginID']},`;
                        });
                        //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
                        filterParams = filterParams.substring(0, filterParams.length - 1);
                        filterParams += `&`;
                    }
                }
            }
        });
        filterParams = filterParams.substring(0, filterParams.length - 1);
        this.usersList = [];
        this.http.get(`/ctsinfluencer/getInfluencerList${filterParams}`).subscribe((res) => {
            this.usersList = res.data;
            this.totalItems = res.count;
            this.spinner.hide();
        });
    }
    
    // findInfluencerList(event = null) {
    //     this.spinner.show();
    //     let filterParams = '?pageNo=' + (this.page - 1) + '&pageSize=' + this.itemsPerPage + '&';
    //     Object.keys(this.influenceData).forEach(key => {
    //         if (this.influenceData[key] || (Array.isArray(this.influenceData[key]) && this.influenceData[key].length)) {
    //             if (!Array.isArray(this.influenceData[key])) {
    //                 if (key == 'divisionId') {
    //                     filterParams += `divId=`;
    //                 } else {
    //                     filterParams += `${key}=`;
    //                 }
    //                 if (key == 'state') {
    //                     filterParams += `${this.findstatename(this.influenceData[key])}&`;
    //                 } else {
    //                     filterParams += `${this.influenceData[key]}&`;
    //                 }
    //                 //filterParams += `${this.influenceData[key]}&`;
    //             } else {
    //                 if (this.influenceData[key].length > 0) {
    //                     filterParams += `primaryowner=`;
    //                     Object.keys(this.influenceData[key]).forEach(subkey => {
    //                         filterParams += `${this.influenceData[key][subkey]['loginID']},`;
    //                     });
    //                     //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
    //                     filterParams = filterParams.substring(0, filterParams.length - 1);
    //                     filterParams += `&`;
    //                 }
    //             }
    //         }
    //     });
    //     filterParams = filterParams.substring(0, filterParams.length - 1);
    //     this.usersList = [];
    //     this.http.get(`/ctsinfluencer/getInfluencerList${filterParams}`).subscribe((res) => {
    //         this.usersList = res.data;
    //         this.totalItems = res.message;
    //         this.spinner.hide();
    //     });
    // }

    findFilter(event = null) {
        // this.accountData.infCategory = '';
        // this.accountData.city = '';
        // this.accountData.infContact = '';
        // this.accountData.district = '';
        // this.accountData.infName = '';
        // this.accountData.pincode = '';
        // this.accountData.state = '';
        // this.accountData.divisionId="3"
        this.find();
        // this.findInfluencerList()
    }

    resetFilters(event = null) {
        this.accountData = {
            ...this.accountData,
            inf_state: '',
            divisionId: '3',
            inf_city: '',
            inf_district: '',
            inf_allAccountOwner: [],
            // customer_name: this.accountData.customer_name,
            // mobileNo: this.accountData.mobileNo,
            // customerEmail: this.accountData.customerEmail,
            // gstNumber: this.accountData.gstNumber,
            // territoryCode: this.accountData.territoryCode,
            // status: this.accountData.status,
            // type: this.accountData.type,
            // customerCode:this.accountData.customerCode,
            // divisionName:this.accountData.divisionName,
        };
        // this.influenceData = {
        //     city: "",
        //     district: "",
        //     pincode: "",
        //     state: "",
        //     divisionId:"3"
        // };
        this.find();
    }

    filterDropDown(type, val) {
        if (type == "edit") {
            this.CityChange(val, 'e');
            this.talukaChange(val,'e');
            this.localityChange(val,'e');
        }
        if (type == "dt") {
            this.CityChange(val);
            this.talukaChange(val);
            this.localityChange(val);
        }
        if (type == "ct") {
            this.talukaChange(val);
            this.localityChange(val);
            
        }
        if (type == "taluka") {
            this.localityChange(val);
            
        }

    }

    CityChange(val, type=null) {
        if(!type){
            this.createAcct.city = '';
            this.createAcct.locality = '';
            this.createAcct.taluka = '';
        }
        if (this.createAcct.district) {
            this.ct = this.pincodedata.filter(ele => ele.districtName == this.createAcct.district);
            this.ct = [...new Map(this.ct.map(item =>
                [item.cityName, item])).values()];
            if(!type)
                this.createAcct.city = this.ct[0].cityName;
            // else
        }
    }
    talukaChange(val, type=null) {

        if (this.createAcct.city) {
            this.taluka = this.pincodedata.filter(ele => (ele.districtName == this.createAcct.district && ele.cityName == this.createAcct.city))
            this.taluka = [...new Map(this.taluka.map(item =>
                [item.taluka, item])).values()];
            if(!type)
                this.createAcct.taluka = this.taluka[0].taluka;
        }
    }
    localityChange(val, type=null) {

        if (this.createAcct.taluka) {
            this.locality = this.pincodedata.filter(ele => (ele.districtName == this.createAcct.district && ele.cityName == this.createAcct.city && ele.taluka == this.createAcct.taluka))
            this.locality = [...new Map(this.locality.map(item =>
                [item.locality, item])).values()];
            if(!type)
                this.createAcct.locality = this.locality[0].locality;
        }
    }

    getAddressByPincode(pincode, edituser = null) {
        if (pincode && String(pincode).length === 6 && (this.oldPincode != pincode) && (this.createAcct.divisionId = "3")) {
            this.spinner.show();
            this.oldPincode = pincode;
            // this.http.get(`/geography-master/get-by-pin-code/${this.createAcct.address.pinCode}`).subscribe((data) => {
            this.http.get(`/geography-master/get-by-pin-code/${pincode}/${this.createAcct.divisionId}`).subscribe((data) => {
                this.spinner.hide();
                if (data.status == '200') {
                    if (data.data.length == 0) {
                        this.pincodeError = 'Pincode Is Incorrect';
                    }
                    else {
                        this.pincodeError = '';
                        // this.pincodedata = data.data;
                        this.pincodedata = data.data;
                        this.dt = [...new Map(this.pincodedata.map(item =>
                            [item.districtName, item])).values()];

                        this.ct = [...new Map(this.pincodedata.map(item =>
                            [item.cityName, item])).values()];

                        this.taluka = [...new Map(this.pincodedata.map(item =>
                            [item.taluka, item])).values()];

                        this.locality = [...new Map(this.pincodedata.map(item =>
                            [item.locality, item])).values()];

                        if (!edituser) {
                            //this.createAcct.address.pincode = data.data[0].pinCode;
                            //this.createAcct.address = data.data[0];
                            // this.createAcct.address.pinCode = data.data[0].pinCode;
                            this.createAcct.state = data.data[0].stateName;
                            // this.stateSelected(data.data[0].stateCode);
                            this.createAcct.city = data.data[0].cityName;
                            this.createAcct.district = data.data[0].districtName;
                            this.createAcct.status = 1;
                            this.createAcct.locality = data.data[0].locality;
                            this.createAcct.taluka = data.data[0].taluka;
                            this.createAcct.geographyMasterId = data.data[0].id;
                        }

                        //this.filterDropDown('dt',this.createAcct.address.district);
                        //this.filterDropDown('ct',this.createAcct.address.city);
                        //this.filterDropDown('taluka',this.createAcct.address.taluka);


                        if (edituser) {
                            this.createAcct.state = this.createAcct.state;
                            // this.stateSelected(data.data[0].stateCode);
                            this.createAcct.district = this.createAcct.district;
                            // this.createAcct.district = this.createAcct.district.toUpperCase();
                            // this.createAcct.address.status = 1;
                            
                            this.createAcct.city = this.createAcct.city;
                            // this.createAcct.city = this.createAcct.city.charAt(0).toUpperCase() + this.createAcct.city.substr(1).toLowerCase();;
                            this.createAcct.locality = this.createAcct.locality;
                            this.createAcct.taluka = this.createAcct.taluka;
                            this.filterDropDown('edit',this.createAcct.district);
                            // this.filterDropDown('edit',this.createAcct.district.toUpperCase());
                            
                        }

                        // let val = data.data[0].stateCode > 9 ? data.data[0].stateCode : '0' + data.data[0].stateCode;
                        let val = data.data[0].stateCode;
                        this.getOwnerByState(val);
                    }
                }
            });
        }
    }

    getCheckSapCode(sapcode) {
        if (sapcode && String(sapcode).length >= 6) {
            this.spinner.show();
            this.http.get(`/account-management/Check-SAPCode?sapCode=${sapcode}`).subscribe((data) => {
                this.spinner.hide();
                if (data.message == 'SAP Code does not Exists') {
                    this.sapcodeError = 'SAP Code does not Exists';
                    this.sapcodeVError = true;
                } else {
                    this.sapcodeError = "";
                        this.setSAPCode();
                    this.sapcodeVError = false;
                }
            });
        }
    }

    onKeySearch(event: any) {
        this.page = 1;
        if (event.keyCode == 13) {
            this.find();
            this.timeout = null;
            return;
        }
        clearTimeout(this.timeout);
        var $this = this;
        this.timeout = setTimeout(function () {
            if (event.keyCode != 13) {
                $this.find();
            }
        }, 1000);
    }

    transferInfluencer(){
        console.log(this.transferList.ids)
        if(this.transferList.createdBy.length != 0 || this.transferList.createdBy === null || this.transferList.createdBy===undefined){
            this.spinner.show();
                this.http.put(`/ctsinfluencer/transfer-influencer?ids=${this.transferList.ids.filter((ele, ind) =>ind === this.transferList.ids.findIndex((elem) => elem === ele))}&createdBy=${this.transferList.createdBy[0].employeeid}`).subscribe((data) => {
                    this.spinner.hide();
                    let str = '';
                    if(data.status === 200){
                        // this.http.get(`/lead-admin/donwloadFailedResult?type=Create`).subscribe((resp) => {
                        //     console.log(resp,"resp")
                        // })                        
                        str += "<br/><a href='" + `${environment.BASE_ENDPOINT}/lead-admin/donwloadFailedResult?type=Create` + "'><h4> Download Error Log " + " </h4></a>"
                        Swal.fire({
                            icon:"success",
                            title: data.message,
                            // html:str,
                            timer: 150000,
                        });
                        this.selectAll = false;
                        this.transferList.ids.forEach(ele=>{
                            (document.getElementById("checkboxInf_"+ele) as HTMLInputElement).checked = false;
                        })
                        this.transferList.createdBy=[]
                        this.transferList.ids=[]
                    }
                });
            (jQuery("#influencerTransfer") as any).modal("hide");
        }
        else{
            alert("Please select the user !")
        }
    }

    addInfluencerInTheList(event,user){  
        if((document.getElementById("checkboxInf_"+user.id) as HTMLInputElement).checked){
            (document.getElementById("checkboxInf_"+user.id) as HTMLInputElement).checked = true;
        }else{
            (document.getElementById("checkboxInf_"+user.id) as HTMLInputElement).checked = false;
        }        
        // (document.getElementById("checkboxInf_"+user.id) as HTMLInputElement).checked = true;
        if(this.transferList.ids.length == 0){
            this.transferList.ids.push(user.id);
        }
        else if(this.transferList.ids.length > 0){
            let exist = this.transferList.ids.filter(ele => ele === user.id);
            if(!exist[0]){
                this.transferList.ids.push(user.id);
            }
            else{
                let indx = this.transferList.ids.indexOf(exist[0]);
                this.transferList.ids.splice(indx,1) ;
            } 
            if(this.transferList.ids.length !== 10){
                this.selectAll = false;
            }
            if(this.transferList.ids.length === 10){
                this.selectAll = true;
            }
        }
    }

    openTransfer(){
        if(this.transferList.ids.length < 1){
            Swal.fire({
                icon:"error",
                title: "Please select the influencer!",
                timer: 150000,
            });
        }
        else{
            // Swal.fire({
            //     icon:"success",
            //     title: "Transferred Successfully!",
            //     timer: 150000,
            // });
            (jQuery("#influencerTransfer") as any).modal("show");
        }
    }
    
    selectAllData(){
        if(this.selectAll){
            this.usersList.filter((ele, ind) =>ind === this.usersList.findIndex((elem) => elem === ele)).map(ele =>{
                (document.getElementById("checkboxInf_"+ele.id) as HTMLInputElement).checked = true;
                console.log(ele.id,"----------------")
                this.transferList.ids.push(ele.id)
            })
        }
        else{
            this.usersList.map(ele =>{
                (document.getElementById("checkboxInf_"+ele.id) as HTMLInputElement).checked = false;
                this.transferList.ids=[]
            })
        }
    }

  }
