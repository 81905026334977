import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppserviceService {

  constructor() {


   }

     sub=new Subject<String>();

     setUrl(url){
this.sub.next(url);
     }

}
