import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fulllayout-component',
  templateUrl: './fulllayout-component.component.html',
  styleUrls: ['./fulllayout-component.component.css']
})
export class FulllayoutComponentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
