import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JcpComponent } from './jcp/jcp.component';
import { CounterWiseJcpComponent } from './counter-wise-jcp/counter-wise-jcp.component';
import { MonthWiseVisitsComponent } from './month-wise-visits/month-wise-visits.component';

const routes: Routes = [
  {path: 'jcp', component: JcpComponent},
  {path: 'date-wise-visits', component: CounterWiseJcpComponent},
  {path: 'month-wise-visits', component: MonthWiseVisitsComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JcpRoutingModule { }
