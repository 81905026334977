import { Component, OnInit } from '@angular/core';

import { HttpClientService } from '../../http-client-service.service';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { first, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { log } from 'util';


declare var jQuery: any;

@Component({
  selector: 'app-jcp',
  templateUrl: './jcp.component.html',
  styleUrls: ['./jcp.component.css']
})
// export class JcpComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }

export class JcpComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  userListing: any = [];
  reportingMnager: any = [];
  userreportingMnager: any = [];
  userdropdownSettings: any;
  dropdownSettings: any;
  statusList: any = [
      {
          id: 1,
          value: 'Active'
      },
      {
          id: 0,
          value: 'InActive'
      }
  ];
  filterData: any = {
      state: '',
      reporing_manager: [],
      division: '',
      designationid: '',
      status: '',
      userName: '',
      empId: '',
      month:'',
      year:'',
  };
  searchFilterData: any = {
      designationName:'',
      stateName:'',
      divisionName:'',
      firstName:'',
      lastName:'',
      loginId:'',
      userName: '',
      empId: '',
  };
  divisionList: [any];
  viewUser: any;
  pageNumber: number = 1;
  totalItem: number;
  perPage = 10;
  pager: { itemsPerPage: any; currentPage: any; totalItems: any; };;
  createUser: FormGroup;
  filterForm: FormGroup;
  designationList: any;
  filterdesignationList: any;
  statelist: any;
  managerList: any;
  departmentList: any;
  filtermanagerList: any;
  allchannel: any;
  updateuser: any;
  territyError: boolean;
  LoginIDError: boolean;
  PasswordError: boolean;
  todayDate: any = new Date();
  addcreateAcct: any;
  createAcct: any = {};
  page: number = 1;
  itemsPerPage: number = 10;
  totalItems : any;
  timeout: any = null;
  userStatus: any ;
  validationErrorMessage : string;

  list_of_months = [
    {id:0, value:'January'}, 
    {id:1, value:'Febrary'}, 
    {id:2, value:'March'}, 
    {id:3, value:'April'}, 
    {id:4, value:'May'}, 
    {id:5, value:'June'}, 
    {id:6, value:'July'}, 
    {id:7, value:'August'}, 
    {id:8, value:'September'}, 
    {id:9, value:'October'}, 
    {id:10, value:'November'}, 
    {id:11, value:'December'}
];
    errMsg: string = "";
    fromDate: any = new Date();
    toDate: any = new Date();
    downloadExcelFilter: any = {};
    commentForRejection = {
        id:'',
        msg:'',
        error:'',
        userId:'',
    };
    monthWiseVisit=[];
    transferList = {
        delete:false,
        jcpId:[],
        userId:'',
        statusCode:'',
        comment: '',
    };
    action:"";
    visitListing:[];
    selectAll:boolean = false;

  constructor(private http: HttpClientService, public datepipe: DatePipe , private spinner: NgxSpinnerService, private route: Router) {
      this.createUser = new FormGroup({
          employeeId: new FormControl('', [
              Validators.required,
          ]),
          areaOffice: new FormControl('', [
              Validators.required,
          ]),
          name: new FormControl('', [
              Validators.required,
          ]),
          lastName: new FormControl('', [
              Validators.required,
          ]),
          loginId: new FormControl('', [
              Validators.required,
          ]),
          email: new FormControl('', [
              Validators.required,
              Validators.email
          ]),
          personalEmail: new FormControl('', [
              Validators.email
          ]),
          officeEmail: new FormControl('', [
              Validators.email
          ]),
          territeryCode: new FormControl('', [
              Validators.required
          ]),
          status: new FormControl('', [
              Validators.required
          ]),
          personalMobile: new FormControl(''),
          officeMobile: new FormControl(''),
          rm: new FormControl(''),
          designation: new FormControl(''),
          division: new FormControl(''),
          channel: new FormControl(''),
          state: new FormControl(''),
          jkState: new FormControl(''),
          password: new FormControl('', [
              Validators.required,
          ]),
          confirmPassword: new FormControl(''),
          address: new FormControl(''),
          dob: new FormControl('')
      });
      this.filterForm = new FormGroup({
          state: new FormControl(''),
          division: new FormControl(''),
          manager: new FormControl('')
      })
  }

  ngOnInit() {

      this.dtOptions = {
          pagingType: 'full_numbers',
          ordering: false,
      };
      this.todayDate = this.datepipe.transform(this.todayDate, 'yyyy-MM-dd')
      // console.log(this.todayDate);
      this.page=1;
      this.getUserLst();
      this.getDivisionList();
      this.getDistributionChannel();
      this.getAllState();
      this.getDepartmentList();
      this.getfilterAllDesignation();

      this.createUser.get('division').valueChanges.pipe(
          map(v => {
              // console.log(v);
              this.getAllDesignation();
              this.getManagers();
          })
      ).subscribe(a => {
          // console.log(a);
      })
      this.filterForm.get('division').valueChanges.pipe(
          map(v => {
              this.getAllDesignation();
              this.getManagers();
          })
      ).subscribe(a => {
          // console.log(a);
      })
      this.createUser.get('territeryCode').valueChanges.pipe(
          map(v => {
              // console.log(v);
              this.checkerriteryCode();
          })
      ).subscribe(a => {
          // console.log(a);
      })
      this.dropdownSettings = {
          singleSelection: true,
          idField: 'userId',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 8,
          allowSearchFilter: true
      };
      this.userdropdownSettings = {
          singleSelection: true,
          idField: 'userId',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 8,
          allowSearchFilter: true
      };
      this.userreportingMnager = [];
  }

  getUserLst( event = null) {
      this.userListing = [];
      this.spinner.show();
      let filterParams = '?pageNo='+(this.page-1)+'&pageSize='+this.itemsPerPage+'&';
      filterParams += this.filterData.state?'stateCode='+this.filterData.state+'&':'';
    //   filterParams += this.filterData.division?'division='+this.filterData.division+'&':'';
    //   filterParams += this.filterData.reporing_manager.length > 0 ?'reportingTo='+this.filterData.reporing_manager[0]['userId']+'&':'';
    filterParams += this.filterData.designationid?'designation='+this.filterData.designationid+'&':'';
      filterParams += this.filterData.month?'month='+this.filterData.month+'&':'';
      filterParams += this.filterData.status?'status='+this.filterData.status+'&':'';
      filterParams += this.filterData.year?'year='+this.filterData.year+'&':'';
      filterParams += this.searchFilterData.status?'status='+this.searchFilterData.status+'&':'';
      filterParams += this.searchFilterData.userName?'userName='+this.searchFilterData.userName+'&':'';
      filterParams += this.searchFilterData.empId?'empId='+this.searchFilterData.empId+'&':'';
    //   filterParams += this.searchFilterData.firstName?'firstName='+this.searchFilterData.firstName+'&'+'lastName='+this.searchFilterData.lastName+'&':'';
    //   filterParams += this.searchFilterData.stateName?'stateName='+this.searchFilterData.stateName+'&':'';
    //   filterParams += this.searchFilterData.divisionName?'divisionName='+this.searchFilterData.divisionName+'&':'';
    //   filterParams += this.searchFilterData.loginId?'loginId='+this.searchFilterData.loginId+'&':'';
      filterParams += this.searchFilterData.jcpName?'jcpName='+this.searchFilterData.jcpName+'&':'';
    //   http://localhost:8080/umber-rest/lead-admin/get-all-jcps?month=9&year=2023&status=1&pageNo=1&pageSize=10&jcpName=1001556_Oct_2023&empId=1001556&userName=Vikas%27

      this.http.get(`/lead-admin/get-all-jcps${filterParams}`).pipe(first()).subscribe(data => {
          if (data.status == '200') {            
              this.userListing = data.data;
              //this.preparePager();
              this.totalItems = data.message;
              this.spinner.hide();
          }
      })
  }

  preparePager() {
      this.pager = {
          itemsPerPage: this.perPage,
          currentPage: this.pageNumber,
          totalItems: this.userListing.length
      }
  }

  getDivisionList() {
      this.http.get('/ctslead/getAllDivision').subscribe(data => {
          if (data.status == '200') {
              this.divisionList = data.data;
          }
      })
  }
  getDepartmentList() {
      this.http.get('/ctslead/getAllDepartment').subscribe(data => {
          if (data.status == '200') {
              this.departmentList = data.data;
          }
      })
  }
  getAllState() {
      this.http.get('/state/get-all').subscribe(data => {
          if (data.status == '200') {
              this.statelist = data.data;
          }
      })
  }
  getDistributionChannel() {
      this.http.get('/ctslead/getAllDistribution').subscribe(data => {
          if (data.status == '200') {
              this.allchannel = data.data;
          }
      });
  }

  changePage(e) {
      console.log(e);
  }

  next(e) {
      // console.log(e);
      this.pageNumber++;
      // this.getUserLst();
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
      let pass = group.get('password').value;
      let confirmPass = group.get('confirmPassword').value;

      return pass === confirmPass ? null : { notSame: true }
  }

  previous() {
      this.pageNumber--;
      this.getUserLst();
  }
  getAllDesignation() {
      if (!this.createAcct.division) {
          return;
      }
      this.http.get(`/ctslead/getAllDesignation/${this.createAcct.division}`).subscribe(data => {
          if (data.status == '200') {
              this.designationList = data.data;
              // this.createAcct.designation = "";
          }
      })
  }
  saveUser() {
      this.createUserReq(this.parseData(this.createUser.value));
  }

  mobileValidation = () => {
      let arr = ["6","7","8","9"]
      if(this.createAcct.personalMobile){
          if(arr.includes(this.createAcct.personalMobile[0]) /*&& arr.includes(this.createAcct.officeMobile[0])*/ && this.createAcct.personalMobile.length == 10 /*&& this.createAcct.officeMobile.length == 10*/){
              return true
          }
          else{
              return false
          }
      }
      else{
          return false
      }
  }
  createUsernew() {
      // this.setPrimaryOwner();
      if (this.validation()) {
          if(!this.LoginIDError && !this.territyError){
              alert(this.validationErrorMessage);
          }
      }
      else {
          this.spinner.show();
          let mobValid = this.mobileValidation();
          if(mobValid){
              if (this.updateuser) {
                  const reqData = { ...this.parseDatanew(), id: this.createAcct.id };
                  this.http.post(`/ctslead/updateUser`, reqData).subscribe(data => {
                      (jQuery("#usercreate") as any).modal("hide");
                      Swal.fire({
                          title: data.message,
                          timer: 150000,
                      });
                      this.reset();
                      this.page=1;
                      this.getUserLst();
                      this.spinner.hide();
                  }, err => {
                      if(err.error.status === 500){
                          alert(err.error.message)
                      }
                      else{
                          alert('Error saving details')
                      }
                      // Swal.fire({
                      //     title: err.error.status === 500 ? err.error.message : 'Error saving details',
                      //     timer: 150000,
                      // });
                      this.spinner.hide();
                  });
              } 
              else{
                  const reqData = { ...this.parseDatanew() };
                  this.http.post(`/ctslead/createUser`, reqData).subscribe(data => {
                      (jQuery("#usercreate") as any).modal("hide");
                      Swal.fire({
                          title: data.message,
                          timer: 150000,
                      });
                      this.reset();
                      this.page=1;
                      this.getUserLst();
                      this.spinner.hide();
                  }, err => {
                      if(err.error.status === 500){
                          alert(err.error.message)
                      }
                      else{
                          alert('Error saving details')
                      }
                      // (jQuery("#usercreate") as any).modal("hide");
                      // Swal.fire({
                      //     title: err.error.status === 500 ? err.error.message : 'Error saving details',
                      //     timer: 150000,
                      // });
                      this.spinner.hide();
                  });
              }
          }
          else{
              alert("Please fill valid mobile number.");
              this.spinner.hide();
          }
      }
  }

  onDropDownClose(val) {
    //   console.log(this.filterData, 'valueee onDropDownClose');
  }

  objectToArrayOwner(obj) {
      const arr = [];
      Object.keys(obj).forEach(key => {
          arr.push(
              {
                  userId: obj[key]['parentPositionCode'],
                  userName: obj[key]['firstName'] + " " + obj[key]['lastName'] + "(" + obj[key]['employeeId'] + ")"
              }
          );
      });
      return arr;
  }
  getManagers() {
      if (this.createAcct.division) {
          this.http.get(`/ctslead/getReportingManager/${this.createAcct.division}`).subscribe(data => {
              //this.managerList = data.data;
              this.userreportingMnager = this.objectToArrayOwner(data.data);
          });
      }
  }
  getfilterManagers() {
      if (this.filterData.division) {
          this.getfilterAllDesignation();
      }
      if (this.filterData.division && this.filterData.state) {
          this.http.get(`/ctslead/getReportingManagerV2/${this.filterData.state}/${this.filterData.division}`).subscribe(data => {
              //this.filtermanagerList = data.data;
              this.reportingMnager = this.objectToArrayOwner(data.data);
          });
      }
  }
  getfilterAllDesignation() {
    //   if (!this.filterData.division) {
    //       return;
    //   }
      this.http.get(`/ctslead/getAllDesignation/2`).subscribe(data => {
          if (data.status == '200') {
              this.filterdesignationList = data.data;
          }
      })
  }
  searchUser() {
    //   console.log(this.searchUser());
  }

  sapAccountIdDisable(){
      if (this.createAcct.division==2 && this.createAcct.channel==10) {
          return false
      } else {
          return true            
      }
  }

  createUserReq(data) {
      if (this.updateuser) {
          this.http.post(`/ctslead/updateUser`, {
              ...this.parseData(this.createUser.value),
              id: this.updateuser.id
          }).subscribe((a) => {
              (jQuery("#usercreate") as any).modal("hide");
              this.createUser.reset();
              this.getUserLst();
              this.updateuser = null;
              Swal.fire({
                  title: 'User Updated successfully',
                  timer: 300000
              });
          })
      } else {
          this.http.post(`/ctslead/createUser`, data).subscribe((a) => {
              (jQuery("#usercreate") as any).modal("hide");
              this.createUser.reset();
              this.getUserLst();
              Swal.fire({
                  title: 'User Created successfully',
                  timer: 300000
              });
          })
      }

  }

  parseData(formData) {
      return {
          "isWithoutAdAllowed": 1,
          "employeeId": formData.employeeId,
          "firstName": formData.name,
          "lastName": formData.lastName,
          "email": formData.email,
          "personalEmail": formData.personalEmail,
          "officeEmail": formData.officeEmail,
          "loginId": formData.loginId,
          "dob": formData.dob,
          "parentPositionCode": formData.rm[0]['userId'],
          "designationId": formData.designation,
          "divisionId": formData.division,
          "distributionChannelId": formData.channel,
          "jkState": formData.state,
          "status": 1,
          "address": formData.address,
          "password": formData.password,
          "authorities": "",
          "designationName": "",
          "distChannelName": "",
          "divisionName": "",
          "id": 0,
          "parentFirstName": "",
          "parentLastName": "",
          "territeryCode": formData.territeryCode,
          "departmentId": formData.departmentId

      }
  }
  parseDatanew() {
      return {
          "isWithoutAdAllowed": 1,
          "employeeId": this.createAcct.employeeId,
          "firstName": this.createAcct.firstName,
          "lastName": this.createAcct.lastName,
          "email": this.createAcct.email,
          "personalEmail": this.createAcct.personalEmail,
          "officeEmail": this.createAcct.officeEmail,
          "loginId": this.createAcct.loginId,
          "dob": this.createAcct.dob,
          "parentPositionCode": this.createAcct.rm[0]['userId'],
          "designationId": this.createAcct.designation,
          "divisionId": this.createAcct.division,
          "distributionChannelId": this.createAcct.channel,
          "jkState": this.createAcct.jkState,
          "state": this.createAcct.state,
          "status": this.createAcct.status,
          "address": this.createAcct.address,
          "password": this.createAcct.password,
          "personalMobile": this.createAcct.personalMobile,
          "officeMobile": this.createAcct.personalMobile,
          // "officeMobile": this.createAcct.officeMobile,
          "authorities": "",
          "designationName": "",
          "distChannelName": "",
          "divisionName": "",
          "id": 0,
          "parentFirstName": "",
          "parentLastName": "",
          "territeryCode": this.createAcct.territeryCode,
          "departmentId": this.createAcct.departmentId,
          "areaOffice": this.createAcct.areaOffice,
          // "sapAccountantEmailId": this.createAcct.sapAccountantEmailId
      }
  }

  update(data) {
      this.spinner.show();
      this.userStatus = data.status
      this.http.get(`/ctslead/getUserView/${data.id}`).subscribe(a => {
          this.updateuser = true;  
          this.createAcct = a.data;
          this.createAcct.division = a.data.divisionId;
          // this.createAcct.channel = a.data.distributionChannelId;
          if (a.data.distributionChannelId === 1 || a.data.distributionChannelId === 10) {
              this.createAcct.channel = 10;
          }
          if (a.data.distributionChannelId === 2 || a.data.distributionChannelId === 20) {
              this.createAcct.channel = 20;
          }
          this.createAcct.rm = [{ userId: a.data.parentPositionCode, userName: a.data.parentFirstName + " " + a.data.parentLastName }];
          this.createAcct.designation = a.data.designationId;
          this.createAcct.dob = a.data.dob ? new Date(a.data.dob) : '';
          this.createAcct.dob = this.createAcct.dob ? this.datepipe.transform(this.createAcct.dob, 'yyyy-MM-dd') : '';
          this.LoginIDError = false;
          this.getAllDesignation();
          this.getManagers();
          ($('#usercreate') as any).modal('show');
          this.spinner.hide();
      });
      // this.sapAccountIdDisable();
  }

  viewUserEvent(id) {
      this.http.get(`/ctslead/getUserView/${id}`).subscribe(a => {
          this.viewUser = a.data;
          this.viewUser.dob = this.viewUser.dob ? new Date(this.viewUser.dob) : '';
          this.viewUser.dob = this.viewUser.dob ? this.datepipe.transform(this.viewUser.dob, 'yyyy-MM-dd') : '';
          // this.viewUser.channel = this.viewUser.distributionChannelId;
          if (this.viewUser.distributionChannelId === 1 || this.viewUser.distributionChannelId === 10) {
              this.viewUser.channel = 10;
          }
          if (this.viewUser.distributionChannelId === 2 || this.viewUser.distributionChannelId === 20) {
              this.viewUser.channel = 20;
          }
          jQuery('#viewuselist').modal('show');
      })

  }
  initForm() {
      this.createUser.reset();
      $('#createInfluencerModal').trigger('reset');
      this.createAcct.status = 1;
      this.updateuser = null;
      this.createAcct.rm = '';
      this.reportingMnager = [];
  }
  checkerriteryCode() {
      this.territyError = false;
      // console.log(this.createAcct.territeryCode);
      if (this.updateuser) {
          return true;
      }
      if (String(this.createAcct.territeryCode).length > 5) {
          this.spinner.show();
          this.http.get(`/ctslead/getTerrCode/${this.createAcct.territeryCode}`).subscribe((res) => {
              const exists = 'Territery Code Already Exist';
              if (res.message === exists) {
                  this.territyError = true;
              } else {
                  this.territyError = false;
              }
              this.spinner.hide();
          });
      }
  }

  // checkEmployeeId = () => {
  //     const checkListLength = this.userListing.filter( ele => this.createAcct.employeeId === ele.employeeId);
  //     if(checkListLength.length > 0){
  //         this.checkExistingEmployeeId = true;
  //     }
  //     else{
  //         this.checkExistingEmployeeId = false;
  //     }
  //     console.log("chkExisting", this.checkExistingEmployeeId);
  // }

  checkLoginId() {
      // console.log(this.createAcct.loginId);
      if (this.updateuser) {
          return true;
      }
      this.LoginIDError = false;
      if (String(this.createAcct.loginId).length > 3) {
          this.spinner.show();
          this.http.get(`/ctslead/getUniLoginId/${this.createAcct.loginId}`).subscribe((res) => {
              const exists = 'Login Id Already Exist';
              if (res.message === exists) {
                  this.LoginIDError = true;
              } else {
                  this.LoginIDError = false;
              }
              this.spinner.hide();
          });
      }
  }
  onFileSelect(event, type) {
      let url;
      if (event.target.files.length > 0) {
          if (type == 'approve_reject') {
              url = '/lead-admin/upload-jcp-aprrove-reject-excel';
          } else {
              url = '/lead-admin/delete-jcp-excel';
          }
          const file = event.target.files[0];
          const formData = new FormData();
          formData.append('file', file);
          this.spinner.show();
          this.http.post(`${url}`, formData).subscribe(
              (res) => {
                  let str;
                  str = '';
                //   if (res.message == 'Users Not Created Successfully' || res.message.includes("invalid data...")) {
                //       str += "<br/><a href='" + `${environment.BASE_ENDPOINT}/user/user-errorFileDownload` + "'><h4> Download Records </h4></a>"
                //   }
                  event.srcElement.value = null;
                  Swal.fire({
                      title: res.message,
                      html: str,
                      timer: 1500000,
                  });
                  this.page = 1;
                  this.getUserLst();
                  this.spinner.hide();
              },
              (err) => {
                  event.srcElement.value = null;
                  Swal.fire({
                      title: err.error ? err.error.message : 'Error saving account',
                      timer: 1500000,
                  });
                  this.spinner.hide();
              }
          );
      }
  }

  downloadBlankFile(type) {
      let url;
    //   http://localhost:8080/umber-rest/lead-admin/jcp-approve-reject-template-download%27
    // http://localhost:8080/umber-rest/lead-admin/jcp-delete-template-download%27
      if(type === 'approve_reject'){
          url = `${environment.BASE_ENDPOINT}/lead-admin/jcp-approve-reject-template-download`;
      }
      else{
          url = `${environment.BASE_ENDPOINT}/lead-admin/jcp-delete-template-download`;
      }
      window.location.href = url;
  }

  downloadFile() {
      let url1;
      let param = '?';
      if (this.filterData.state) {
          param += `state=${this.filterData.state}`;
      }
      if (this.filterData.division) {
          param += `&div=${this.filterData.division}`;
      }
      if (this.filterData.designationid) {
          param += `&desigId=${this.filterData.designationid}`;
      }
      if (this.filterData.reporing_manager.length > 0) {
          param += `&reportingTo=${this.filterData.reporing_manager[0]['userId']}`;
      }
      if (this.filterData.status) {
          param += `&status=${this.filterData.status}`;
      }
      url1 = `${environment.BASE_ENDPOINT}/user/user-downloadByFilter${param}`;
      window.location.href = url1;
  }
  resetFilters() {
      this.filterData = {
          state: '',
          reporing_manager: [],
          division: '',
          designationid: '',
          status: '',
          userName: '',
          empId: '',
          year:'',  
          month:'',
      };
      this.page = 1;
      this.getUserLst();
      this.reportingMnager = [];
    //   this.filterdesignationList = [];
  }
  find( events = null) {
    if((this.filterData.month != "" && this.filterData.year != "") || this.filterData.month == "" && this.filterData.year == ""){
        this.filterData.empId = ''
        this.filterData.userName = ''
        this.getUserLst();
    }
    else if(this.filterData.month == "" && this.filterData.year != ""){
        this.filterData.empId = ''
        this.filterData.userName = ''
        this.getUserLst();
    }
    else{
        alert("Year is required.")
    }
      return '';
      let state = this.filterData.state ? this.filterData.state : 543210;
      let division = this.filterData.division ? this.filterData.division : 543210;
      let designationid = this.filterData.designationid ? this.filterData.designationid : 543210;
      let reporing_manager = this.filterData.reporing_manager.length > 0 ? this.filterData.reporing_manager[0]['userId'] : 543210;
      let status = this.filterData.status ? this.filterData.status : 543210;
      if (division != 543210 && status != 543210 && reporing_manager == 543210 && designationid == 543210 && state == 543210) {
          alert('Please select state to fetch results.');
          return;
      }
      this.userListing = [];
      this.http.get(`/ctslead/getFilterUserList/${state}/${designationid}/${division}/${reporing_manager}/${status}`).subscribe((data) => {
          if (data.status == 200) {
              this.userListing = data.data;
              this.preparePager();
          }
      });
  }

  psdValidation(){
      if(!this.updateuser && this.createAcct.password && this.createAcct.confirmPassword){
          let x = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.createAcct.password)
          if(x){
              return false
          }
          else{
              return true
          }
      }
      else{
          if(this.updateuser && this.createAcct.password && this.createAcct.confirmPassword){
              let x = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.createAcct.password)
              if(x){
                  return false
              }
              else{
                  return true
              }
          }
          else{
              return false
          }
      }
  }

  emailValidation = (mailId) => {
      const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})/;
      const emailval = mailId ? mailId.match(validRegex) : true;
      if(!emailval){
        return true
      }
      else{
        return false
      }
    }

  validation() {
      if(this.userStatus == 0 && this.createAcct.status == 0){
          this.validationErrorMessage = "Sorry...! Can't Update Inactive User.";
          return true
      }
      if (!this.updateuser && !this.createAcct.employeeId) {
          this.validationErrorMessage = 'Please Enter Employee Id.';
          return true;
      }
      if (!this.updateuser && this.createAcct.employeeId.length < 5) {
          this.validationErrorMessage = "Employee Id contain's min. 5 characters";
          return true;
      }
      if (!this.createAcct.firstName) {
          this.validationErrorMessage = "Please Enter First Name.";
          return true;
      }
      if (this.createAcct.firstName.length < 2) {
          this.validationErrorMessage = "First Name contain's min. 2 characters";
          return true;
      }
      if (!this.createAcct.lastName) {
          this.validationErrorMessage = "Please Enter Last Name";
          return true;
      }
      if (this.createAcct.lastName.length < 2) {
          this.validationErrorMessage = "Last Name contain's min. 2 characters";
          return true;
      }
      if (!this.createAcct.loginId) {
          this.validationErrorMessage = "Please Enter Login ID";
          return true;
      }
      if (this.createAcct.loginId.length < 5) {
          this.validationErrorMessage = "LoginID contain's min. 5 characters";
          return true;
      }
      if (this.emailValidation(this.createAcct.personalEmail)) {
          this.validationErrorMessage = "Please Enter Valid Personal Email.";
          return true;
      }
      if (this.emailValidation(this.createAcct.officeEmail)) {
          this.validationErrorMessage = "Please Enter Valid Office Email.";
          return true;
      }
      if (this.LoginIDError) {
          return true;
      }
      if (!this.createAcct.personalMobile) {
          this.validationErrorMessage = "Please Enter Office Mobile No.";
          return true;
      }
      if (this.createAcct.personalMobile.length !==10 || !this.mobileValidation()) {
          this.validationErrorMessage = "Please Enter Valid Mobile No.";
          return true;
      }
      if (!this.updateuser && !this.createAcct.division) {
          this.validationErrorMessage = "Please Select Division.";
          return true;
      }
      if (!this.createAcct.territeryCode) {
          this.validationErrorMessage = "Please Enter Territory Code.";
          return true;
      }   
      if (this.createAcct.territeryCode.length < 6) {
          this.validationErrorMessage = "Territory Code contain's min. 6 characters";
          return true;
      }
      if (this.territyError) {
          return true;
      }
      if (!this.updateuser && !this.createAcct.designation) {
          this.validationErrorMessage = "Please Select Designation.";
          return true;
      }
      if (!this.createAcct.channel) {
          this.validationErrorMessage = "Please Select Distribution Channel.";
          return true;
      }
      if (!this.createAcct.departmentId) {
          this.validationErrorMessage = "Please Select Department Name.";
          return true;
      }
      if (!this.updateuser && !this.createAcct.state) {
          this.validationErrorMessage = "Please Select User State.";
          return true;
      }
      if (!this.updateuser && (this.createAcct.password == undefined || this.createAcct.password == null || this.createAcct.password == "")) {
          this.validationErrorMessage = 'Please Enter Password.';
          return true;
      }
      if (!this.updateuser && (this.createAcct.confirmPassword == undefined || this.createAcct.confirmPassword == null || this.createAcct.confirmPassword == "")) {
          this.validationErrorMessage = 'Please Enter Confirm Password.';
          return true;
      }
      if ((this.createAcct.password !== this.createAcct.confirmPassword) && this.createAcct.password && this.createAcct.confirmPassword) {
          this.validationErrorMessage = 'Password & Confirm Pasword must be same.';
          return true
      }
      if (this.psdValidation()) {
          this.validationErrorMessage = 'Password must have at least 1 alphabet, 1 number, 1 special character and min. 8 characters.';
          return true
      }
      if (!this.createAcct.password && this.createAcct.confirmPassword) {
          this.validationErrorMessage = 'Password & Confirm Pasword must be same.';
          return true
      }
      if (this.createAcct.password && !this.createAcct.confirmPassword) {
          this.validationErrorMessage = 'Password & Confirm Pasword must be same.';
          return true
      }
      if (this.updateuser && this.createAcct.password && this.createAcct.confirmPassword) {
          if(this.createAcct.password !== this.createAcct.confirmPassword){
              this.validationErrorMessage = 'Please Enter psd1.';
              return true;
          }
      }
      if (this.updateuser && !this.createAcct.password && this.createAcct.confirmPassword) {
          this.validationErrorMessage = 'Please Enter psd2.';
          return true;
      }
      
      if (this.updateuser && this.psdValidation()) {
          return true;
      }
      if (this.updateuser && this.createAcct.password && !this.createAcct.confirmPassword) {
          return true;
      }
      if (!this.updateuser && (this.createAcct.password !== this.createAcct.confirmPassword)) {
          return true;
      }
      if (!this.updateuser && this.psdValidation()) {
          return true;
      }
      if (!this.createAcct.rm && this.createAcct.rm.length == 0) {
          this.validationErrorMessage = 'Please Select Reporting Manager.';
          return true;
      }
      if (this.createAcct.division=="2" && (this.createAcct.designation == 12 || this.createAcct.designation == 13 || this.createAcct.designation == 17) && (this.createAcct.areaOffice=="" || this.createAcct.areaOffice==null || this.createAcct.areaOffice==undefined)) {
          this.validationErrorMessage = 'Please Enter Area Office.';
          return true;
      }
      if (!this.createAcct.status) {
          return true;
      }
      return false;
  }

  getDisable() {
      if (this.updateuser) {
          return true;
      }
      return false;
  }
  reset() {
      this.updateuser = false;
      this.createAcct = {
      };
      $('#createInfluencerModal').trigger('reset');
      this.userreportingMnager = [];
      this.designationList = [];
      this.territyError = false;
      this.LoginIDError = false;
  }

    onKeySearch(event: any){
        this.page = 1;
        if (event.keyCode == 13) {
            this.getUserLst();
            this.timeout = null;
            return ;
        }
        clearTimeout(this.timeout);
        var $this = this;
        this.timeout = setTimeout(function () {
            if (event.keyCode != 13) {
                $this.getUserLst();
            }
        }, 1000);
    }

    downloadExcelFormReset() {
        this.errMsg = ''
        this.downloadExcelFilter = {
            startDate: this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'),
            endDate: this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),
        }
    }

    downloadExcelData() {
        let fd = this.downloadExcelFilter.startDate;
        let td = this.downloadExcelFilter.endDate;
        let FD = new Date(fd);
        let TD = new Date(td);
        let no_of_days;
        try {
            if (fd !== "" && td !== "") {
                if (FD.getTime() <= TD.getTime()) {
                    no_of_days = (TD.getTime() - FD.getTime()) / (1000 * 3600 * 24);
                    if (no_of_days <= 30) {
                        this.spinner.show();
                        // let url = this.filterObj(fd, td);
                        // http://localhost:8080/umber-rest/lead-admin/jcp-filter-download?fromDate=2019-02-28&toDate=2019-06-05%27
                        window.location.href = environment.BASE_ENDPOINT+"/lead-admin/jcp-filter-download?fromDate="+fd+"&toDate="+td;

                        // window.location.href = url;
                        this.spinner.hide();
                        document.getElementById("downloadExcelClose").click();
                    }
                    else {
                        this.errMsg = "30 days limit exceed !"
                    }
                }
                else {
                    this.errMsg = "No records found in this time period!"
                }
            }
            else {
                this.errMsg = "All Fields are required!";
            }
        } catch (error) {
            console.error("Error", error);
        }

        // this.downloadExcelFormReset();

    }

    approve_rejectJCP(userId, jcpId, statusCode, comment){
        this.spinner.show();
        this.http.post(`/lead-admin/approve-reject-jcp?userId=${userId}&jcpId=${jcpId}&statusCode=${statusCode}&comment=${comment}`,statusCode).subscribe(data => {
            if(data.status == 200){
                this.getUserLst();
                this.spinner.hide();
                if(statusCode == "2"){
                    Swal.fire(
                        'Approved!',
                        'JCP has been Approved.',
                        'success'
                    )
                }
                else{
                    Swal.fire(
                        'Rejected!',
                        'JCP has been rejected.',
                        'success'
                    )
                }
            }
        })
    }

    deleteJCP(jcpId){
        this.spinner.show();
        this.http.post(`/lead-admin/delete-jcp?jcpId=${jcpId}`).subscribe(data => {
            if(data.status == 200){
                this.getUserLst();
                this.spinner.hide();
            }
        })
    }

    delApproveRejectJCP(jcpData, actionType){
        
        switch (actionType) {
            case 'approve':
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                  }).then((result) => {
                    if (result.value === true) {           
                        this.approve_rejectJCP(jcpData.userId, jcpData.id, "2", "");
                    }
                  })
                break;
                
            case 'reject':
                if(this.commentForRejection.msg){
                    this.approve_rejectJCP(jcpData.userId, jcpData.id, "3", this.commentForRejection.msg);
                    this.commentForRejection.error = "";
                    this.commentForRejection.userId = "";
                    this.commentForRejection.msg = "";
                    jQuery('#comment_to_reject').modal('hide');
                    
                }
                else{
                    this.commentForRejection.error = "Comment is required !";
                }
                break;

            case 'delete':
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                  }).then((result) => {
                    if (result.value === true) {
                        this.deleteJCP(jcpData.id);                        
                        Swal.fire(
                            'Deleted!',
                            'JCP has been deleted.',
                            'success'
                        )
                    }
                  })
                break;
        
            default:
                break;
        }
    }

    commentBasedOnJcpId(jcpData){       
        this.commentForRejection.id = jcpData.id;
        this.commentForRejection.userId = jcpData.userId;
    }

    handleCommentBox(event){
        this.commentForRejection.msg = event.target.value;
    }

    viewCounterWiseJCP(jcpInfo, date, visitType){
        const days =  new Date(jcpInfo.year, jcpInfo.month+1, 0).getDate();
        if(visitType === "month"){
            this.route.navigate(['/umber/month-wise-visits',{jcpId:jcpInfo.id, days, month:jcpInfo.month+1, year:jcpInfo.year}]);
        }
        else{
            (jQuery('#counter_wise_visits') as any).modal('hide');
            this.route.navigate(['/umber/date-wise-visits',{jcpId:jcpInfo, date}]);
            // console.log(jcpInfo);
            
        }
    }

    async openCalendar(mon){
        await this.getAllVisits(mon);
    }

    getDayInMonth(mon){
        this.monthWiseVisit = [];
        const days =  new Date(mon.year, mon.month+1, 0).getDate();
        const monthName = mon.name.split('_')[1];
        for(let i = 1; i <= days; i++){
            let dataLen;
            const taareekh = `${mon.year}-${mon.month < 10 ? `0${mon.month+1}` : mon.month+1}-${i < 10 ? `0${i}` : i}`;
            let obj = {
                visits:false,
                date:taareekh,
                day:i < 10 ? `0${i}` : i,
                month:monthName,
                planVisit:'Planned Visits',
                noOfVisits:mon.plannedVisitCount,
                jcpId:mon.id,
            }            
            dataLen = this.visitListing.filter(visit => visit['startDate'] == taareekh);
            if(dataLen.length > 0){
                this.monthWiseVisit.push({...obj, visits:true});
            }
            else{
                this.monthWiseVisit.push({...obj, visits:false});
            }
        }
        console.log("daywise", this.monthWiseVisit);
        
    }


    addInfluencerInTheList(event,user){  
        if((document.getElementById("checkboxInf_"+user.id) as HTMLInputElement).checked){
            (document.getElementById("checkboxInf_"+user.id) as HTMLInputElement).checked = true;
        }
        else{
            (document.getElementById("checkboxInf_"+user.id) as HTMLInputElement).checked = false;
        }        
        // (document.getElementById("checkboxInf_"+user.id) as HTMLInputElement).checked = true;
        const obj = {
            id: user.id,
            status:user.jcpStatus.statusName
        }
        if(this.transferList.jcpId.length == 0){
            this.transferList.jcpId.push(obj);
        }
        else if(this.transferList.jcpId.length > 0){
            let exist = this.transferList.jcpId.filter(ele => ele.id === user.id);
            if(!exist[0]){
                this.transferList.jcpId.push(obj);
            }
            else{
                let indx = this.transferList.jcpId.indexOf(exist[0]);
                this.transferList.jcpId.splice(indx,1) ;
            } 
            if(this.transferList.jcpId.length !== this.userListing.length){
                this.selectAll = false;
            }
            if(this.transferList.jcpId.length === this.userListing.length){
                this.selectAll = true;
            }
        }
        console.log("######", this.transferList);
        
    }

    selectAllData(){
        if(this.selectAll){
            this.userListing.map(ele =>{
                (document.getElementById("checkboxInf_"+ele.id) as HTMLInputElement).checked = true;
                this.transferList.jcpId.push({id: ele.id, status:ele.jcpStatus.statusName});
                
            })
        }
        else{
            this.userListing.map(ele =>{
                (document.getElementById("checkboxInf_"+ele.id) as HTMLInputElement).checked = false;
                this.transferList.jcpId=[]
            })
        }
    }

    actionPerform(event){
        const user_id = sessionStorage.getItem('userid');
        const code = event.target.value;
        this.transferList.userId = user_id;
        this.transferList.statusCode = (code != 0) && code ;
        this.transferList.delete = (code == 0) && true ;

        let url; let title; let msg;
        // this.transferList.jcpId = this.transferList.jcpId.filter(x => x.status !== 'In progress');
        
        switch (code) {
            case "0":
                const inProgress = this.transferList.jcpId.filter(x => x.status === 'In progress');
                
                let arr = this.fetchJCP_id(this.transferList.jcpId);
                if(this.transferList.jcpId.length > 0 && inProgress.length <= 0){
                    url = `delete=${this.transferList.delete}&jcpId=${arr}`;
                    Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No'
                    }).then((result) => {
                        if (result.value === true) {
                            this.spinner.show();
                            this.http.post(`/lead-admin/approve-reject-delete-multiple?${url}`).subscribe(data => {
                                if(data.status == 200){
                                    this.getUserLst();
                                    this.spinner.hide();
                                    Swal.fire(
                                        "Deleted",
                                        "JCP Deleted Successfully" ,
                                        'success'
                                        )
                                        this.transferList.jcpId = [];
                                        this.selectAll = false;
                                    }
                                })                       
                            }
                        event.target.value = "";
                    })
                }else{
                    if(inProgress.length > 0){
                        Swal.fire(
                            "Invalid Multiple Selection" ,
                            "Sorry...!",
                            'error'
                        )
                    }else{
                        alert("Please select JCP's");
                    }
                    event.target.value = "";
                }
                break;
                
            case "2":
                const inApprove = this.transferList.jcpId.filter(x => x.status === 'Submitted');
                
                let arr1 = this.fetchJCP_id(inApprove);
                // console.log(arr1);
                if(this.transferList.jcpId.length > 0 && inApprove.length > 0){
                url = `userId=${this.transferList.userId }&statusCode=${this.transferList.statusCode }&jcpId=${arr1}`;
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No'
                  }).then((result) => {
                    if (result.value === true) {
                        this.spinner.show();
                        this.http.post(`/lead-admin/approve-reject-delete-multiple?${url}`).subscribe(data => {
                            if(data.status == 200){
                                this.getUserLst();
                                this.spinner.hide();
                                Swal.fire(
                                    "Approved",
                                    "JCP Approved Successfully" ,
                                    'success'
                                    )
                                    this.transferList.jcpId = [];
                                    this.selectAll = false;
                                }
                            })                       
                        }
                        event.target.value = "";
                    })
                    }else{
                        if(inApprove.length <= 0){
                            Swal.fire(
                                "Invalid Multiple Selection" ,
                                "Sorry...!",
                                'error'
                            )
                        }else{
                            alert("Please select JCP's");
                        }
                        event.target.value = "";
                    }
                    break;
                    
                    case "3":
                        this.commentForRejection.msg = "";
                        const inReject = this.transferList.jcpId.filter(x => x.status === 'Submitted');

                        this.fetchJCP_id(inReject);
                        if(this.transferList.jcpId.length > 0 && inReject.length > 0){
                            (jQuery('#multi_comment_to_reject') as any).modal('show');
                            event.target.value = "";
                        }
                        else{
                            if(inReject.length <= 0){
                                Swal.fire(
                                    "Invalid Multiple Selection" ,
                                    "Sorry...!",
                                    'error'
                                )
                            }else{
                                alert("Please select JCP's");
                            }
                            event.target.value = "";

                    }
                break;
             
        
            default:
                break;
        }
        
    }

    rejectMultipleJCP(){
        this.transferList.comment = this.commentForRejection.msg;

        (jQuery('#multi_comment_to_reject') as any).modal('hide');

        const inReject = this.transferList.jcpId.filter(x => x.status === 'Submitted');
        const arr3 = this.fetchJCP_id(inReject);

        let url = `jcpId=${arr3}&statusCode=${this.transferList.statusCode}&comment=${this.transferList.comment}`;
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
            }).then((result) => {
            if (result.value === true) {
                this.spinner.show();
                this.http.post(`/lead-admin/approve-reject-delete-multiple?${url}`).subscribe(data => {
                    if(data.status == 200){
                        this.getUserLst();
                        // console.log("data",data);
                        this.spinner.hide();
                        Swal.fire(
                            "Rejected",
                            "JCP Rejected Successfully" ,
                            'success'
                        )
                        this.commentForRejection.msg = "";
                        this.transferList.jcpId = [];
                        this.selectAll = false;
                    }
                })                       
            }
            // event.target.value = ""
        })
    }

    getAllVisits(jcp) {
        this.visitListing = [];
        this.spinner.show();
        let filterParams = '?pageNo='+(this.page-1)+'&pageSize='+this.itemsPerPage+'&jcpId='+ jcp.id;
        this.http.get(`/lead-admin/get-visits${filterParams}`).pipe(first()).subscribe(data => {
            if (data.status == '200') {     
                (jQuery('#counter_wise_visits') as any).modal('show');       
                this.visitListing = data.data;
                this.getDayInMonth(jcp)
                //this.preparePager();
                this.totalItems = data.message;
                this.spinner.hide();
            }
        })
    }

    fetchJCP_id(list){
        let ids=[];
        for(var i = 0; i < list.length; i++){
            ids.push(list[i].id);
        }
        return ids
        // console.log("dfsdgghjgrgfngfgdnbcfdgdnbd",ids);
    }

}
