import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../http-client-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ctswhite-individual-report',
  templateUrl: './ctswhite-individual-report.component.html',
  styleUrls: ['./ctswhite-individual-report.component.css']
})
export class CtswhiteIndividualReportComponent implements OnInit {

 
  constructor(private http: HttpClientService) { }

  ngOnInit() {
  }

  downloadReports(buttonClicked){
    let parentpositioncode = sessionStorage.getItem("parentpositioncode");
    if(buttonClicked == 'Event'){
       this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-event-by-user/${parentpositioncode}`);
      //  this.getReports('https://umber.jkcement.com/umber-rest/white-reports/download-report-event-by-user/'+parentpositioncode);
    }
    else if(buttonClicked == 'Influencer'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-influncer-by-user/${parentpositioncode}`);
      //  this.getReports('https://umber.jkcement.com/umber-rest/white-reports/download-report-influncer-by-user/'+parentpositioncode);
    }
    else if(buttonClicked == 'Lead'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-lead-by-user/${parentpositioncode}`);
      //  this.getReports('https://umber.jkcement.com/umber-rest/white-reports/download-report-lead-by-user/'+parentpositioncode);
    }
    else if(buttonClicked == 'Ticket'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-ticket-by-user/${parentpositioncode}`);
      //  this.getReports('https://umber.jkcement.com/umber-rest/white-reports/download-report-ticket-by-user/'+parentpositioncode)
    }
    else if(buttonClicked == 'Visit'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-visit-by-user/${parentpositioncode}`);
      //  this.getReports('https://umber.jkcement.com/umber-rest/white-reports/download-report-visit-by-user/'+parentpositioncode)
    }
  } 

  getReports(URL){
    console.log(URL);
    window.location.href = URL;
    //window.open(URL);
  }

}
