import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VanComponent } from './vancomponent/van/van.component';
import { VanTripDetailsComponent } from '../van-profile/van-profile.component';

const routes: Routes = [
  {path: 'van/profile', component: VanTripDetailsComponent},
  {path: 'van/trip-details', component: VanComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VanRoutingModule { }
