import { Component, OnInit } from '@angular/core';
import { HttpClientService } from '../http-client-service.service';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import Swal from 'sweetalert2';
import { JsonPipe, DatePipe } from '@angular/common';
declare var jQuery: any;

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.css']
})
export class CtsLeadComponent implements OnInit {
  oneyeardate: any;
  mindate: any;
  maxdate: any;
  term: any;
  model: any = {};
  public history = [];
  usersiteid: any = [];
  allleaddata: any = [];
  assignToDeptUser: any = [];
  selfsourceLead: any = [];
  viewlead: any = [];
  viewcontactperson: any = [];
  selfcheck: any = false;
  Self: any;
  Other: any;
  siteData: any;
  brand: any = [];
  compMaterialgrp: any = [];
  brandMatGrp: any = [];
  leadStatus: any = [];
  show: any = false;
  no: boolean = true;


  dropdownList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings;

  contactpersonlist: any = [];
  showassign: boolean = true;

  updatelead = {
    followuprequired: '',
    leadid: '',
    description: '',
    visitype: '',
    revisitdate: '',
    isconverted: ''
  };



  potentiallength: any;


  constructor(private http: HttpClientService, public datepipe: DatePipe) {
    this.getLeadData();
    this.getBrand();
    this.getLeadStatus();
    this.getcompMaterialgrp();
    this.getbrandMatGrp();
    this.getAllUserHierarchy();
    this.oneyeardatefun();
    this.previousDateDisbaled();
    //this.getAllProduct();

  }

  followuprequired: '';
  followuprequireddate = false;

  // ************************* this is for add site modal
  pincodedata: any = [];
  site = {
    name: '',
    createdById: '',
    constuctionStage: '',
    geographyMasterId: '',
    geogId: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    pinCode: '',
    cityName: '',
    districtName: '',
    stateName: '',
    ihbName: '',
    ihbContatct: '',
    ihbCategory: '',
    ihbName2: '',
    ihbContact2: '',
    ihbCategory2: '',
    ihbName3: '',
    ihbContact3: '',
    ihbCategory3: '',
    ihbName4: '',
    ihbContact4: '',
    ihbCategory4: '',
    stateId: '',
    contactpersondetails: [
      { ihbName: '', isPrimary: '', ihbContatct: '', ihbCategory: 'Architect' },
      { ihbName: '', isPrimary: '', ihbContatct: '', ihbCategory: 'IHB' },
      { ihbName: '', isPrimary: '', ihbContatct: '', ihbCategory: 'Contractor' }
    ],
    contactpersoncategory: [
      { id: 1, name: 'Architect' },
      { id: 2, name: 'IHB' },
      { id: 3, name: 'Contractor' },
      { id: 4, name: 'Engineer' },
      { id: 5, name: 'Dealer' },
      { id: 6, name: 'Retailer' },
    ]
  };


  // View modal data
  viewticket = {
    ihb_name: '',
    sourcetype: '',
    accountcontact: '',
    categoryname: '',
    accountname: '',
    loginId: '',
    accountcat: '',
    siteid: '',
    leadtype: '',
    departmentid: '',
    assigntoid: '',
    createDate: '',
    tkt: '',
    id: '',
    status: '',
    sitename: '',
    createdby: '',
    assignto: '',
    leadnumber: '',
    lmslead: [],
    city: '',
    district: '',
    state: '',
    constuctionStage: '',
    description: ''
  };

  lead = {
    status: '',
    division_id: '',
    sourcetype: '',
    accountcontact: '',
    accountname: '',
    loginId: '',
    accountcat: '',
    siteid: '',
    leadtype: '',
    departmentid: '',
    assigntoid: '',
    comment: '',
    slab_date: '',
    foundation_date: '',
    grahpravesh_date: '',
    callername: '',
    callercategry: '',
    addressLine1: '',
    districtId: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    pincode: '',
    stateId: '',
    trade: '',
    cityId: '',
    productid: '',
    leadid: '',
    leadflag: '1',
    isconverted: '',
    reasonnonconversion: '',
    leadpotential: [
      { product: '', quantity: '', price: '', brand: '', purchaseDate: '', leadpotionaltype: 'Current Brand (MT)' }
    ],
    comptitorPotential: [
      { product: '', quantity: '', leadpotionaltype: 'Sitepotential' }
    ],
    closeleadpotential: [
      { product: '', quantity: '', price: '' }
    ]

  };




  category = [
    { id: 1, name: 'Service' },
    { id: 2, name: 'Complaint' }
  ];


  allchilduser: any = [];
  getAllUserHierarchy() {
    this.http.get('/user/get-user-hierarchy/' + sessionStorage.getItem("positionCode")).subscribe(data => {
      if (data.status == '200') {
        this.allchilduser = data.data;
        console.log(this.allchilduser);
      }
    });

  }




  // getAllProduct() {
  //   this.http.get("/ctslead/all-product/"+sessionStorage.getItem("divison")).subscribe(data => {

  //     this.product = data.data ;


  //   })
  // }
  //productarr: any=[{"id":10,"name":"PPC","type":"0","division":2,"department":0,"status":1},{"id":11,"name":"OPC","type":"0","division":2,"department":0,"status":1},{"id":12,"name":"PSC","type":"0","division":2,"department":0,"status":1},{"id":13,"name":"Premium","type":"0","division":2,"department":0,"status":1}];

  product = [{ "id": 10, "name": "PPC" }, { "id": 11, "name": "OPC" }, { "id": 12, "name": "PSC" }, { "id": 13, "name": "PREMIUM" }];
  // quantity=[{value:'100'},{value:'200'},{value:'300'},{value:'400'},{value:'500'}];
  // price=[{pvalue:'2000'},{pvalue:'2400'},{pvalue:'3000'},{pvalue:'6000'},{pvalue:'80000'}];

  sub
    = [
      { id: 1, name: 'Slab Supervision' },
      { id: 2, name: 'Other Concrete supervision' },
      { id: 3, name: 'Premium product service' },
      { id: 4, name: 'Pre Slab Cast Guidance' },
      { id: 4, name: 'Post slab cast guidance' }
    ];
  leadTypes = [
    { id: 1, name: 'Warm' },
    { id: 2, name: 'Cold' },
    { id: 3, name: 'Hot' },
  ];

  assignCat = [
    { id: 1, name: 'Trade' },
    { id: 2, name: 'Non Trade' },
    { id: 3, name: 'CTS' },
  ];

  //********************** This for Influencer
  influncer = {
    categoryId: '',
    name: '',
    tier: '',
    preferredBrand: '',
    monthlyPotentialMt: '',
    tradeSitesNumber: '',
    contact: '',
    email: '',
    status: '',
    district: '',
    pincode: '',
    state: '',
    statecode: '',
    city: '',
    divisionId: 3,
    departmentId: 3,
  }

  jkliftingproduct = [
    { id: '1', name: 'OPC' },
    { id: '2', name: 'PPC' },
    { id: '3', name: 'PSC' },
    { id: '4', name: 'Premium' },
  ];

  potentialtypearr = [
    { id: '1', name: 'Current Brand (MT)' },
    { id: '2', name: 'Sitepotential' },

  ];

  readonly = false;
  private selectedLead = '';

  private active: any = false;
  private visitactive: any = false;
  visitno: boolean = false;
  private weatheractive: any = false;
  weatherno: boolean = false;
  weathershow = false;
  sitepotentialjklefting = false;
  reasonnonconversion = false;

  readonlycondition() {

    if (this.influncer.categoryId == '33') {
      this.influncer.tradeSitesNumber = '1';
      this.readonly = true;
    } else {
      this.readonly = false;
    }
  }

  addcontactperson(i) {
    this.site.contactpersondetails.push({ ihbName: '', isPrimary: '', ihbContatct: '', ihbCategory: '' });
    //console.log(this.site.contactpersondetails);
  }

  removecontactperson(index) {
    if (index > 2) {
      this.site.contactpersondetails.splice(index, 1);
    }
  }

  addCompitator(i, key) {
    this.lead.comptitorPotential.push({ product: '', quantity: '', leadpotionaltype: 'SitePotional' });
    // console.log(this.lead.leadpotential);
  }

  removeCompitator(index) {
    //console.log(index);
    if (index > 0) {
      this.lead.comptitorPotential.splice(index, 1);
    }
  }

  addleadpotential(i, key) {

    this.lead.leadpotential.push({ product: '', brand: '', quantity: '', price: '', purchaseDate: '', leadpotionaltype: 'Current Brand (MT)' });
    console.log(this.lead.leadpotential);
  }


  editleadpotential(i, key) {

    this.viewticket.lmslead.push({ brand: '', product: '', potential_type: '', price: '' })
    // alert(i)
    // this.lead.leadpotential.push({ product: '', brand: '', quantity: '', price: '', purchasedate: '', leadpotionaltype: 'Current Brand (MT)' });
    // console.log(this.lead.leadpotential);
  }

  editremoveleadpotential(index) {
    //console.log(index);
    if (index > 0) {
      this.viewticket.lmslead.splice(index, 1);
    }
  }

  removeleadpotential(index) {
    //console.log(index);
    if (index > 0) {
      this.lead.leadpotential.splice(index, 1);
    }
  }



  closeleadpotential(i, key) {
    this.lead.closeleadpotential.push({ product: '', quantity: '', price: '' });
    //console.log(this.lead.closeleadpotential);
  }

  removecloseleadpotential(index) {
    // console.log(index);
    if (index > 0) {
      this.lead.closeleadpotential.splice(index, 1);
    }
  }



  checkRedioLead(e: string): void {

    this.lead.sourcetype = e;
    this.selectedLead = e;
    if (e == 'Self') {
      this.getSelfSiteData();
    }
    else
      this.lead.sourcetype = 'Other'
    // else if (e == 'other')
  }
  isSelected(name: string): boolean {
    if (!this.selectedLead) {
      return false;
    }
    return (this.selectedLead === name);
  }



  // For Update Modal
  checkRedioLead1(e: string): void {
    if (e === 'yes') {
      this.followuprequireddate = true;
    } else if (e === 'no') {
      this.followuprequireddate = false;
    }
  }

  oneyeardatefun() {
    let todaysDate = new Date();
    todaysDate.setMonth(todaysDate.getMonth() - 12);
    let year = todaysDate.getFullYear();
    let month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);
    let day = ("0" + todaysDate.getDate()).slice(-2);
    this.oneyeardate = (year + "-" + month + "-" + day);
  }

  closeLeadModal(closelead: NgForm) {
    closelead.resetForm();
    this.lead.closeleadpotential = [];
  }

  closeleadpost(closelead: NgForm) {
    console.log(closelead);

    this.http.post('/ctslead/closelead', this.lead).subscribe(data => {
      if (data.status == '200') {

        Swal.fire({
          title: 'Lead closed successfully',
          timer: 3000
        });
        this.getLeadData();
        closelead.resetForm();
      }
      else if (data.status != '200') {
        Swal.fire({
          title: 'Something went wrongff ',
          timer: 3000
        });
      }


    });
  }



  // For Visit Condition
  checkvisit(e: string): void {
    this.visitactive = e;
    if (e == 'yes') {
      this.visitactive = true;
      this.visitno = false;
      this.weathershow = true;
    }
    else if (e == 'no') {
      this.visitno = true;
      this.visitactive = false;
      this.weathershow = false;
      this.reasonnonconversion = false;
      jQuery("#closelead").modal("hide");
    }
  }
  isSelectedvist(name: string): boolean {

    if (!this.visitactive) {
      return false;
    }
    return (this.visitactive === name);
  }


  resestAssignLeadForm(assignLeadForm: NgForm) {
    assignLeadForm.resetForm();
  }


  // For Weather Condition
  checkweather(e: string): void {
    if (e === 'yes') {
      this.lead.isconverted = '1';
      this.lead.closeleadpotential = [];
      this.lead.closeleadpotential.push({ product: '', quantity: '', price: '' });
    } else if ('no') {
      this.lead.isconverted = '0';
      this.lead.closeleadpotential = [];
    }

    this.weatheractive = e;
    if (e == 'yes') {
      this.weatheractive = true;
      this.weatherno = false;
      this.sitepotentialjklefting = true;
      this.reasonnonconversion = false;
    }
    else if (e == 'no') {
      this.weatherno = true;
      this.weatheractive = false;
      this.sitepotentialjklefting = false;
      this.reasonnonconversion = true;
    }
  }
  isSelectedweather(name: string): boolean {
    if (!this.weatheractive) {
      return false;
    }
    return (this.weatheractive === name);
  }




  createLead(form) {
    if (this.lead.siteid != null && this.lead.siteid != '') {
      this.lead.loginId = sessionStorage.getItem('employeeId');
      if (this.degree.contact == '' || this.degree.contact == null)
        this.lead.sourcetype = 'Self'
      if (this.lead.assigntoid == '' || this.lead.assigntoid == null) {
        this.lead.assigntoid = sessionStorage.getItem("employeeId")

      }
      this.show = false;
      this.lead.division_id = sessionStorage.getItem('divison');
      console.log(this.lead)

      this.http.post('/ctslead/createLead', this.lead).subscribe(data => {
        if (data.status == '200') {
          jQuery("#myModal").modal("hide");
          Swal.fire({
            title: 'Lead created successfully',
            timer: 3000
          });
          form.resetForm();
          this.getLeadData();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            // footer: '<a href>Why do I have this issue?</a>'
          });
        }
      });
      // form.reset();
    }

  }

  getLeadData() {
    return this.http.get('/ctslead/getleaddata/' + sessionStorage.getItem('employeeId')).subscribe(data => {
      if (data.status == 200) {
        this.allleaddata = data.data;
      }
    });
  }

  getBrand() {
    return this.http.get('/ctslead/getBrand').subscribe(data => {
      if (data.status == 200) {
        this.brand = data.data;
      }
    });
  }

  getcompMaterialgrp() {
    return this.http.get('/ctslead/getBrand').subscribe(data => {
      if (data.status == 200) {

        this.compMaterialgrp = data.data;
      }
    });
  }

  getbrandMatGrp() {
    return this.http.get('/ctslead/getbrandMatGrp').subscribe(data => {
      if (data.status == 200) {
        this.brandMatGrp = data.data;
      }
    });
  }

  //   previousDateDisbaledNew() {
  //     var todaysDate = new Date(); 
  //     var year = todaysDate.getFullYear(); 						
  //     var month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);	
  //     var day = ("0" + todaysDate.getDate()).slice(-2);			
  //    	var maxDate = (year +"-"+ month +"-"+ day);
  //    jQuery('input[type="date"].disabledpastdate').attr('min', maxDate);
  // };

  previousDateDisbaled() {
    let todaysDate = new Date();
    let year = todaysDate.getFullYear();
    let month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);
    let day = ("0" + todaysDate.getDate()).slice(-2);
    this.maxdate = (year + "-" + month + "-" + day);
    this.mindate = (year + "-" + month + "-" + day);
  };

  showaddress(ctsSiteId) {
    console.log(ctsSiteId);
    this.lead.siteid = ctsSiteId;
    this.show = true;

    this.http.get('/site/get-address-sites-web/' + ctsSiteId).subscribe(data => {
      if (data.status == '200') {
        this.contactpersonlist = data.data;
        this.degree.addressLine1 = data.data[0].addressLine1;
        this.degree.addressLine2 = data.data[0].addressLine2;
        this.degree.addressLine3 = data.data[0].addressLine3;
        this.degree.addressLine4 = data.data[0].addressLine4;
        //  this.degree.ctsGeogId = data.data.geographyMaster.id;
        this.degree.pincode = data.data[0].pinCode
        this.degree.stateId = data.data[0].state
        this.degree.districtId = data.data[0].district
        this.degree.cityId = data.data[0].city
        this.degree.ihbName = data.data[0].name
        this.degree.ihbContatct = data.data[0].contact
        this.degree.name = data.data[0].catName
        this.site.constuctionStage = data.data[0].constructionstage;
      }
    })

  }


  getLeadStatus() {
    return this.http.get('/ctslead/getLeadStatus').subscribe(data => {
      if (data.status == 200) {
        this.leadStatus = data.data;
      }
    });
  }


  assignTochange(name) {
    this.http.get('/ctslead/getUser/' + name + '/' + this.lead.siteid + '/' + sessionStorage.getItem('divison')).subscribe(data => {
      if (data.status == '200') {
        if (data.data.length > 0)
          this.assignToDeptUser = data.data;
        else {
          alert("No user Found")
        }
      }
    });
  }



  getSelfSiteData() {
    this.http.get('/ctslead/getsiteself/' + sessionStorage.getItem('userid')).subscribe(data => {
      if (data.status == '200') {
        this.selfsourceLead = data.data;



      }
    });
  }



  // This is for add site modal
  getDataByPinCode(pincode) {
    if (pincode != null && pincode.length == 6) {
      this.http.get('/geography-master/get-by-pin-code/' + pincode).subscribe(data => {
        if (data.status == '200') {
          if (data.data.length == 0) {
            Swal.fire({
              title: 'Pincode Is Incorrect',
              timer: 3000
            });
          }
          this.pincodedata = data.data;
          this.site.stateName = data.data[0].stateName;
          this.site.cityName = data.data[0].cityName;
          this.site.districtName = data.data[0].districtName;
          this.influncer.pincode = data.data[0].pinCode;
          this.influncer.state = data.data[0].stateName;
          this.influncer.city = data.data[0].cityName;
          this.influncer.district = data.data[0].districtName;
          this.influncer.statecode = data.data[0].stateCode;
        }
      });
    }
  }


  getViewLeadData(leadid) {
    //   console.log(leadid);
    this.getcompMaterialgrp();
    this.active = true;
    this.lead.leadid = leadid;
    this.updatelead.leadid = leadid;
    this.no = true;
    this.http.get('/ctslead/getLeadView/' + leadid).subscribe(data => {
      if (data.status == '200') {
        this.viewcontactperson = data.data.lmscontact;
        for (let i = 0; i < this.viewcontactperson.length; i++) {
          if (this.viewcontactperson[i].category == null) {
            this.viewcontactperson[i].category = { id: '', name: "", moduleId: '', status: '', parentId: '' };
          }

          // if(this.viewcontactperson[i].ihbCategory==null){
          //   if(this.viewcontactperson[i].category!=null){
          //     this.viewcontactperson[i].ihbCategory=this.viewcontactperson[i].category.name;
          //   }
          // }

        }

        this.viewticket.description = data.data.description;
        console.log('view ', this.viewcontactperson);
        this.viewlead = data.data;
        this.viewticket.sourcetype = data.data.sourcetype,
          this.viewticket.ihb_name = data.data.ihb_name
        this.viewticket.accountcontact = data.data.accountcontact,
          this.viewticket.categoryname = data.data.ihb_category,
          this.viewticket.accountname = data.data.accountname,
          this.viewticket.loginId = data.data.loginId,
          this.viewticket.accountcat = data.data.accountcat,
          this.viewticket.siteid = data.data.siteid,
          this.viewticket.leadtype = data.data.leadtype,
          this.viewticket.departmentid = data.data.departmentid,
          this.viewticket.assigntoid = data.data.assigntoid,
          this.viewticket.createDate = data.data.createDate,
          this.viewticket.sitename = data.data.sitename,
          this.viewticket.createdby = data.data.createdby,
          this.viewticket.assignto = data.data.assignto,
          this.lead.status = data.data.status;
        this.viewticket.leadnumber = data.data.leadnumber,

          this.viewticket.lmslead = data.data.lmslead,
          this.potentiallength = this.viewticket.lmslead.length - 1;

        this.viewticket.city = data.data.city,
          this.viewticket.status = data.data.status,
          this.viewticket.district = data.data.district,
          this.viewticket.state = data.data.state,
          this.viewticket.constuctionStage = data.data.constuctionStage;
        if (this.viewticket.sourcetype != "Self")
          this.selfcheck = true;
        this.lead.siteid = this.viewticket.siteid;

        if (data.data.foundation_date != null)
          this.lead.foundation_date = new Date(data.data.foundation_date).toISOString().split('T')[0] //+ ' ' + new Date(data.data.foundation_date).toLocaleTimeString()

        if (data.data.slab_date != null) {

          this.lead.slab_date = new Date(data.data.slab_date).toISOString().split('T')[0];
        }
        if (data.data.grahpravesh_date != null) {

          this.lead.grahpravesh_date = new Date(data.data.grahpravesh_date).toISOString().split('T')[0] //+ ' ' + new Date(data.data.grahpravesh_date).toLocaleTimeString()

        }
      }
      this.viewticket.id = leadid;


      if (sessionStorage.getItem('loginId') != 'admin') {
        var userarray = this.allchilduser;

        if (userarray.find(x => x.employeeId == data.data.assigntoid) || sessionStorage.getItem('loginId') == data.data.assigntoid) {
          console.log("userherirchy found")
          this.showassign = true;
        }
        else
          this.showassign = false;
      }
      else if (sessionStorage.getItem('loginId') == 'admin')
        this.showassign = true;

      this.lead.leadpotential = [];
      this.lead.comptitorPotential = [];


      this.lead.leadpotential = this.viewticket.lmslead.filter(i => i.brand != null);

      this.lead.leadpotential.forEach(i => {
        if (i.purchaseDate != null && i.purchaseDate != '') {
          i.purchaseDate = new Date(i.purchaseDate).toISOString().split('T')[0];

        }
      })
      this.lead.comptitorPotential = this.viewticket.lmslead;
      // this.lead.comptitorPotential = this.viewticket.lmslead.filter(i => i.brand == null)
      console.log(this.lead)
    })



    // for(let i=0;i<this.viewticket.lmslead.length;i++){

    //   if(this.viewticket.lmslead[i].potential_type=='Current Brand (MT)'){
    //     this.lead.leadpotential=this.viewticket.lmslead[i];
    //   }

    //   if(this.viewticket.lmslead[i].potential_type=='Sitepotential'){
    //     this.lead.comptitorPotential=this.viewticket.lmslead[i];
    //   }
    // }

  }

  // { product: '', quantity: '', price: '', brand: '', purchaseDate: '', leadpotionaltype: 'Current Brand (MT)' }
  // { product: '', quantity: '', leadpotionaltype: 'Sitepotential' }
  validation() {
    if (!this.lead.slab_date) {
      return true;
    }
    if (this.lead.leadpotential.length = 0) {
      return true;
    }
    if (this.lead.leadpotential.length > 0) {
      this.lead.leadpotential.forEach(element => {
        if (element.brand == null || element.brand == '') {
          return true;
        }
        if (element.product == null || element.product == '') {
          return true;
        }
        if (element.quantity == null || element.quantity == '') {
          return true;
        }
        if (element.purchaseDate == null || element.purchaseDate == '') {
          return true;
        }
      })
    }
    if (this.lead.comptitorPotential.length > 0) {
      this.lead.comptitorPotential.forEach(element => {

        if (element.product == null || element.product == '') {
          return true;
        }
        if (element.quantity == null || element.quantity == '') {
          return true;
        }
      })
    }
  }


  getsiteidonsavesite: any = [];
  createSite(form) {
    var count = 0;


    for (let i = 0; i < this.site.contactpersondetails.length; i++) {

      if (this.site.contactpersondetails[i].isPrimary == 'yes') {
        this.site.contactpersondetails[i].isPrimary = '1'

      }
      else {

        this.site.contactpersondetails[i].isPrimary = '0'

      }


      if (this.site.contactpersondetails[i].ihbContatct != '' && this.site.contactpersondetails[i].ihbContatct != null && this.site.contactpersondetails[i].ihbContatct.length < 10) {
        alert(this.site.contactpersondetails[i].ihbContatct + " number is less than 10 digit")
        // if(i < this.site.contactpersondetails.length)
        // continue;
        // else
        return;
      }


      // if (i < 3) {
      //   if ((this.site.contactpersondetails[i].ihbContatct != '' && this.site.contactpersondetails[i].ihbContatct != null) && (this.site.contactpersondetails[i].ihbName != '' && this.site.contactpersondetails[i].ihbName != null)) {
      //     count = 1;
      //   }
      //   else if (count == 1 && 
      //     ((this.site.contactpersondetails[i].ihbContatct !='' &&  this.site.contactpersondetails[i].ihbContatct != null) && (this.site.contactpersondetails[i].ihbName != '' &&  this.site.contactpersondetails[i].ihbName != null)) ) {
      //     alert("Please Fill All Details")
      //     if(i < this.site.contactpersondetails.length)
      //   continue;
      //   else
      //   return;
      //   }

      //   else if (count != 1 && (this.site.contactpersondetails[i].ihbContatct == '' || this.site.contactpersondetails[i].ihbContatct == null) && (this.site.contactpersondetails[i].ihbName == '' || this.site.contactpersondetails[i].ihbName == null)) {
      //     alert("Please Fill Atleast One Contact Details")
      //     if(i < this.site.contactpersondetails.length)
      //     continue;
      //     else
      //     return;
      //   }

      // }

      // else if (i > 2) {
      //   if ((this.site.contactpersondetails[i].ihbContatct != '' && this.site.contactpersondetails[i].ihbContatct != null) && (this.site.contactpersondetails[i].ihbName != '' && this.site.contactpersondetails[i].ihbName != null) && (this.site.contactpersondetails[i].ihbCategory != '' && this.site.contactpersondetails[i].ihbCategory != null)) {
      //     count = 1;
      //   }
      //   else if (count == 1 && (this.site.contactpersondetails[i].ihbContatct == '' || this.site.contactpersondetails[i].ihbContatct == null) || (this.site.contactpersondetails[i].ihbName == '' || this.site.contactpersondetails[i].ihbName == null) || (this.site.contactpersondetails[i].ihbCategory == '' || this.site.contactpersondetails[i].ihbCategory == null)) {
      //     alert("Please Fill All Details")
      //     if(i < this.site.contactpersondetails.length)
      //   continue;
      //   else
      //   return;
      //   }

      // }
    }
    this.site.stateId = this.site.stateName;
    this.site.createdById = sessionStorage.getItem('userid');
    console.log(this.site.createdById)
    this.http.post('/site/save', this.site).subscribe(data => {
      if (data.status == '200') {
        jQuery("#addSiteModal").modal("hide");
        Swal.fire({
          title: 'Site Created successfully',
          timer: 3000
        });

        if (this.lead.sourcetype != 'Self') {
          this.getAllSite();
        }
        else if (this.lead.sourcetype == 'Self') {
          this.getSelfSiteData()
        }

        // get site data after save create site
        this.getsiteidonsavesite = data.data.id;
        //   this.show = true;

        this.http.get('/site/get-address-sites-web/' + this.getsiteidonsavesite).subscribe(data => {
          if (data.status == '200') {
            this.contactpersonlist = data.data;
            this.degree.addressLine1 = data.data[0].addressLine1;
            this.degree.addressLine2 = data.data[0].addressLine2;
            this.degree.addressLine3 = data.data[0].addressLine3;
            this.degree.addressLine4 = data.data[0].addressLine4;
            //  this.degree.ctsGeogId = data.data.geographyMaster.id;
            this.degree.pincode = data.data[0].pinCode
            this.degree.stateId = data.data[0].state
            this.degree.districtId = data.data[0].district
            this.degree.cityId = data.data[0].city
            this.degree.ihbName = data.data[0].name
            this.degree.ihbContatct = data.data[0].contact
            this.degree.name = data.data[0].catName
            this.site.constuctionStage = data.data[0].constructionstage;
          }
        });

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          // footer: '<a href>Why do I have this issue?</a>'
        });
      }
    });
    form.reset();
  }

  formreset() {
    this.lead.sourcetype = '';
    this.lead.accountcontact = '';
    this.lead.accountname = '';
    this.lead.loginId = '';
    this.lead.accountcat = '';
    this.lead.siteid = '';
    this.lead.leadtype = '';
    this.lead.departmentid = '';
    this.lead.assigntoid = '';
    this.lead.comment = '';
    this.lead.slab_date = '';
    this.lead.foundation_date = '';
    this.lead.grahpravesh_date = '';
    this.lead.callername = '';
    this.lead.callercategry = '';
    this.lead.addressLine1 = '';
    this.lead.districtId = '';
    this.lead.addressLine2 = '';
    this.lead.addressLine3 = '';
    this.lead.addressLine4 = '';
    this.lead.pincode = '';
    this.lead.stateId = '';
    this.lead.trade = '';
    this.lead.cityId = '';
    this.lead.productid = '';
    this.degree.contact = '',
      this.degree.contactCategory = '',
      this.degree.contactName = '',
      this.degree.addressLine1 = '',
      this.degree.addressLine2 = '',
      this.degree.addressLine3 = '',
      this.degree.addressLine4 = '',
      this.degree.pincode = '',
      this.degree.stateId = '',
      this.degree.districtId = '',
      this.degree.cityId = '',
      this.degree.ihbContatct = '',
      this.degree.ihbName = '',
      this.degree.name = '',
      this.site.name = '',
      this.site.constuctionStage = '',
      this.site.geographyMasterId = '',
      this.site.geogId = '',
      this.site.addressLine1 = '',
      this.site.addressLine2 = '',
      this.site.addressLine3 = '',
      this.site.addressLine4 = '',
      this.site.pinCode = '',
      this.site.cityName = '',
      this.site.districtName = '',
      this.site.stateName = '',
      this.site.ihbName2 = '',
      this.site.ihbContact2 = '',
      this.site.ihbCategory2 = '',
      this.site.ihbName3 = '',
      this.site.ihbContact3 = '',
      this.site.ihbCategory3 = '',
      this.site.ihbName4 = '',
      this.site.ihbContact4 = '',
      this.site.ihbCategory4 = '',
      this.site.contactpersondetails = [
        { ihbName: '', isPrimary: '', ihbContatct: '', ihbCategory: 'Architect' },
        { ihbName: '', isPrimary: '', ihbContatct: '', ihbCategory: 'IHB' },
        { ihbName: '', isPrimary: '', ihbContatct: '', ihbCategory: 'Contractor' }
      ],
      this.lead.leadpotential = [
        { product: '', quantity: '', price: '', brand: '', purchaseDate: '', leadpotionaltype: 'Current Brand (MT)' }
      ],
      this.lead.comptitorPotential = [
        { product: '', quantity: '', leadpotionaltype: 'Sitepotential ' }
      ]
    // this.site.contactpersoncategory= [
    //   {name: ''},
    //   {cate: ''},
    //   {cate: ''},
    //   {cate: ''},
    //   {cate: ''},
    //   {cate: ''},
    // ]
  }


  // View modal data
  viewTicket(id) {
    // this.updatedata(id);
    // this.http.get('/ctsticket/get/' + id).subscribe(data => {
    //   if (data.status == '200') {
    //     this.history = null;
    //     this.viewticket.ticketnm = data.data.ticketNumber;
    //     this.viewticket.assignto = data.data.assignedToUser.firstName
    //     this.viewticket.createdby = data.data.createdByUser.firstName
    //     this.viewticket.status = data.data.ctsStatus.name
    //     this.viewticket.ticketcategory = data.data.category.name;
    //     this.viewticket.ticketsubcat = data.data.subCategory.name;
    //     this.viewticket.accountname = data.data.accountName
    //     this.viewticket.accountcontact = data.data.accountContact
    //     this.viewticket.sitedetails = data.data.ctsSiteContact.address.addressLine1
    //     this.viewticket.catsubcat=this.viewticket.ticketcategory+"/"+ this.viewticket.ticketsubcat
    //     if (data.data.ctsSiteContact.address.geographyMaster)
    //       this.viewticket.state = data.data.ctsSiteContact.address.geographyMaster.stateName
    //     this.history = data.data.ctsTickeHistories;
    //   }
    // });

  }
  degree = {
    contact: '',
    sitecategory: '',
    contactCategory: '',
    contactName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    pincode: '',
    stateId: '',
    districtId: '',
    cityId: '',
    ihbContatct: '',
    ihbName: '',
    name: ''
  }



  getDataContact(contact, type) {
    if (type == 'contact' && contact.length == 10) {

      this.http.get('/ctsinfluencer/verify-accounts-influencer/' + contact + '/' + type + '/' + sessionStorage.getItem("divison") + '/' + sessionStorage.getItem("department")).subscribe(data => {
        if (data.status == '200') {
          this.degree.contact = data.data[0].contact;
          this.degree.contactCategory = data.data[0].category;
          this.degree.contactName = data.data[0].name;

        }
        else if (data.status == '204') {
          this.degree.contactName = '';
          this.degree.contactCategory = '';
          alert("Influencer Not Exists, Please Create Influencer.")
        }
        this.getAllSite();
      });





    }
    // else if (contact.length > 2) {
    //   this.http.get('/ctsinfluencer/verify-accounts-influencer/' + contact + '/' + type).subscribe(data => {
    //     if (data.status == '200') {
    //       this.degree.contact = data.data[0].contact;
    //       this.degree.contactCategory = data.data[0].category;
    //       this.degree.contactName = data.data[0].name;
    //     }

    //   });
    //   this.getAllSite();
    // }
  }

  getAllSite() {
    this.http.get('/site/get-allsites/' + this.degree.contact).subscribe(data => {
      if (data.status == '200') {
        this.selfsourceLead = data.data

        // this.addres=data.data.address;

      }

    });
  }



  assign(assignLeadForm: NgForm) {
    if (this.assignToDeptUser.find(x => x.employeeId == this.lead.assigntoid).firstName == this.viewticket.assignto) {
      Swal.fire({
        title: 'Ticket is already assigned to this user',
        timer: 3000
      });
      jQuery('#assignModel').modal('show');
    }
    else {
      jQuery('#assignModel').modal('hide');
      this.http.get('/ctslead/assign/' + this.lead.assigntoid + '/' + this.viewticket.id).subscribe(data => {
        if (data.status == '200') {
          Swal.fire({
            title: 'Lead assigned successfully',
            timer: 3000
          });
          this.getLeadData();
          assignLeadForm.resetForm();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            // footer: '<a href>Why do I have this issue?</a>'
          });
        }
      });
    }
  }


  update(form: NgForm) {
    console.log(form)

    this.http.post('/ctslead/updatelead', this.updatelead).subscribe(data => {
      if (data.status == '200')
        jQuery('#updatelead').modal('hide');
      Swal.fire({
        title: 'Lead updated successfully',
        timer: 3000
      });
    })

    form.resetForm();
  }


  // This for influencer modal

  createInfluncer(form) {
    jQuery("#influncerModal").modal("hide");
    this.http.post('/ctsinfluencer/save', this.influncer).subscribe(data => {
      if (data.status == '200') {
        this.getDataContact(data.data.contact, 'contact');
        Swal.fire({
          title: 'Influencer created successfully',
          timer: 3000
        });
        jQuery('.closebtn').click();
        this.site.stateName = '';
        this.site.cityName = '';
        this.site.districtName = '';
        //this.getAllInfluncer();
      }
      else {
        Swal.fire({
          title: 'Something Went Wrong',
          timer: 3000
        });
      }
    });
    form.reset();
  }
  checkContact(contact) {
    if (contact.length == 10) {
      this.http.get('/ctsinfluencer/verify-accounts-influencer/' + contact + '/contact').subscribe(data => {
        if (data.status == '200') {
          this.degree.contactName = '';
          this.degree.contactCategory = '';
          alert("Influencer Already Exists On This Number.");
          this.influncer.contact = null;
        }
      });
    }
  }

  setFirstRowData() {
    this.degree.sitecategory = this.degree.contactCategory;
    if (this.lead.sourcetype != 'Self')
      this.site.contactpersondetails[0] = { ihbName: this.degree.contactName, isPrimary: '', ihbContatct: this.degree.contact, ihbCategory: this.degree.contactCategory };

  }

  listgetofcontactperson: any[];
  ngOnInit() {
    this.listgetofcontactperson = this.site.contactpersoncategory;
    this.dropdownList = [
      { item_id: 1, item_text: 'JK' },
      { item_id: 2, item_text: 'AMBUJA' },
      { item_id: 3, item_text: 'ACC' },
      { item_id: 4, item_text: 'SANGHI' },
      { item_id: 5, item_text: 'ULTRATECH' },
      { item_id: 6, item_text: 'WONDER' },
      { item_id: 7, item_text: 'BIRLA' },
      { item_id: 8, item_text: 'PERFECT PLUS' },
      { item_id: 9, item_text: 'NUVOCO' },
      { item_id: 10, item_text: 'MYCEM' },
      { item_id: 11, item_text: 'PRISM' },
      { item_id: 12, item_text: 'BANGUR' },
      { item_id: 13, item_text: 'MANGALAM' },
      { item_id: 14, item_text: 'ROCK' },
      { item_id: 15, item_text: 'SHREE' },
      { item_id: 16, item_text: 'SIDHEE' },
      { item_id: 17, item_text: 'INDIA' },
      { item_id: 18, item_text: 'SHRIRAM' },
      { item_id: 19, item_text: 'NIRMAX' },
      { item_id: 20, item_text: 'VASODATTA' },
      { item_id: 21, item_text: 'ZUARI' },
      { item_id: 22, item_text: 'RAMCO' },
      { item_id: 23, item_text: 'DALMIA' },
      { item_id: 24, item_text: 'KESORAM' },
      { item_id: 25, item_text: 'JSW' },
      { item_id: 26, item_text: 'CHETTINAD' },
      { item_id: 27, item_text: 'PENNA' },
      { item_id: 28, item_text: 'JAYPEE' },
      { item_id: 29, item_text: 'DURATON' },
      { item_id: 30, item_text: 'KAMAL' },
      { item_id: 31, item_text: 'Other' }
    ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  }

  onItemSelect(item: any) {
    // for(let i=0;i<item.length;i++){
    //   this.influncer.preferredBrand=  item[i].item_text+","+ this.influncer.preferredBrand
    //   }
    this.influncer.preferredBrand = JSON.stringify(item['item_text']) + "," + this.influncer.preferredBrand;
    console.log("onItemSelect == " + this.influncer.preferredBrand);
  }
  onSelectAll(items: any) {
    for (let i = 0; i < items.length; i++) {
      this.influncer.preferredBrand = items[i].item_text + "," + this.influncer.preferredBrand
    }
    console.log("onSelectAll == " + this.influncer.preferredBrand)
  }




  addContactDetails(i, leadviewlist) {
    //this.neweditsite=i;

    this.viewcontactperson.push({ ihbName: '', ihbContatct: '', ihbCategory: '', category: { id: '', name: "", moduleId: '', status: '', parentId: '' } })
  }

  removeContactDetails(index) {
    if (index > 0)
      this.viewcontactperson.splice(index, 1);

  }
  closeleaddata() {
    //this.visitno=true;
    this.visitactive = false;
    this.weathershow = false;
    this.lead.foundation_date = '';
    this.lead.grahpravesh_date = '';
    this.lead.slab_date = '';
    this.lead.leadpotential = null;
    this.lead.comptitorPotential = null;

    // this.reasonnonconversion=false;
    // this.weatherno=false;
    this.weatheractive = false;
    this.sitepotentialjklefting = false;
    this.reasonnonconversion = false;
  }

  resetcreateinfulencer() {
    this.influncer.categoryId = '',
      this.influncer.name = '',
      this.influncer.tier = '',
      this.influncer.preferredBrand = '',
      this.influncer.monthlyPotentialMt = '',
      this.influncer.tradeSitesNumber = '',
      this.influncer.contact = '',
      this.influncer.email = '',
      this.influncer.status = ''
  }
  updateReset(updateform: NgForm) {
    updateform.resetForm();
    // this.updatelead.visitype = '',
    // this.updatelead.followuprequired = '',
    // this.updatelead.description = '',
    //   this.updatelead.revisitdate = '',
    //   this.updatelead.isconverted = ''
    this.followuprequireddate = false;
  }


  //check ihb contact duplicacy
  checkIhbDuplicate(contact, i) {
    if (contact != null && contact.length == 10 && this.site.contactpersondetails[i].ihbCategory == 'IHB')
      this.http.get('/ctsinfluencer/verify-contact-details/' + contact + "/ihb").subscribe(data => {
        if (data.status == 200) {
          if (data.data.length > 0) {
            this.site.contactpersondetails[i].ihbContatct = ''
            alert("Ihb Number already Exist")
          }
        }
      })
  }


  editLead() {

    for (let i = 0; i < this.viewcontactperson.length; i++) {

      if (this.viewcontactperson[i].isPrimary == 'yes') {
        this.viewcontactperson[i].isPrimary = '1'
        console.log(i + " if = " + this.viewcontactperson[i].isPrimary)
      }
      else {
        console.log(i + " else = " + this.viewcontactperson[i].isPrimary)
        this.viewcontactperson[i].isPrimary = '0'

      }
      if (this.viewcontactperson[i].category.name != null && this.viewcontactperson[i].category.name != '') {
        this.viewcontactperson[i].ihbCategory = this.viewcontactperson[i].category.name;
        this.viewcontactperson[i].categoryId = null;
      }
    }


    const req = {
      lmscontact: this.viewcontactperson,
      siteid: this.viewticket.siteid,
      id: this.lead.leadid,
      leadpotential: this.lead.leadpotential,
      comptitorPotential: this.lead.comptitorPotential,
      slab_date: this.lead.slab_date,
      foundation_date: this.lead.foundation_date,
      grahpravesh_date: this.lead.grahpravesh_date
    }
    this.http.post('/ctslead/editleadweb', req).subscribe(data => {
      if (data.status == '200') {

        Swal.fire({
          title: 'Lead edited successfully',
          timer: 5000
        });
        this.getLeadData();
      }
    })
  }


  editisPrimary(value, index) {

    if (value.checked) {
      alert("check ==   " + index)
      this.viewcontactperson[index].isPrimary = '1'
    }
    else {
      alert("uncheck==" + index)
      this.viewcontactperson[index].isPrimary = '0'
    }
  }



}
