import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CtsTargetComponent } from './cts-target/cts-target.component';

const routes: Routes = [
  {path: 'cts-target', component: CtsTargetComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CtsSubadminRoutingModule { }
