import { Component, OnInit } from '@angular/core';
// import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HttpClientService } from '../http-client-service.service';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
// import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-profile',
  templateUrl: './event-profile.component.html',
  styleUrls: ['./event-profile.component.css']
})
export class EventProfileComponent implements OnInit {

  // constructor() { }

  // ngOnInit() {
  // }

  currentDate:any;
  dtOptions: DataTables.Settings = {};
  cityList: any = [];
  districtList: any = [];
  ownerList: any = [];
  stateList: any = [];
  divisonList: any = [];
  statusList: any = [
    {
      id: 1,
      value: 'Active'
    },
    {
      id: 0,
      value: 'InActive'
    }
  ];
  accountData: any = {
    // siteName: "",
    // constructionStage: "",
    // influencerCat: "",
    // siteContact: "",
    // state: "",
    // district: "",
    // city: "",
    // pinCode: "",
    // divisionId: "3"
  };
  siteData: any = {
    state: "",
    district: "",
    city: "",
    divisionId: "3"
  };
  customerType: any = [];
  term: string = '';
  whiteDivision: boolean = false;
  dropdownSettings: any;
  usersList: any = [];
  createAcct: any = {};
  view: boolean = false;
  edit: boolean = false;
  // ABCClassificationList: any = [];
  InfluencerCategory: any = [];
  SiteCategory: any = [];
  constructionStage: any = [];
  InfluencerTier: any = [];
  preferredBrand: any = [];
  disableSAPCode: boolean;
  pager: { itemsPerPage: any; currentPage: any; totalItems: any; };
  perPage: any = 10;
  pageNumber: any = 1;
  addcreateAcct: any;
  mobileError: boolean = true;
  allOwnersList: any = [];
  pincodedata: any;
  pincodeError: string;
  sapcodeError: string = "";
  sapcodeVError: boolean = false;
  dropdownSettingsFilter: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  dropdownSettingsForTransferUser: {
    singleSelection: boolean;
    idField: string;
    textField: string;
    selectAllText: string;
    unSelectAllText: string;
    itemsShowLimit: number;
    allowSearchFilter: boolean;
    closeDropDownOnSelection: boolean;
  };
  primaryOwnerIdError: boolean = false;
  page: number = 1;
  itemsPerPage: number = 10;
  totalItems: any;
  timeout: any = null;
  errMsg: string = "";
  fromDate: any = new Date();
  toDate: any = new Date();
  downloadExcelFilter: any = {};
  dt: any;
  ct: any;
  taluka: any;
  locality: any;
  oldPincode: any;
  showAbcClassificationList: boolean = false;
  showCustomerTypeList: boolean = false;
  departmentList: any;
  validationErrorMsg: any;
  transferList = {
    createdBy: "",
    ids: []
  }
  transferUserList = [];
  userlist = [];
  obj:any;
  isVanExist = '';
  eventPreviousState : any;
  eventStatus:boolean;
  eventId:number;
  eventUserDetails:any;
  // activeAccountState:boolean=false;
  // activeAccountId:number;
  approvalList = [
    {
      id:13,
      name:"RE-CTS"
    }, 
    {
      id:14,
      name:"SH-CTS"
    }, 
    {
      id:15,
      name:"ZH-CTS"
    }, 
    {
      id:18,
      name:"HEAD-CTS"
    },
    // {
    //   id:"NA",
    //   name:"NA"
    // }
  ];
  // level2 = ["SH-CTS", "ZH-CTS", "HEAD-CTS", "NA"];
  // level3 = ["HEAD-CTS", "NA"];

  constructor(private http: HttpClientService, private spinner: NgxSpinnerService, public datepipe: DatePipe, private route: Router) { }

  ngOnInit() {
    let date = new Date().toLocaleDateString().split("/").reverse();
    this.currentDate = date[0]+'-'+date[2]+'-'+date[1];
    
    this.downloadExcelFilter = {
      startDate: this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'),
      endDate: this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),
    }

    // this.getAllOwners();
    this.dtOptions = {
      pagingType: 'full_numbers',
      ordering: false
    };

    // this.getStateList();
    // this.getDivisonList();
    // this.getABCClassification(2);
    // this.getCustomerType(2);
    // this.getDepartmentList();
    // this.getSiteCategory();
    // this.getConstructionStage();
    // this.getInfluencerCategoryForSite();
    // this.getInfluencerTier();
    // this.getBrands();
    // this.getTransferUserList();
    this.find();
    this.createAcct = {
      status: 1,
      dealer_ars_change_level1:"",
      dealer_ars_change_level2:"",
      dealer_ars_change_level3:"",
      event_creation_level1:"",
      event_creation_level2:"",
      event_creation_level3:"",
      event_date_change_level1:"",
      event_date_change_level2:"",
      event_date_change_level3:"",
      violation_of_food_level1:"",
      violation_of_food_level2:"",
      violation_of_food_level3:"",
      violation_of_invitee_level1:"",
      violation_of_invitee_level2:"",
      violation_of_invitee_level3:"",

      event_creation_list_level2:[],
      event_creation_list_level3:[],
      dealer_ars_change_list_level2:[],
      dealer_ars_change_list_level3:[],
      event_date_change_list_level2:[],
      event_date_change_list_level3:[],
      violation_of_invitee_list_level2:[],
      violation_of_invitee_list_level3:[],
      violation_of_food_list_level2:[],
      violation_of_food_list_level3:[],
    };    
    // this.dropdownSettings = {
    //   singleSelection: false,
    //   text:"Select Owners",
    //   selectAllText:'Select All',
    //   unSelectAllText:'UnSelect All',
    //   enableSearchFilter: true,
    //   classes:"myclass custom-class"
    // };
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'userId',
      textField: 'userName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.dropdownSettingsFilter = {
      singleSelection: true,
      idField: 'userId',
      textField: 'userName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
    };
    this.dropdownSettingsForTransferUser = {
      singleSelection: true,
      idField: 'employeeid',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
    };

    //  this.accountData.state = "";
  }

  objectToArray(obj) {
    const arr = [];
    Object.keys(obj).forEach(key => {
      arr.push(
        {
          id: key,
          value: obj[key]
        }
      );
    });
    return arr;
  }

  objectToArrayForTransferUser(obj) {
    const arr = [];
    Object.keys(obj).forEach(key => {
      arr.push(
        {
          employeeid: obj[key]["employeeid"],
          name: obj[key]["employeeid"] + " (" + obj[key]["name"] + ")"
        }
      );
    });
    return arr;
  }

  objectToArrayOwner(obj) {
    const arr = [];
    if (obj) {
      Object.keys(obj).forEach(key => {
        arr.push(
          {
            userId: obj[key][0],
            userName: obj[key][1] + "(" + obj[key][0] + ")",
            territoryCode: obj[key][2]
          }
        );
      });
      return arr;
    }
  }

  // getStateList() {
  //   this.spinner.show();
  //   this.http.get('/account-management/get-state').subscribe(data => {
  //     this.spinner.hide();
  //     if (data.status == 200) {
  //       this.stateList = this.objectToArray(data.data);
  //       // this.accountData.state = sessionStorage.getItem("stateCode");
  //       // this.find();
  //     }
  //   });
  // }

  getCustomerType(id) {
    this.spinner.show();
    this.http.get(`/account-management/get-customer-group/${id}`).subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.customerType = this.objectToArray(data.data);
      }
    });
  }

  getDivisonList() {
    this.spinner.show();
    this.http.get('/account-management/get-division').subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.divisonList = this.objectToArray(data.data);
      }
    });
  }

  stateSelected(stateId) {
    if (stateId) {
      sessionStorage.setItem('selected-state', stateId);
      this.getCityByState(stateId);
      this.getDistrictByState(stateId);
      this.getOwnerByState(stateId);
    }
  }

  getCityByState(id) {
    this.spinner.show();
    this.http.get(`/account-management/get-citybystateid?stateid=${id}`).subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.cityList = this.objectToArray(data.data);
      }
    });
  }

  getDistrictByState(id) {
    this.spinner.show();
    this.http.get(`/account-management/get-districtbystateid?stateid=${id}`).subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.districtList = this.objectToArray(data.data);
      }
    });
  }

  setOwnetArr(ownerList) {
    let arr = [];
    if (!Array.isArray(ownerList)) {
      arr = [ownerList];
    } else if (Array.isArray(ownerList)) {
      arr = ownerList.map(v => v.id);
    }
    const ownerArr = [];
    arr.forEach(v => {
      this.ownerList.forEach(element => {
        if (element.id == v) {
          ownerArr.push(element.id);
        }
      });
    });
    return ownerArr;
  }

  getOwnerByState(id) {
    this.spinner.show();
    this.http.get(`/account-management/get-primaryOwnerbyStateid?stateid=${id}`).subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        //this.createAcct.territoryCode = data.data["user details"][0][2];
        this.ownerList = this.objectToArrayOwner(data.data["user details"]);
      }
    });
  }

  // getABCClassification(id) {
  //     this.spinner.show();
  //     this.http.get(`//account-management/get-abc-classification/${id}`).subscribe(data => {
  //         this.spinner.hide();
  //         if (data.status == 200) {
  //             this.ABCClassificationList = this.objectToArray(data.data);
  //         }
  //     });
  // }

  getSiteCategory() {
    this.spinner.show();
    this.http.get("/ctscategory/nwtcategory").subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.SiteCategory = this.objectToArray(data.data);
      }
    });
  }

  getConstructionStage() {
    this.spinner.show();
    this.http.get("/nwtlead/getConstStage").subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.constructionStage = this.objectToArray(data.data);
      }
    });
  }

  getInfluencerCategoryForSite() {
    this.spinner.show();
    this.http.get("/ctscategory/influencer-category").subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.InfluencerCategory = this.objectToArray(data.data);
      }
    });
  }

  getInfluencerTier() {
    this.spinner.show();
    this.http.get("/ctswhiteinfluencer/fetchInfluencerTier/3/3").subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.InfluencerTier = this.objectToArray(data.data);
      }
    });
  }
  getBrands() {
    this.spinner.show();
    this.http.get("/nwtlead/getBrand").subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.preferredBrand = this.objectToArray(data.data);
      }
    });
  }

  searchUser() {
    if (this.term && this.term.length > 2) {
      this.spinner.show();
      this.http.get(`/account-management/get-by-customer-name/${this.term}`).subscribe(data => {
        this.spinner.hide();
        if (data.status == 200) {
          this.usersList = data.data;
        }
      });
    } else if (this.term.length == 0) {
      this.find();
    }
  }

  getUserDetails() {
    this.spinner.show();
    this.usersList = [];
    this.http.get(`/account-management/all-account`).subscribe(data => {
      if (data) {
        this.usersList = data;
      }
      this.spinner.hide();
    });
  }

  reset() {
    this.edit = false;
    this.view = false;
    this.createAcct = {
      status: 1,
      dealer_ars_change_level1:"",
      dealer_ars_change_level2:"",
      dealer_ars_change_level3:"",
      event_creation_level1:"",
      event_creation_level2:"",
      event_creation_level3:"",
      event_date_change_level1:"",
      event_date_change_level2:"",
      event_date_change_level3:"",
      violation_of_food_level1:"",
      violation_of_food_level2:"",
      violation_of_food_level3:"",
      violation_of_invitee_level1:"",
      violation_of_invitee_level2:"",
      violation_of_invitee_level3:"",
    };
    $('#createInfluencerModal').trigger('reset');
    // this.cityList = [];
    // this.districtList = [];
    // this.ownerList = [];
    // this.pincodedata = [];
    // this.ct = [];
    // this.dt = [];
    // this.locality = [];
    // this.taluka = [];
    // this.oldPincode = '';
    // this.showAbcClassificationList = false;
    // this.showCustomerTypeList = false;
    this.stateSelected(sessionStorage.getItem('selected-state'));
  }

  onItemSelect(val) {
    this.obj=val;
    this.checkMappedExecutive(val);
  }

  onDropDownClose(val) {
    
    if (!this.createAcct.allAccountOwner || !this.createAcct.allAccountOwner.length) {
      this.primaryOwnerIdError = true;
    } else {
      this.primaryOwnerIdError = false;
    }
  }

  setPrimaryOwner(prmowner, otheracc) {
    const arrayV = [];
    if (prmowner) {
      arrayV.push({ userId: prmowner.id, userName: prmowner.firstName + " " + prmowner.lastName + "(" + prmowner.employeeId + ")", territoryCode: prmowner.territoryCode })
    }
    if (otheracc) {
      otheracc.forEach(function (value) {
        arrayV.push({ userId: Number(value.userId), userName: value.userName + "(" + value.accountOwner.employeeId + ")", territoryCode: value.territoryCode })
      });
    }
    return arrayV;
  }

  getStateCode(state) {
    const code = this.stateList.filter(ele => {
      if (ele.value === state) {
        return ele
      }
    })
    return code[0].id
  }

  emailValidation = () => {
    const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})/;
    if (this.createAcct.email) {
      const emailval = this.createAcct.email.match(validRegex);
      if (emailval) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return true
    }
  }

  mobileValidation = (type) => {
    let arr = ["6", "7", "8", "9"]
    if (this.createAcct.siteContact && type == "S") {
      if (arr.includes(this.createAcct.siteContact[0]) && this.createAcct.siteContact.length == 10) {
        return true
      }
      else {
        return false
      }
    }
    else if (this.createAcct.infContact && type == "I") {
      if (arr.includes(this.createAcct.infContact[0]) && this.createAcct.infContact.length == 10) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }

  // checkDuplicateEvent(){
  //   if(this.createAcct.glAccount !== this.eventPreviousState.glAccount){
  //     let duplicacy = this.usersList.filter(user => user.glAccount === this.createAcct.glAccount || user.orderNo === this.createAcct.orderNo);
  //     if(duplicacy.length > 0) {
  //       return true
  //     }
  //   }else{
  //     return false
  //   }
    
  // }

  validation() {
    // if(this.edit && !(this.createAcct.glAccount === this.eventPreviousState.glAccount || this.createAcct.orderNo === this.eventPreviousState.orderNo)) {
    //   return false; 
    // }
    // if(this.checkDuplicateEvent()){
    //   this.validationErrorMsg = "Sorry! Either Gl Account already exists or Order no exist"
    //   return true;
    // }
    if (!this.createAcct.eventType) {
      this.validationErrorMsg = "Please Enter Event Name."
      return true;
    }
    if (this.createAcct.eventType.length < 4) {
      this.validationErrorMsg = "Event Name should have atleast 4 characters."
      return true;
    }
    if (this.createAcct.eventType[0] === " ") {
      this.validationErrorMsg = "Please Enter Valid Event Name."
      return true;
    }
    if (!this.createAcct.glAccount) {
      this.validationErrorMsg = "Please Enter GL Account."
      return true;
    }
    if (parseInt(this.createAcct.glAccount) == 0) {
      this.validationErrorMsg = "GL Account should be greater than 0."
      return true;
    }
    if (this.createAcct.glAccount.length < 6) {
      this.validationErrorMsg = "Please fill atleast 6 characters."
      return true;
    }
    if (!this.createAcct.orderNo) {
      this.validationErrorMsg = "Please Enter Order No."
      return true;
    }
    if (parseInt(this.createAcct.orderNo) == 0) {
      this.validationErrorMsg = "Order No. should be greater than 0."
      return true;
    }
    if (this.createAcct.orderNo.length < 6) {
      this.validationErrorMsg = "Please fill atleast 6 characters."
      return true;
    }
    if (!this.createAcct.idealPax) {
      this.validationErrorMsg = "Please Enter Ideal Pax."
      return true;
    }
    if (parseInt(this.createAcct.idealPax) == 0) {
      this.validationErrorMsg = "Ideal Pax should be greater than 0."
      return true;
    }
    if (!this.createAcct.maxPax) {
      this.validationErrorMsg = "Please Enter Max Pax."
      return true;
    }
    if (parseInt(this.createAcct.maxPax) == 0) {
      this.validationErrorMsg = "Max Pax should be greater than 0."
      return true;
    }
    if (parseInt(this.createAcct.maxPax) <= parseInt(this.createAcct.idealPax)) {
      this.validationErrorMsg = "Max Pax should be greater than Ideal Pax."
      return true;
    }
    if (!this.createAcct.e_c) {
      this.validationErrorMsg = "Please Enter E&C."
      return true;
    }
    if (parseInt(this.createAcct.e_c) == 0) {
      this.validationErrorMsg = "E&C should be greater than 0."
      return true;
    }
    if (!this.createAcct.food_per_pax) {
      this.validationErrorMsg = "Please Enter Food Per Pax."
      return true;
    }
    if (parseInt(this.createAcct.food_per_pax) == 0) {
      this.validationErrorMsg = "Food Per Pax should be greater than 0."
      return true;
    }
    if (!this.createAcct.adminPerActivity) {
      this.validationErrorMsg = "Please Enter Admin Per Activity."
      return true;
    }
    if (parseInt(this.createAcct.adminPerActivity) == 0) {
      this.validationErrorMsg = "Admin Per Activity should be greater than 0."
      return true;
    }
    if (!this.createAcct.giftPerPax) {
      this.validationErrorMsg = "Please Enter Gift Per Pax."
      return true;
    }
    if (parseInt(this.createAcct.giftPerPax) == 0) {
      this.validationErrorMsg = "Gift Per Pax should be greater than 0."
      return true;
    }
    if (!this.createAcct.idealBudget) {
      this.validationErrorMsg = "Please Enter Ideal Budget."
      return true;
    }
    if (parseInt(this.createAcct.idealBudget) == 0) {
      this.validationErrorMsg = "Ideal Budget should be greater than 0."
      return true;
    }
    if (!this.createAcct.maxBudget) {
      this.validationErrorMsg = "Please Enter Max Budget."
      return true;
    }
    if (parseInt(this.createAcct.maxBudget) == 0) {
      this.validationErrorMsg = "Max Budget should be greater than 0."
      return true;
    }
    if (parseInt(this.createAcct.maxBudget) <= parseInt(this.createAcct.idealBudget)) {
      this.validationErrorMsg = "Max Budget should be greater than Ideal Budget."
      return true;
    }
    if (!this.createAcct.event_creation_level1) {
      this.validationErrorMsg = "Please select atleast level1 approval from all events."
      return true;
    }
    if (!this.createAcct.dealer_ars_change_level1) {
      this.validationErrorMsg = "Please select atleast level1 approval from all events."
      return true;
    }
    if (!this.createAcct.event_date_change_level1) {
      this.validationErrorMsg = "Please select atleast level1 approval from all events."
      return true;
    }
    if (!this.createAcct.violation_of_invitee_level1) {
      this.validationErrorMsg = "Please select atleast level1 approval from all events."
      return true;
    }
    if (!this.createAcct.violation_of_food_level1) {
      this.validationErrorMsg = "Please select atleast level1 approval from all events."
      return true;
    }
    return false;
  }

  calculateIdeal_Max_budget(){
    if( this.createAcct.e_c && this.createAcct.food_per_pax && this.createAcct.adminPerActivity){
      if(this.createAcct.idealPax && this.createAcct.maxPax == ""){
        this.createAcct.idealBudget = ((parseInt(this.createAcct.idealPax) + parseInt(this.createAcct.e_c)) * parseInt(this.createAcct.food_per_pax)) + parseInt(this.createAcct.adminPerActivity);
        this.createAcct.maxBudget = "";
      }
      else if(this.createAcct.maxPax && this.createAcct.idealPax == ""){
        this.createAcct.maxBudget = ((parseInt(this.createAcct.maxPax) + parseInt(this.createAcct.e_c)) * parseInt(this.createAcct.food_per_pax)) + parseInt(this.createAcct.adminPerActivity);
        this.createAcct.idealBudget = "";
      }
      else if(this.createAcct.maxPax && this.createAcct.idealPax){
        this.createAcct.maxBudget = ((parseInt(this.createAcct.maxPax) + parseInt(this.createAcct.e_c)) * parseInt(this.createAcct.food_per_pax)) + parseInt(this.createAcct.adminPerActivity);
        this.createAcct.idealBudget = ((parseInt(this.createAcct.idealPax) + parseInt(this.createAcct.e_c)) * parseInt(this.createAcct.food_per_pax)) + parseInt(this.createAcct.adminPerActivity);
      }
      else{
        this.createAcct.idealBudget = "";
        this.createAcct.maxBudget = "";
      }
    }
    else{
      this.createAcct.idealBudget = "";
      this.createAcct.maxBudget = "";
    }
  }

  createUser(type) {
    if (this.validation()) {
      alert(this.validationErrorMsg);
    }
    else {
      this.spinner.show();
      let payloadData = {
        adminPerActivity: this.createAcct.adminPerActivity,
        e_c: this.createAcct.e_c,
        eventType: this.createAcct.eventType,
        food_per_pax: this.createAcct.food_per_pax,
        giftPerPax: this.createAcct.giftPerPax,
        glAccount: this.createAcct.glAccount,
        idealBudget: this.createAcct.idealBudget,
        idealPax: this.createAcct.idealPax,
        maxBudget: this.createAcct.maxBudget,
        maxPax: this.createAcct.maxPax,
        orderNo: this.createAcct.orderNo,
        status:1,
      }
      if (type == 'create') {
        const reqData = [
          {
            ...payloadData,
            levelOne: this.createAcct.event_creation_level1 ? this.createAcct.event_creation_level1 : "NA",
            levelTwo: this.createAcct.event_creation_level2 ? this.createAcct.event_creation_level2 : "NA",
            levelThree: this.createAcct.event_creation_level3 ? this.createAcct.event_creation_level3 : "NA",
            category:"Approval on Event Creation"
          },
          {
            ...payloadData,
            levelOne: this.createAcct.dealer_ars_change_level1 ? this.createAcct.dealer_ars_change_level1 : "NA",
            levelTwo: this.createAcct.dealer_ars_change_level2 ? this.createAcct.dealer_ars_change_level2 : "NA",
            levelThree: this.createAcct.dealer_ars_change_level3 ? this.createAcct.dealer_ars_change_level3 : "NA",
            category:"Approval on Dealer/ARS Change"
          },
          {
            ...payloadData,
            levelOne: this.createAcct.event_date_change_level1 ? this.createAcct.event_date_change_level1 : "NA",
            levelTwo: this.createAcct.event_date_change_level2 ? this.createAcct.event_date_change_level2 : "NA",
            levelThree: this.createAcct.event_date_change_level3 ? this.createAcct.event_date_change_level3 : "NA",
            category:"Approval on Event Date Change"
          },
          {
            ...payloadData,
            levelOne: this.createAcct.violation_of_invitee_level1 ? this.createAcct.violation_of_invitee_level1 : "NA",
            levelTwo: this.createAcct.violation_of_invitee_level2 ? this.createAcct.violation_of_invitee_level2 : "NA",
            levelThree: this.createAcct.violation_of_invitee_level3 ? this.createAcct.violation_of_invitee_level3 : "NA",
            category:"Approval on Violation of Invitee/E&C Nos"
          },
          {
            ...payloadData,
            levelOne: this.createAcct.violation_of_food_level1 ? this.createAcct.violation_of_food_level1 : "NA",
            levelTwo: this.createAcct.violation_of_food_level2 ? this.createAcct.violation_of_food_level2 : "NA",
            levelThree: this.createAcct.violation_of_food_level3 ? this.createAcct.violation_of_food_level3 : "NA",
            category:"Approval on Violation of Food/Admin Budget"
          }
        ]
        //https://umber.jkcement.com/umber-rest/greyEvent/saveAll-grey-eventApprovals
        this.http.post(`/greyEvent/saveAll-grey-eventApprovals`, reqData).subscribe(data => {
          // if (data.status == 200) {
            Swal.fire({
              icon: "success",
              title: 'Event Added Successfully!',
              timer: 150000,
            });
          // }
          this.reset();
          this.find();
          (jQuery("#createAccount") as any).modal("hide");
          this.spinner.hide();
        }, err => {
          Swal.fire({
            icon:"error",
            title: err.error ? err.error.message : 'Error saving details',
            timer: 150000,
          });
          (jQuery("#createAccount") as any).modal("hide");
          this.spinner.hide();
        });
      }
      else {
        let reqData = {
          ...payloadData,
          eventCreationApprovlevelOne: this.createAcct.event_creation_level1 ? this.createAcct.event_creation_level1 : "NA",
          eventCreationApprovlevelTwo: this.createAcct.event_creation_level2 ? this.createAcct.event_creation_level2 : "NA",
          eventCreationApprovlevelThree: this.createAcct.event_creation_level3 ? this.createAcct.event_creation_level3 : "NA",
          eventArsChangeApprovOne: this.createAcct.dealer_ars_change_level1 ? this.createAcct.dealer_ars_change_level1 : "NA",
          eventArsChangeApprovTwo: this.createAcct.dealer_ars_change_level2 ? this.createAcct.dealer_ars_change_level2 : "NA",
          eventArsChangeApprovThree: this.createAcct.dealer_ars_change_level3 ? this.createAcct.dealer_ars_change_level3 : "NA",
          eventDateChangeApprovOne: this.createAcct.event_date_change_level1 ? this.createAcct.event_date_change_level1 : "NA",
          eventDateChangeApprovTwo: this.createAcct.event_date_change_level2 ? this.createAcct.event_date_change_level2 : "NA",
          eventDateChangeApprovThree: this.createAcct.event_date_change_level3 ? this.createAcct.event_date_change_level3 : "NA",
          voilationOfInviteApprovOne: this.createAcct.violation_of_invitee_level1 ? this.createAcct.violation_of_invitee_level1 : "NA",
          voilationOfInviteApprovTwo: this.createAcct.violation_of_invitee_level2 ? this.createAcct.violation_of_invitee_level2 : "NA",
          voilationOfInviteApprovThree: this.createAcct.violation_of_invitee_level3 ? this.createAcct.violation_of_invitee_level3 : "NA",
          voilationOfFoodApprovOne: this.createAcct.violation_of_food_level1 ? this.createAcct.violation_of_food_level1 : "NA",
          voilationOfFoodApprovTwo: this.createAcct.violation_of_food_level2 ? this.createAcct.violation_of_food_level2 : "NA",
          voilationOfFoodApprovThree: this.createAcct.violation_of_food_level3 ? this.createAcct.violation_of_food_level3 : "NA",
        }
        //@ts-ignore
        reqData.id = this.createAcct.id;
        this.http.put(`/greyEvent/update-grey-eventType/${this.createAcct.id}`, reqData).subscribe(data => {
          if (data.status == 200) {
            Swal.fire({
              icon: "success",
              title: 'Event Updated Successfully!',
              timer: 150000,
            });
          }
          this.reset();
          this.find();
          (jQuery("#createAccount") as any).modal("hide");
          this.spinner.hide();
        }, err => {
          Swal.fire({
            icon:"error",
            title: err.error ? err.error.message : 'Error saving details',
            timer: 150000,
          });
          (jQuery("#createAccount") as any).modal("hide");
          this.spinner.hide();
        });
      }
    }
  }

  filterPincode() {
    let self = this;
    const data = this.pincodedata.filter(item => {
      if (item.cityName == this.createAcct.city && item.districtName == this.createAcct.district && item.locality == this.createAcct.locality && item.taluka == this.createAcct.taluka
      ) {
        self.createAcct.geographyMasterId = item.id
        return item
      }
    })
  }

  getDisable(name) {
    this.disableSAPCode = false
    // if (this.view || (this.edit && (name == 'CustomerType' || name == 'SAP') && (this.createAcct.customerGroupId ==2 || this.createAcct.divisionId ==3))) {
    if (this.view || (this.edit && ((name == 'SAPcode' || name == 'CustomerType') || (name == 'SAP' && this.createAcct.customerGroupId != 2 && this.createAcct.divisionId != 3)))) {
      return true;
    }
    else if (!this.view && !this.edit && name == 'PINCode') {
      if (this.createAcct.divisionId == null || this.createAcct.divisionId == "") {
        return true
      }
    }
    return false;
  }

  divisionSelected(id) {
    if (id) {
      this.createAcct.parentAccountCode = "";
      this.createAcct.sapCustomerCode = "";
      this.showAbcClassificationList = true;
      this.showCustomerTypeList = true;
      // this.getABCClassification(id);
      // this.getCustomerType(id);
      const val = id ? this.divisonList.find(o => o.id == id) : {};
      this.whiteDivision = val.value ? val.value.match(/white/gi) : false;
      if (this.whiteDivision) {
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'userId',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 5,
          allowSearchFilter: true
        }
      } else {
        this.dropdownSettings = {
          singleSelection: true,
          idField: 'userId',
          textField: 'userName',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 3,
          allowSearchFilter: true
        }
      }
      // this.setSAPCode();
    }
  }

  getDepartmentList() {
    this.http.get('/ctslead/getAllDepartment').subscribe(data => {
      if (data.status == '200') {
        this.departmentList = data.data;
      }
    })
  }

  getDepartmentId(department) {
    const departName = this.departmentList.filter(ele => ele.departmentName === department)
    return departName[0].id
  }

  getCategoryId(category) {
    const categoryId = this.SiteCategory.filter(ele => ele.value.name === category)
    return categoryId[0].value.id
  }

  showApprovalsValueOnEdit = () => {
    // if(this.createAcct.event_creation_level1 || this.createAcct.event_creation_level2){
      this.filterApprovalList('event_one')('level1')('edit');
      this.filterApprovalList('event_one')('level2')('edit');
    // }
    // else if(this.createAcct.dealer_ars_change_level1 || this.createAcct.dealer_ars_change_level2){
      this.filterApprovalList('event_two')('level1')('edit');
      this.filterApprovalList('event_two')('level2')('edit');
    // }
    // else if(this.createAcct.event_date_change_level1 || this.createAcct.event_date_change_level2){
      this.filterApprovalList('event_three')('level1')('edit');
      this.filterApprovalList('event_three')('level2')('edit');
    // }
    // else if(this.createAcct.violation_of_invitee_level1 || this.createAcct.violation_of_invitee_level2){
      this.filterApprovalList('event_four')('level1')('edit');
      this.filterApprovalList('event_four')('level2')('edit');
    // }
    // else if(this.createAcct.violation_of_food_level1 || this.createAcct.violation_of_food_level2){
      this.filterApprovalList('event_five')('level1')('edit');
      this.filterApprovalList('event_five')('level2')('edit');
    // }
  }

  editAccount(user, type) {
    if(user.status==1){
    console.log(user)
    this.reset();
    this.edit = type == 'edit' ? true : false;
    this.view = type == 'view' ? true : false;
    this.dropdownSettings.disabled = this.view;
    this.spinner.show();
    // this.http.get(`/vanModule/get-van-moduleMap/${user.id}`).subscribe(rdata => {
    this.http.get(`/greyEvent/get-grey-eventType/${user.id}`).subscribe(rdata => {
      if (rdata.status === 200) {
        this.createAcct.id = user.id
        this.createAcct.eventType = rdata.data.eventType,
          this.createAcct.glAccount = rdata.data.glAccount,
          this.createAcct.orderNo = rdata.data.orderNo,
          this.createAcct.idealPax = rdata.data.idealPax,
          this.createAcct.maxPax = rdata.data.maxPax,
          this.createAcct.e_c = rdata.data.e_c,
          this.createAcct.food_per_pax = rdata.data.food_per_pax
          this.createAcct.adminPerActivity = rdata.data.adminPerActivity,
          this.createAcct.giftPerPax = rdata.data.giftPerPax,
          this.createAcct.idealBudget = rdata.data.idealBudget,
          this.createAcct.maxBudget = rdata.data.maxBudget

          this.createAcct.event_creation_level1 = rdata.data.eventCreationApprovlevelOne
          this.createAcct.event_creation_level2 = rdata.data.eventCreationApprovlevelTwo
          this.createAcct.event_creation_level3 = rdata.data.eventCreationApprovlevelThree
          this.createAcct.dealer_ars_change_level1 = rdata.data.eventArsChangeApprovOne
          this.createAcct.dealer_ars_change_level2 = rdata.data.eventArsChangeApprovTwo
          this.createAcct.dealer_ars_change_level3 = rdata.data.eventArsChangeApprovThree
          this.createAcct.event_date_change_level1 = rdata.data.eventDateChangeApprovOne
          this.createAcct.event_date_change_level2 = rdata.data.eventDateChangeApprovTwo
          this.createAcct.event_date_change_level3 = rdata.data.eventDateChangeApprovThree
          this.createAcct.violation_of_invitee_level1 = rdata.data.voilationOfInviteApprovOne
          this.createAcct.violation_of_invitee_level2 = rdata.data.voilationOfInviteApprovTwo
          this.createAcct.violation_of_invitee_level3 = rdata.data.voilationOfInviteApprovThree
          this.createAcct.violation_of_food_level1 = rdata.data.voilationOfFoodApprovOne
          this.createAcct.violation_of_food_level2 = rdata.data.voilationOfFoodApprovTwo
          this.createAcct.violation_of_food_level3 = rdata.data.voilationOfFoodApprovThree
          // this.eventPreviousState = {...this.createAcct}

          this.showApprovalsValueOnEdit();
      }
      this.spinner.hide();
      // this.getAddressByPincode(data.address.pinCode, 'edit');
      //this.stateSelected(user.state);
      // this.checkMObileNum();
      ($('#createAccount') as any).modal('show');
    })
  }else{
    Swal.fire({
      icon:"warning",
      title: "Inactive Event can't be editable.",
      // html:str,
      timer: 150000,
  });
  }
  }

  downloadFilePre() {
    try {
      window.location.href = `${environment.BASE_ENDPOINT}/greyEvent/download-file?type=Pre`;
    }catch (error) {
      console.error("Error", error);
    }
  }
  downloadFilePost() {
    try {
      window.location.href = `${environment.BASE_ENDPOINT}/greyEvent/download-file?type=Post`;
    }catch (error) {
      console.error("Error", error);
    }
  }

  closeModal(){
    this.find();
    (jQuery("#confirmationModal") as any).modal("hide");
  }

  handleStatusChecked($event,user){
    // console.log($event.target.value)
    // console.log($event.target.checked)
    this.eventId = $event.target.value;
    this.eventStatus = $event.target.checked;
    this.eventUserDetails = user;
  }
  userStatus(){
    this.spinner.show();
    let str="";
    this.http.put(`/greyEvent/update-grey-eventType/${this.eventId}`,{...this.eventUserDetails,status:this.eventStatus?1:0}).subscribe(rdata => {
      if (rdata.status === 200) {
        this.spinner.hide();
        Swal.fire({
          icon:"success",
          title: rdata.message,
          html:str,
          timer: 150000,
      });
      (jQuery("#confirmationModal") as any).modal("hide");
      this.find();
      }
      else{
        (jQuery("#confirmationModal") as any).modal("hide");
        Swal.fire({
          icon:"error",
          title: rdata.message,
          html:str,
          timer: 150000,
      });
      }
      
      // this.getAddressByPincode(data.address.pinCode, 'edit');
      //this.stateSelected(user.state);
      // this.checkMObileNum();
    })

  }
  

  // activeAccount(data:any,type:boolean){
  //   const finalData = this.usersList.filter((el)=>{
  //     return el.id==data.id
  //   })
  //   console.log(finalData)
  //     if(finalData[0].id==data.id&&type){
  //       this.activeAccountState=finalData
  //       this.activeAccountId=data.id
  //     }
  //   else{
  //     this.activeAccountState=type
  //     this.activeAccountId=data.id
  //   };
    
    
  // }
  // inActiveAccount(data:any,type:boolean){
  //   console.log("=======",data)
  //   this.activeAccountId=data.id
  //   this.activeAccountState=type
  // }

  getCustomerTypeVal() {
    const val = this.customerType.find(o => o.id == this.createAcct.customerGroupId);
    return val ? val.value.match(/Stockist/gi) : false;
  }

  getSAPCode() {
    this.spinner.show();
    this.http.get(`/account-management/get-SAPCode`).subscribe(data => {
      // if (data.status == 200) {
      this.createAcct.sapCustomerCode = data;
      // }
      this.spinner.hide();
    });
  }

  preparePager() {
    this.pager = {
      itemsPerPage: this.perPage,
      currentPage: this.pageNumber,
      totalItems: this.usersList.length
    };
  }

  setSAPCode() {
    if (this.createAcct.customerGroupId != 1 && this.createAcct.customerGroupId != null && this.createAcct.divisionId == 3) {
      this.disableSAPCode = true;
      if (this.edit == false && this.createAcct.parentAccountCode != "") {
        this.getSAPCode();
      }
    } else {
      this.disableSAPCode = false;
    }
  }

  resetStockiestSAPCodeAndSAPCode() {
    this.setSAPCode();
    this.createAcct.sapCustomerCode = "";
    this.createAcct.parentAccountCode = "";
  }

  filters() {
    const data = this.accountData;
    let filter = false;
    Object.keys(data).forEach(key => {
      if (data[key]) {
        if (Array.isArray(data[key]) && data[key].length > 0) {
          filter = true;
        }
        if (!Array.isArray(data[key])) {
          filter = true;
        }
      }
    });
    return filter;
  }

  downloadExcelFormReset() {
    this.errMsg = ''
    this.downloadExcelFilter = {
      startDate: this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'),
      endDate: this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),
    }
  }

  filterObj(fd, td) {
    let url;
    if (this.filters()) {
      let filterParams = '?';
      Object.keys(this.accountData).forEach(key => {
        if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
          if (!Array.isArray(this.accountData[key])) {
            if (key == 'divisionId') {
              filterParams += `divId=`;
            } else {
              filterParams += `${key}=`;
            }
            if (key == 'state') {
              filterParams += `${this.findstatename(this.accountData[key])}&`;
            } else {
              filterParams += `${this.accountData[key]}&`;
            }
          } else {
            if (this.accountData[key].length > 0) {
              filterParams += `primaryowner=`;
              Object.keys(this.accountData[key]).forEach(subkey => {
                filterParams += `${this.accountData[key][subkey]['userId']},`;
              });
              //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
              filterParams = filterParams.substring(0, filterParams.length - 1);
              filterParams += `&`;
            }
          }
        }
      });
      // https://umber.jkcement.com/umber-rest/site/download-site?div=3&fromDate=2022-11-08&toDate=2022-11-09&state=Haryana&district=GURGAON&city&primaryowner
      // https://umber.jkcement.com/umber-rest/vanModule/download-Van
      filterParams = filterParams.substring(0, filterParams.length - 1);
      // url = `${environment.BASE_ENDPOINT}/site/download-site${filterParams}&fromDate=${fd}&toDate=${td}`;
      url = `${environment.BASE_ENDPOINT}/vanModule/download-Van`;
      return url
    }
    else {
      // url = `${environment.BASE_ENDPOINT}/site/download-site?&fromDate=${fd}&toDate=${td}`;
      url = `${environment.BASE_ENDPOINT}/vanModule/download-Van`;
      return url
    }

  }

  downloadExcelData() {
    let fd = this.downloadExcelFilter.startDate;
    let td = this.downloadExcelFilter.endDate;
    let FD = new Date(fd);
    let TD = new Date(td);
    let no_of_days;
    try {
      if (fd !== "" && td !== "") {
        if (FD.getTime() <= TD.getTime()) {
          no_of_days = (TD.getTime() - FD.getTime()) / (1000 * 3600 * 24);
          if (no_of_days <= 30) {
            let url = this.filterObj(fd, td);
            // window.location.href = environment.BASE_ENDPOINT+"/account-management/filter-download?fromDate="+fd+"&toDate="+td+"&div="+div+"&state="+stateId[0].value+"&city="+city+"&district="+district+"&primaryowner="+primaryowner;

            window.location.href = url;

            document.getElementById("downloadExcelClose").click();
          }
          else {
            this.errMsg = "30 days limit exceed !"
          }
        }
        else {
          this.errMsg = "No records found in this time period!"
        }
      }
      else {
        this.errMsg = "All Fields are required!";
      }
    } catch (error) {
      console.error("Error", error);
    }

    // this.downloadExcelFormReset();

  }

  downloadData(value?) {
    let url;
    if (!value && this.filters()) {
      let filterParams = '?';
      Object.keys(this.accountData).forEach(key => {
        if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
          if (!Array.isArray(this.accountData[key])) {
            if (key == 'divisionId') {
              filterParams += `div=`;
            } else {
              filterParams += `${key}=`;
            }
            if (key == 'state') {
              filterParams += `${this.findstatename(this.accountData[key])}&`;
            } else {
              filterParams += `${this.accountData[key]}&`;
            }
          } else {
            if (this.accountData[key].length > 0) {
              filterParams += `primaryowner=`;
              Object.keys(this.accountData[key]).forEach(subkey => {
                filterParams += `${this.accountData[key][subkey]['userId']},`;
              });
              //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
              filterParams = filterParams.substring(0, filterParams.length - 1);
              filterParams += `&`;
            }
          }
        }
      });
      filterParams = filterParams.substring(0, filterParams.length - 1);
      url = `${environment.BASE_ENDPOINT}/account-management/filter-download${filterParams}`;
    } else {
      // https://umber.jkcement.com/umber-rest/site/site-template-download
      // url = value ? `${environment.BASE_ENDPOINT}/account-management/download?key=${value}` :
      url = value ? `${environment.BASE_ENDPOINT}/site/site-template-download` :
        `${environment.BASE_ENDPOINT}/site/site-template-download`;
    }
    window.location.href = url;
  }

  findstatename(stateid) {
    let name = '';
    Object.keys(this.stateList).forEach(key => {
      if (this.stateList[key]['id'] == stateid) {
        name = this.stateList[key]['value'];
      }
    });
    return name;
  }

  getDivisionName(id) {
    if (this.divisonList.length && id) {
      const v = this.divisonList.find(val => val.id == id);
      return v ? v.value : id;
    }
    return id;
  }

  getCustomeTypeName(id) {
    if (this.customerType.length && id) {
      const v = this.customerType.find(val => val.id == id);
      return v ? v.value : id;
    }
    return id;
  }

  onFileSelect(event, type) {
    if (event.target.files.length > 0) {
      this.spinner.show();
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      // https://umber.jkcement.com/umber-rest/site/upload-site
      this.http.post(`/site/${type}-site`, formData).subscribe((res) => {
        this.spinner.hide();
        let str = res.status === 200 ? 'File Uploaded Successfully!' : 'Something went wrong...!';
        // event.srcElement.value = null;
        // if (res.map['success']) {
        //     str += "<h4> success:" + res.map['success'] + " </h4>"
        // }
        // if (res.map['failure']) {
        //     str += "<br/><a href='" + `${environment.BASE_ENDPOINT}/account-management/download-failed` + "'><h4> failure:" + res.map['failure'] + " </h4></a>"
        // }
        Swal.fire({
          title: res.message,
          html: str,
          timer: 150000,
        });
        this.find();
      },
        (err) => {
          this.spinner.hide();
          event.srcElement.value = null;
          Swal.fire({
            title: err.error ? err.error.message : 'Error saving account',
            timer: 150000,
          });
        }
      );
    }
  }

  checkMObileNum() {
    if (String(this.createAcct.customerPhone).length > 9) {
      this.mobileError = false;
      return true;
      this.http.get(`/account-management/Check-Mob?mob=${this.createAcct.customerPhone}`).subscribe((res) => {
        const exists = 'Mobile Number Exists';
        if (res.message === exists) {
          this.mobileError = true;
        }
      });
    }
    else {
      this.mobileError = true;
      return true;
    }
  }

  getAllOwners() {
    this.spinner.show();
    this.http.get(`/account-management/get-all-primaryowner?divId=2&module=van&`).subscribe((res) => {
      this.allOwnersList = this.objectToArrayOwner(res.data["user details"]);      
      this.spinner.hide();
    });
  }

  getOwnerName(prmowner) {
    if (prmowner) {
      return prmowner.firstName + " " + prmowner.lastName + " (" + prmowner.employeeId + ")"
    }
    return '';
  }

  getOwnerTCode(id) {
    if (this.allOwnersList.length && id) {
      const arr = Array.isArray(id) ? id : id.split(',');
      const val = arr.map(o => {
        return this.allOwnersList.find(v => v.userId == o.userId) ? this.allOwnersList.find(v => v.userId == o.userId).territoryCode : o.userId;
      });
      return val;
    }
    return id;
  }

  // find(event = null) {
  //     this.spinner.show();
  //     let filterParams = '?pageNo=' + (this.page - 1) + '&pageSize=' + this.itemsPerPage + '&';
  //     Object.keys(this.accountData).forEach(key => {
  //         if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
  //             if (!Array.isArray(this.accountData[key])) {
  //                 if (key == 'divisionId') {
  //                     filterParams += `divId=`;
  //                 } else {
  //                     filterParams += `${key}=`;
  //                 }
  //                 if (key == 'state') {
  //                     // filterParams += `${this.findstatename(this.accountData[key])}&`;
  //                     filterParams += `${this.accountData[key]}&`;
  //                 } else {
  //                     filterParams += `${this.accountData[key]}&`;
  //                 }
  //                 //filterParams += `${this.accountData[key]}&`;
  //             } else {
  //                 if (this.accountData[key].length > 0) {
  //                     filterParams += `primaryowner=`;
  //                     Object.keys(this.accountData[key]).forEach(subkey => {
  //                         filterParams += `${this.accountData[key][subkey]['userId']},`;
  //                     });
  //                     //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
  //                     filterParams = filterParams.substring(0, filterParams.length - 1);
  //                     filterParams += `&`;
  //                 }
  //             }
  //         }
  //     });
  //     filterParams = filterParams.substring(0, filterParams.length - 1);
  //     this.usersList = [];
  //     this.http.get(`/site/get-all-white-site${filterParams}`).subscribe((res) => {
  //         this.usersList = res.data;
  //         this.totalItems = res.message;
  //         this.spinner.hide();
  //     });
  // }

  find(event = null) {
    this.spinner.show();
    let filterParams = '?pageNo=' + (this.page - 1) + '&pageSize=' + this.itemsPerPage + '&';
    Object.keys(this.accountData).forEach(key => {
      if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
        if (!Array.isArray(this.accountData[key])) {
          if (key == 'eventName') {
            filterParams += `eventName=`;
          } else {
            filterParams += `${key}=`;
          }
          if (key == 'state') {
            // filterParams += `${this.findstatename(this.accountData[key])}&`;
            filterParams += `${this.accountData[key]}&`;
          } else {
            filterParams += `${this.accountData[key]}&`;
          }
          //filterParams += `${this.accountData[key]}&`;
        }
        else {
          if (this.accountData[key].length > 0) {
            filterParams += `primaryowner=`;
            Object.keys(this.accountData[key]).forEach(subkey => {
              filterParams += `${this.accountData[key][subkey]['userId']},`;
            });
            //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
            filterParams = filterParams.substring(0, filterParams.length - 1);
            filterParams += `&`;
          }
        }
      }
    });
    filterParams = filterParams.substring(0, filterParams.length - 1);
    this.usersList = [];
    // console.log("filterParams",filterParams);
    this.http.get(`/greyEvent/getAllEventTypes${filterParams}`).subscribe((res) => {
    // this.http.get(`/greyEvent/getAllEventTypes`).subscribe((res) => {
      
      this.usersList = res.data.reverse();
      // this.totalItems = res.message;
      // console.log("res",this.totalItems);
      this.spinner.hide();
    });
  }



  siteFilter(event = null) {
    this.spinner.show();
    let filterParams = '?pageNo=' + (this.page - 1) + '&pageSize=' + this.itemsPerPage + '&';
    Object.keys(this.siteData).forEach(key => {
      if (this.siteData[key] || (Array.isArray(this.siteData[key]) && this.siteData[key].length)) {
        if (!Array.isArray(this.siteData[key])) {
          if (key == 'divisionId') {
            filterParams += `divId=`;
          } else {
            filterParams += `${key}=`;
          }
          if (key == 'state') {
            filterParams += `${this.findstatename(this.siteData[key])}&`;
          } else {
            filterParams += `${this.siteData[key]}&`;
          }
          //filterParams += `${this.siteData[key]}&`;
        } else {
          if (this.siteData[key].length > 0) {
            filterParams += `primaryowner=`;
            Object.keys(this.siteData[key]).forEach(subkey => {
              filterParams += `${this.siteData[key][subkey]['userId']},`;
            });
            //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
            filterParams = filterParams.substring(0, filterParams.length - 1);
            filterParams += `&`;
          }
        }
      }
    });
    filterParams = filterParams.substring(0, filterParams.length - 1);
    this.usersList = [];
    this.http.get(`/site/get-all-white-site${filterParams}`).subscribe((res) => {
      this.usersList = res.data;
      this.totalItems = res.message;
      this.spinner.hide();
    });
  }

  findFilter(event = null) {
    // this.find();
    this.siteFilter();
  }

  resetFilters(event = null) {
    this.siteData = {
      state: '',
      divisionId: '3',
      city: '',
      district: '',
      allAccountOwner: [],
    };
    this.find();
  }

  filterDropDown(type, val) {
    if (type == "edit") {
      this.CityChange(val, 'e');
      this.talukaChange(val, 'e');
      this.localityChange(val, 'e');
    }
    if (type == "dt") {
      this.CityChange(val);
      this.talukaChange(val);
      this.localityChange(val);
    }
    if (type == "ct") {
      this.talukaChange(val);
      this.localityChange(val);

    }
    if (type == "taluka") {
      this.localityChange(val);

    }

  }

  CityChange(val, type = null) {
    if (!type) {
      this.createAcct.city = '';
      this.createAcct.locality = '';
      this.createAcct.taluka = '';
    }
    if (this.createAcct.district) {
      this.ct = this.pincodedata.filter(ele => ele.districtName == this.createAcct.district);
      this.ct = [...new Map(this.ct.map(item =>
        [item.cityName, item])).values()];
      if (!type)
        this.createAcct.city = this.ct[0].cityName;
      // else
    }
  }
  talukaChange(val, type = null) {

    if (this.createAcct.city) {
      this.taluka = this.pincodedata.filter(ele => (ele.districtName == this.createAcct.district && ele.cityName == this.createAcct.city))
      this.taluka = [...new Map(this.taluka.map(item =>
        [item.taluka, item])).values()];
      if (!type)
        this.createAcct.taluka = this.taluka[0].taluka;
    }
  }
  localityChange(val, type = null) {

    if (this.createAcct.taluka) {
      this.locality = this.pincodedata.filter(ele => (ele.districtName == this.createAcct.district && ele.cityName == this.createAcct.city && ele.taluka == this.createAcct.taluka))
      this.locality = [...new Map(this.locality.map(item =>
        [item.locality, item])).values()];
      if (!type)
        this.createAcct.locality = this.locality[0].locality;
    }
  }

  getAddressByPincode(pincode, edituser = null) {
    if (pincode && String(pincode).length === 6 && (this.oldPincode != pincode) && (this.createAcct.divisionId = "3")) {
      this.spinner.show();
      this.oldPincode = pincode;
      // this.http.get(`/geography-master/get-by-pin-code/${this.createAcct.address.pinCode}`).subscribe((data) => {
      this.http.get(`/geography-master/get-by-pin-code/${pincode}/${this.createAcct.divisionId}`).subscribe((data) => {
        this.spinner.hide();
        if (data.status == '200') {
          if (data.data.length == 0) {
            this.pincodeError = 'Pincode Is Incorrect';
          }
          else {
            this.pincodeError = '';
            // this.pincodedata = data.data;
            this.pincodedata = data.data;
            this.dt = [...new Map(this.pincodedata.map(item =>
              [item.districtName, item])).values()];

            this.ct = [...new Map(this.pincodedata.map(item =>
              [item.cityName, item])).values()];

            this.taluka = [...new Map(this.pincodedata.map(item =>
              [item.taluka, item])).values()];

            this.locality = [...new Map(this.pincodedata.map(item =>
              [item.locality, item])).values()];

            if (!edituser) {
              //this.createAcct.address.pincode = data.data[0].pinCode;
              //this.createAcct.address = data.data[0];
              // this.createAcct.address.pinCode = data.data[0].pinCode;
              this.createAcct.state = data.data[0].stateName;
              // this.stateSelected(data.data[0].stateCode);
              this.createAcct.city = data.data[0].cityName;
              this.createAcct.district = data.data[0].districtName;
              this.createAcct.status = 1;
              // this.createAcct.locality = data.data[0].locality;
              // this.createAcct.taluka = data.data[0].taluka;
              // this.createAcct.geographyMasterId = data.data[0].id;
            }

            //this.filterDropDown('dt',this.createAcct.address.district);
            //this.filterDropDown('ct',this.createAcct.address.city);
            //this.filterDropDown('taluka',this.createAcct.address.taluka);


            if (edituser) {
              this.createAcct.state = this.createAcct.state;
              // this.stateSelected(data.data[0].stateCode);
              this.createAcct.district = this.createAcct.district;
              // this.createAcct.address.status = 1;

              this.createAcct.city = this.createAcct.city;
              // this.createAcct.locality = this.createAcct.locality;
              // this.createAcct.taluka = this.createAcct.taluka;
              this.filterDropDown('edit', this.createAcct.district);

            }

            let val = data.data[0].stateCode > 9 ? data.data[0].stateCode : '0' + data.data[0].stateCode;
            this.getOwnerByState(val);
          }
        }
      });
    }
  }

  getCheckSapCode(sapcode) {
    if (sapcode && String(sapcode).length >= 6) {
      this.spinner.show();
      this.http.get(`/account-management/Check-SAPCode?sapCode=${sapcode}`).subscribe((data) => {
        this.spinner.hide();
        if (data.message == 'SAP Code does not Exists') {
          this.sapcodeError = 'SAP Code does not Exists';
          this.sapcodeVError = true;
        } else {
          this.sapcodeError = "";
          this.setSAPCode();
          this.sapcodeVError = false;
        }
      });
    }
  }

  onKeySearch(event: any) {
    this.page = 1;
    if (event.keyCode == 13) {
      this.find();
      this.timeout = null;
      return;
    }
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.find();
      }
    }, 1000);
  }

  transferInfluencer() {
    if (this.transferList.createdBy !== "" || this.transferList.createdBy === null || this.transferList.createdBy === undefined) {
      this.spinner.show();
      this.http.put(`/site/transfer-site?ids=${this.transferList.ids}&createdBy=${this.transferList.createdBy}`).subscribe((data) => {
        this.spinner.hide();
        if (data.status === 200) {
          this.transferList.createdBy = ""
          Swal.fire({
            icon: "success",
            title: data.message,
            timer: 150000,
          });
        }
      });
      (jQuery("#influencerTransfer") as any).modal("hide");
    }
  }

  addInfluencerInTheList(event, user) {
    if (this.transferList.ids.length == 0) {
      this.transferList.ids.push(user.id);
    }
    else if (this.transferList.ids.length > 0) {
      let exist = this.transferList.ids.filter(ele => ele === user.id);
      if (!exist[0]) {
        this.transferList.ids.push(user.id);
      }
      else {
        let indx = this.transferList.ids.indexOf(exist[0]);
        this.transferList.ids.splice(indx, 1)
      }

    }
  }

  getTransferUserList() {
    this.spinner.show();
    this.http.get("/ctswhiteinfluencer/get-transfer-user").subscribe(data => {
      this.spinner.hide();
      if (data.status == 200) {
        this.userlist = this.objectToArrayForTransferUser(data.data);
        this.transferUserList = this.objectToArray(data.data);

      }
    });
  }

  openTransfer() {
    if (this.transferList.ids.length < 1) {
      Swal.fire({
        icon: "error",
        title: "Please select the site!",
        timer: 150000,
      });
    }
    else {
      // Swal.fire({
      //     icon:"success",
      //     title: "Transferred Successfully!",
      //     timer: 150000,
      // });
      (jQuery("#influencerTransfer") as any).modal("show");
    }
  }

  getVanTripDetail(reg_num){
    this.route.navigate(['/umber/van/trip-details',{reg_no:reg_num}]);
  }

  checkMappedExecutive(primaryUser){
    this.spinner.show();
    this.http.get(`/vanModule/getAll-moduleMap/${primaryUser.userId}`).subscribe(data => {
      if (data.status == 200 && data.message === "Van fetched Successfully") {
        this.isVanExist = "Van already exist.";
      }
      else if(data.status == 404 && data.message === "Van Not Found"){
        this.isVanExist = "";
      }
      this.spinner.hide();
    });
  }

  onItemDeSelect(event){
    this.isVanExist = "";
  }

  openEventForm(){
    this.createAcct.event_creation_level2_disable = true;
    this.createAcct.event_creation_level3_disable = true;
    this.createAcct.dealer_ars_change_level2_disable = true;
    this.createAcct.dealer_ars_change_level3_disable = true;
    this.createAcct.event_date_change_level2_disable = true;
    this.createAcct.event_date_change_level3_disable = true;
    this.createAcct.violation_of_invitee_level2_disable = true;
    this.createAcct.violation_of_invitee_level3_disable = true;
    this.createAcct.violation_of_food_level2_disable = true;
    this.createAcct.violation_of_food_level3_disable = true;
    (jQuery("#createAccount") as any).modal("show");
  }
  
  filterApprovalList = (tabname) => (levelname) => (type="") =>{
    switch (tabname) {
      case 'event_one':
        if(levelname === 'level1'){
          if(this.createAcct.event_creation_level1 !== "NA"){
            this.createAcct.event_creation_level2 = type == 'edit' ? this.createAcct.event_creation_level2 : "";
            this.createAcct.event_creation_level3 = type == 'edit' ? this.createAcct.event_creation_level3 : "";
            this.createAcct.event_creation_level2_disable = false;
            this.createAcct.event_creation_level3_disable = true;
            this.createAcct.event_creation_list_level2 = this.approvalList.filter(ele => ele.id > this.createAcct.event_creation_level1);
          }
          else{
            this.createAcct.event_creation_level2_disable = true;
            this.createAcct.event_creation_level3_disable = true;
            this.createAcct.event_creation_level2 = "NA";
            this.createAcct.event_creation_level3 = "NA";
          }
        }
        else if(levelname === 'level2'){
          if(this.createAcct.event_creation_level2 !== "NA"){
            this.createAcct.event_creation_level3 = type == 'edit' ? this.createAcct.event_creation_level3 : "";
            this.createAcct.event_creation_level3_disable = false;
            this.createAcct.event_creation_list_level3 = this.approvalList.filter(ele => ele.id > this.createAcct.event_creation_level2);
          }
          else{
            this.createAcct.event_creation_level3_disable = true;
            this.createAcct.event_creation_level3 = "NA";
          }
        }
        break;

      case 'event_two':
        if(levelname === 'level1'){
          if(this.createAcct.dealer_ars_change_level1 !== "NA"){
            this.createAcct.dealer_ars_change_level2 = type == 'edit' ? this.createAcct.dealer_ars_change_level2 : "";
            this.createAcct.dealer_ars_change_level3 = type == 'edit' ? this.createAcct.dealer_ars_change_level3 : "";
            this.createAcct.dealer_ars_change_level2_disable = false;
            this.createAcct.dealer_ars_change_level3_disable = true;
            this.createAcct.dealer_ars_change_list_level2 = this.approvalList.filter(ele => ele.id > this.createAcct.dealer_ars_change_level1);
          }
          else{
            this.createAcct.dealer_ars_change_level2_disable = true;
            this.createAcct.dealer_ars_change_level3_disable = true;
            this.createAcct.dealer_ars_change_level2 = "NA";
            this.createAcct.dealer_ars_change_level3 = "NA";
          } 
        }
        else if(levelname === 'level2'){
          if(this.createAcct.dealer_ars_change_level2 !== "NA"){
            this.createAcct.dealer_ars_change_level3 = type == 'edit' ? this.createAcct.dealer_ars_change_level3 : "";
            this.createAcct.dealer_ars_change_level3_disable = false;
            this.createAcct.dealer_ars_change_list_level3 = this.approvalList.filter(ele => ele.id > this.createAcct.dealer_ars_change_level2);
          }
          else{
            this.createAcct.dealer_ars_change_level3_disable = true;
            this.createAcct.dealer_ars_change_level3 = "NA";
          }
        }
        break;
    
      case 'event_three':
        if(levelname === 'level1'){
          if(this.createAcct.event_date_change_level1 !== "NA"){
            this.createAcct.event_date_change_level2 = type == 'edit' ? this.createAcct.event_date_change_level2 : "";
            this.createAcct.event_date_change_level3 = type == 'edit' ? this.createAcct.event_date_change_level3 : "";
            this.createAcct.event_date_change_level2_disable = false;
            this.createAcct.event_date_change_level3_disable = true;
            this.createAcct.event_date_change_list_level2 = this.approvalList.filter(ele => ele.id > this.createAcct.event_date_change_level1);
          }
          else{
            this.createAcct.event_date_change_level2_disable = true;
            this.createAcct.event_date_change_level3_disable = true;
            this.createAcct.event_date_change_level2 = "NA";
            this.createAcct.event_date_change_level3 = "NA";
          }
        }
        else if(levelname === 'level2'){
          if(this.createAcct.event_date_change_level2 !== "NA"){
            this.createAcct.event_date_change_level3 = type == 'edit' ? this.createAcct.event_date_change_level3 : "";
            this.createAcct.event_date_change_level3_disable = false;
            this.createAcct.event_date_change_list_level3 = this.approvalList.filter(ele => ele.id > this.createAcct.event_date_change_level2);
          }
          else{
            this.createAcct.event_date_change_level3_disable = true;
            this.createAcct.event_date_change_level3 = "NA";
          }  
        }
        break;
    
      case 'event_four':
        if(levelname === 'level1'){
          if(this.createAcct.violation_of_invitee_level1 !== "NA"){
            this.createAcct.violation_of_invitee_level2 = type == 'edit' ? this.createAcct.violation_of_invitee_level2 : "";
            this.createAcct.violation_of_invitee_level3 = type == 'edit' ? this.createAcct.violation_of_invitee_level3 : "";
            this.createAcct.violation_of_invitee_level2_disable = false;
            this.createAcct.violation_of_invitee_level3_disable = true;
            this.createAcct.violation_of_invitee_list_level2 = this.approvalList.filter(ele => ele.id > this.createAcct.violation_of_invitee_level1);
          }
          else{
            this.createAcct.violation_of_invitee_level2_disable = true;
            this.createAcct.violation_of_invitee_level3_disable = true;
            this.createAcct.violation_of_invitee_level2 = "NA" ;
            this.createAcct.violation_of_invitee_level3 = "NA" ;
          }
        }
        else if(levelname === 'level2'){
          if(this.createAcct.violation_of_invitee_level2 !== "NA"){
            this.createAcct.violation_of_invitee_level3 = type == 'edit' ? this.createAcct.violation_of_invitee_level3 : "";
            this.createAcct.violation_of_invitee_level3_disable = false;
            this.createAcct.violation_of_invitee_list_level3 = this.approvalList.filter(ele => ele.id > this.createAcct.violation_of_invitee_level2);
          }
          else{
            this.createAcct.violation_of_invitee_level3_disable = true;
            this.createAcct.violation_of_invitee_level3 = "NA" ;
          } 
        }
        break;
    
      case 'event_five':
        if(levelname === 'level1'){
          if(this.createAcct.violation_of_food_level1 !== "NA"){
            this.createAcct.violation_of_food_level2 = type == 'edit' ? this.createAcct.violation_of_food_level2 : "";
            this.createAcct.violation_of_food_level3 = type == 'edit' ? this.createAcct.violation_of_food_level3 : "";
            this.createAcct.violation_of_food_level2_disable = false;
            this.createAcct.violation_of_food_level3_disable = true;
            this.createAcct.violation_of_food_list_level2 = this.approvalList.filter(ele => ele.id > this.createAcct.violation_of_food_level1)
          }
          else{
            this.createAcct.violation_of_food_level2_disable = true;
            this.createAcct.violation_of_food_level3_disable = true;
            this.createAcct.violation_of_food_level2 = "NA";
            this.createAcct.violation_of_food_level3 = "NA";
          }
        }
        else if(levelname === 'level2'){
          if(this.createAcct.violation_of_food_level2 !== "NA"){
            this.createAcct.violation_of_food_level3 = type == 'edit' ? this.createAcct.violation_of_food_level3 : "";
            this.createAcct.violation_of_food_level3_disable = false;
            this.createAcct.violation_of_food_list_level3 = this.approvalList.filter(ele => ele.id > this.createAcct.violation_of_food_level2);
          }
          else{
            this.createAcct.violation_of_food_level3_disable = true;
            this.createAcct.violation_of_food_level3 = "NA";
          }    
        }
        break;
    
      default:
        break;
    }
  }

}

