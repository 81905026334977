import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GolbalserviceService {

public username;
  
  constructor() { }

 name=new Subject<String>();

 public setName(name){
    this.name.next(name);
 }



}
