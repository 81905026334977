import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ParameterWeightageComponent } from '../../parameter-weightage/parameter-weightage.component';
import { PriortizationComponent } from '../priortization.component';
import { ScoreFormulaComponent } from '../../score-formula/score-formula.component';
import { PriortizationLogicComponent } from '../../priortization-logic/priortization-logic.component';

const routes: Routes = [
  {path: 'lead', component: PriortizationComponent },
  {path: 'lead/score-formula', component: ScoreFormulaComponent },
  {path: 'lead/parameter-weightage-percentage', component: ParameterWeightageComponent },
  {path: 'lead/priortization-logic', component: PriortizationLogicComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrioritizationRoutingModule { }
