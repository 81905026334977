import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClientService } from '../http-client-service.service';

@Component({
  selector: 'app-cts-white-report',
  templateUrl: './cts-white-report.component.html',
  styleUrls: ['./cts-white-report.component.css']
})
export class CtsWhiteReportComponent implements OnInit {

  // BASE_ENDPOINT = environment.BASE_ENDPOINT;

  constructor(private http: HttpClientService) { }

  ngOnInit() {
    
    
    
  }

  downloadReports(buttonClicked){
    if(buttonClicked == 'Event'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-event`);
    }
    else if(buttonClicked == 'Influencer'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-influncer`);
    }
    else if(buttonClicked == 'Lead'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-lead`);
    }
    else if(buttonClicked == 'Ticket'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-ticket`);
    }
    else if(buttonClicked == 'Visit'){
      this.getReports(`${environment.BASE_ENDPOINT}/white-reports/download-report-visit`);
    }
  } 

  getReports(URL){
    window.location.href = URL;
    //window.open(URL);
  }

}
