import { Component, OnInit } from "@angular/core";
import { HttpClientService } from "../http-client-service.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { NgForm } from "@angular/forms";
import Swal from "sweetalert2";
declare var jQuery: any;

@Component({
    selector: "app-cts-kyc-viewaccount",
    templateUrl: "./cts-kyc-viewaccount.component.html",
    styleUrls: ["./cts-kyc-viewaccount.component.css"],
})
export class CtsKycViewaccountComponent implements OnInit {
    accountDetaildata;
    pincodeDetaildata;
    kycDocumentList;
    kycDocumentListApproved = [];
    kycDocumentListRejected = [];
    showKycDtails = false;
    showKycDocList = false;
    responseDocumentsDetails;
    documentType;
    filesForUpload = [];
    kycStatus;
    includesGST: boolean = false;
    uploadPAN: boolean = false;
    uploadAADHAR: boolean = false;
    uploadCheque: boolean = false;
    uploadAddress: boolean = false;
    uploadGst: boolean = false;
    enableInput: boolean = false;
    // docType: string = "";
    documentTypeOption: any = [];

    BASE_ENDPOINT = environment.BASE_ENDPOINT;

    constructor(private http: HttpClient, private https: HttpClientService) {}

    updateDcoumentDetailsModel = {
        aadharNum: "",
        accNum: "",
        accountId: "",
        bankName: "",
        cheqNum: "",
        createdOn: "",
        fileId: "",
        gstNum: "",
        id: "",
        ifscCode: "",
        lastUpdated: "",
        panNum: "",
        shopAdd: "",
        tempAccId: "",
    };

    uploadDcoumentDetailsModel = {
        panNum: "",
        gstNum: "",
        shopAdd: "",
        aadharNum: "",
        accNum: "",
        bankName: "",
        cheqNum: "",
        ifscCode: "",
        file: "",
        accountId: "",
        doctype: "",
        fileid: "",
        uploadType: "",
    };

    ngOnInit() {
        this.kycStatus = sessionStorage.getItem("kyc-status");
        this.getAccountDetail();
        this.getKycDetail();
    }

    getAccountDetail() {
        this.https
            .get(`/kyc/get-account/${sessionStorage.getItem("view-account")}`)
            .subscribe((res) => {
                this.accountDetaildata = res;
                this.accountDetaildata = this.accountDetaildata.data;
                this.showKycDtails = true;
                // this.getLocalityByPincode(this.accountDetaildata.address.pinCode);
            });
    }

    getKycDetail() {
        let kycPendingVerification = [];
        this.https
            .get(`/kyc/getDoc/${sessionStorage.getItem("view-account")}`)
            .subscribe((res) => {
                this.kycDocumentList = res;
                this.kycDocumentList = this.kycDocumentList.data;
                kycPendingVerification = this.kycDocumentList.filter(
                    (item) => item.approved != "0"
                );

                this.kycDocumentListApproved = [];
                this.kycDocumentListRejected = [];
                // this.getsortData(this.kycDocumentList)
                this.kycDocumentList.forEach((element) => {
                    if (element.docTypeId == "gst") {
                        this.includesGST = true;
                    }
                    if (element.active == "1") {
                        this.kycDocumentListApproved.push(element);
                    }
                    if (element.active == "0") {
                        this.kycDocumentListRejected.push(element);
                    }
                });
                const gstKycVerifiedList = this.kycDocumentListApproved.filter(
                    (ele) => ele.docTypeId == "gst"
                );
                const chequeKycVerifiedList =
                    this.kycDocumentListApproved.filter(
                        (ele) => ele.docTypeId == "cheque"
                    );
                const aadharKycVerifiedList =
                    this.kycDocumentListApproved.filter(
                        (ele) => ele.docTypeId == "aadhar"
                    );
                const addressKycVerifiedList =
                    this.kycDocumentListApproved.filter(
                        (ele) => ele.docTypeId == "address"
                    );

                if (
                    this.kycDocumentList.length > 0 &&
                    kycPendingVerification.length != 0
                ) {
                    if (
                        gstKycVerifiedList.length != 0 &&
                        chequeKycVerifiedList.length != 0
                    ) {
                        this.kycStatus = "KYC Verified";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    } else if (
                        chequeKycVerifiedList.length != 0 &&
                        aadharKycVerifiedList.length != 0 &&
                        addressKycVerifiedList.length != 0
                    ) {
                        this.kycStatus = "KYC Verified";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    } /*(gstKycVerifiedList.length == 0 || chequeKycVerifiedList.length == 0 || aadharKycVerifiedList.length == 0 || addressKycVerifiedList.length == 0)*/ else {
                        this.kycStatus = "KYC Partial Verified";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    }
                } else {
                    if (this.kycDocumentList.length == 0) {
                        this.kycStatus = "Pending";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    } else {
                        this.kycStatus = "KYC pending for verification";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    }
                }

                this.getsortData(this.kycDocumentListApproved);
                this.getsortData(this.kycDocumentListRejected);
                this.showKycDocList = true;
            });
    }

    getsortData(array) {
        return array.sort((a, b) => {
            return <any>new Date(b.createdOn) - <any>new Date(a.createdOn);
        });
    }

    objectToArray(obj) {
        const arr = [];
        Object.keys(obj).forEach((key) => {
            arr.push({
                id: key,
                value: obj[key],
            });
        });
        return arr;
    }

    resestAssignLeadForm(assignLeadForm: NgForm) {
        assignLeadForm.resetForm();
    }

    resestUploadDocForm(uploadForm: NgForm) {
        uploadForm.resetForm();
        this.uploadDcoumentDetailsModel.fileid = "";
        this.uploadPAN = false;
        this.uploadGst = false;
        this.uploadAddress = false;
        this.uploadCheque = false;
        this.uploadAADHAR = false;
        this.filesForUpload = [];
    }

    deleteDocument(document) {
        this.https
            .get(`/kyc/delete-doc-web?docId=${document.id}`)
            .subscribe((res) => {
                if (res.status == 200) {
                    Swal.fire({
                        title: "Document deleted successfully",
                        timer: 2000,
                    });
                    this.getKycDetail();
                    const KycVerifiedList = this.kycDocumentListApproved.filter(
                        (ele) => ele.id != document.id
                    );
                    const gstKycVerifiedList = KycVerifiedList.filter(
                        (ele) => ele.docTypeId == "gst"
                    );
                    const chequeKycVerifiedList = KycVerifiedList.filter(
                        (ele) => ele.docTypeId == "cheque"
                    );
                    const aadharKycVerifiedList = KycVerifiedList.filter(
                        (ele) => ele.docTypeId == "aadhar"
                    );
                    const addressKycVerifiedList = KycVerifiedList.filter(
                        (ele) => ele.docTypeId == "address"
                    );

                    if (
                        gstKycVerifiedList.length != 0 &&
                        chequeKycVerifiedList.length != 0
                    ) {
                        this.kycStatus = "KYC Verified";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    } else if (
                        chequeKycVerifiedList.length != 0 &&
                        aadharKycVerifiedList.length != 0 &&
                        addressKycVerifiedList.length != 0
                    ) {
                        this.kycStatus = "KYC Verified";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    } else if (
                        gstKycVerifiedList.length == 0 ||
                        chequeKycVerifiedList.length == 0 ||
                        aadharKycVerifiedList.length == 0 ||
                        addressKycVerifiedList.length == 0
                    ) {
                        this.kycStatus = "KYC Partial Verified";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    } else {
                        this.kycStatus = "KYC pending for verification";
                        sessionStorage.setItem("kyc-status", this.kycStatus);
                    }
                }
            });
    }

    dropped(files) {
        let fileName = files[0].name;
        let fileExt = fileName.substr(fileName.lastIndexOf(".") + 1);
        if (files[0].size > 5000000) {
            alert("Document size should be less than 5MB.");
            return;
        }
        // if (fileExt != 'jpg' || fileExt != 'jpeg' || fileExt != 'png') {
        //   alert('Please upload file as JPG/JPEG/PNG.');
        //   return;
        // }
        let newFileList = Array.from(files);

        this.filesForUpload = [];
        newFileList.forEach((file) => {
            this.filesForUpload.push(file);
        });
    }

    setDataOnUpdateModal(id) {
        // let accountId = sessionStorage.getItem('view-account');
        this.updateDcoumentDetailsModel.accountId =
            sessionStorage.getItem("view-account");
        // this.updateDcoumentDetailsModel.id = id;
        // this.https.get(`/kyc/getDoc/${sessionStorage.getItem('view-account')}`).subscribe((res) => {
        //   this.responseDocumentsDetails = res.data;
        //   this.responseDocumentsDetails.forEach(element => {
        //     if (id == element.id) {
        //       this.documentType = element.docTypeId;
        this.patchDataOnUpdateForm(id);
        //     }
        //   });
        // });
    }

    patchDataOnUpdateForm(element) {
        console.log(element);
        this.documentType = element.docTypeId;
        this.kycDocumentListApproved.forEach((ele) => {
            if (ele.docTypeId == this.documentType) {
                if (ele.kycDocDetails != null && ele.id == element.id) {
                    this.updateDcoumentDetailsModel.aadharNum = null
                        ? ""
                        : ele.kycDocDetails.aadharNum;
                    this.updateDcoumentDetailsModel.accNum = null
                        ? ""
                        : ele.kycDocDetails.accNum;
                    this.updateDcoumentDetailsModel.bankName = null
                        ? ""
                        : ele.kycDocDetails.bankName;
                    this.updateDcoumentDetailsModel.cheqNum = null
                        ? ""
                        : ele.kycDocDetails.cheqNum;
                    this.updateDcoumentDetailsModel.gstNum = null
                        ? ""
                        : ele.kycDocDetails.gstNum;
                    this.updateDcoumentDetailsModel.panNum = null
                        ? ""
                        : ele.kycDocDetails.panNum;
                    this.updateDcoumentDetailsModel.ifscCode = null
                        ? ""
                        : ele.kycDocDetails.ifscCode;
                    this.updateDcoumentDetailsModel.shopAdd = null
                        ? ""
                        : ele.kycDocDetails.shopAdd;
                    this.updateDcoumentDetailsModel.lastUpdated = null
                        ? ""
                        : ele.kycDocDetails.lastUpdated;
                    this.updateDcoumentDetailsModel.createdOn = null
                        ? ""
                        : ele.kycDocDetails.createdOn;
                    this.updateDcoumentDetailsModel.fileId = null
                        ? ""
                        : ele.kycDocDetails.fileId;
                    this.updateDcoumentDetailsModel.id = null
                        ? ""
                        : ele.kycDocDetails.id;
                }
            }
        });
        // if (element.kycDocDetails == null)
        //   return;
    }

    downloadAllDocuments(sapCode) {
        let NewArray = [];
        let totalParam;
        this.kycDocumentListApproved.forEach((element, index) => {
            let requestParam = element.path.split("/KYC/");
            let allParams = "name=" + requestParam[1];

            if (index == 0) {
                let Param = "?" + allParams;
                NewArray.push(Param);
            } else {
                let Param = "&" + allParams;
                NewArray.push(Param);
            }
        });
        totalParam = NewArray.join("");
        let apiEndPoint = this.BASE_ENDPOINT;
        let URL =
            apiEndPoint +
            "/kyc/zip-download-new" +
            totalParam +
            "&sapCode=" +
            sapCode;
        window.location.href = URL;
    }

    downloadDocument(url) {
        let URL = url;
        window.open(URL);
        // window.location.href = URL;
    }

    uploadDOcumentSubmit(uploadDocForm: NgForm) {
        if (
            this.uploadDcoumentDetailsModel.uploadType == "" ||
            this.uploadDcoumentDetailsModel.uploadType == null ||
            this.uploadDcoumentDetailsModel.uploadType == "0"
        ) {
            alert("Upload Type is required.");
            return;
        }
        if (
            this.uploadDcoumentDetailsModel.doctype == "" ||
            this.uploadDcoumentDetailsModel.doctype == null ||
            this.uploadDcoumentDetailsModel.doctype == "0"
        ) {
            alert("Document Type is required.");
            return;
        }
        if (this.filesForUpload.length == 0) {
            alert("Document is required.");
            return;
        }

        if (this.uploadPAN) {
            if (
                this.uploadDcoumentDetailsModel.panNum == null ||
                this.uploadDcoumentDetailsModel.panNum == ""
            ) {
                alert("PAN number is required.");
                return;
            }
            if (this.uploadDcoumentDetailsModel.panNum.length != 10) {
                alert("PAN number must be of 10 digit.");
                return;
            }
        }
        if (this.uploadGst) {
            if (
                this.uploadDcoumentDetailsModel.gstNum == null ||
                this.uploadDcoumentDetailsModel.gstNum == ""
            ) {
                alert("Gst Number is required.");
                return;
            }
            if (this.uploadDcoumentDetailsModel.gstNum.length != 15) {
                alert("Gst Number must be of 15 digit.");
                return;
            }
        }
        if (this.uploadAADHAR) {
            if (
                this.uploadDcoumentDetailsModel.aadharNum == null ||
                this.uploadDcoumentDetailsModel.aadharNum == ""
            ) {
                alert("Aadhar number is required.");
                return;
            }
            if (this.uploadDcoumentDetailsModel.aadharNum.length != 14) {
                alert("Aadhar number must be of 14 digit.");
                return;
            }
        }
        if (this.uploadAddress) {
            if (
                this.uploadDcoumentDetailsModel.shopAdd == null ||
                this.uploadDcoumentDetailsModel.shopAdd == ""
            ) {
                alert("Shop address is required.");
                return;
            }
        }
        if (this.uploadCheque) {
            if (
                this.uploadDcoumentDetailsModel.cheqNum == null ||
                this.uploadDcoumentDetailsModel.cheqNum == ""
            ) {
                alert("Cheque number is required.");
                return;
            }
            if (this.uploadDcoumentDetailsModel.cheqNum.length != 6) {
                alert("Cheque number must be of 6 digit.");
                return;
            }
            if (
                this.uploadDcoumentDetailsModel.bankName == null ||
                this.uploadDcoumentDetailsModel.bankName == ""
            ) {
                alert("Bank name is required.");
                return;
            }
            if (
                this.uploadDcoumentDetailsModel.accNum == null ||
                this.uploadDcoumentDetailsModel.accNum == ""
            ) {
                alert("Account number is required.");
                return;
            }
            if (
                this.uploadDcoumentDetailsModel.ifscCode == null ||
                this.uploadDcoumentDetailsModel.ifscCode == ""
            ) {
                alert("Ifsc code is required.");
                return;
            }
        }

        // return;
        const formData = new FormData();
        formData.set("file", this.filesForUpload[0]);
        formData.set("accountId", this.uploadDcoumentDetailsModel.accountId);
        formData.set("doctype", this.uploadDcoumentDetailsModel.doctype);
        formData.set("aadharNum", this.uploadDcoumentDetailsModel.aadharNum);
        formData.set("panNum", this.uploadDcoumentDetailsModel.panNum);
        formData.set("gstNum", this.uploadDcoumentDetailsModel.gstNum);
        formData.set("shopAdd", this.uploadDcoumentDetailsModel.shopAdd);
        formData.set("cheqNum", this.uploadDcoumentDetailsModel.cheqNum);
        formData.set(
            "bankName",
            this.uploadDcoumentDetailsModel.bankName.toUpperCase()
        );
        formData.set(
            "accNum",
            this.uploadDcoumentDetailsModel.accNum.toUpperCase()
        );
        formData.set(
            "ifscCode",
            this.uploadDcoumentDetailsModel.ifscCode.toUpperCase()
        );
        formData.set("uploadType", this.uploadDcoumentDetailsModel.uploadType);

        // let header = new HttpHeaders();
        // header = header.append('content-type', 'multipart/form-data');

        this.https
            .postFile("/file/retailerKycDocNewWeb", formData)
            .subscribe((res) => {
                if (res.status == 200) {
                    jQuery("#uploadDocModal").modal("hide");
                    Swal.fire({
                        title: "Document uploaded successfully",
                        timer: 2000,
                    });
                    uploadDocForm.resetForm();
                    this.uploadDcoumentDetailsModel.fileid = "";
                    this.getKycDetail();
                }
            });
    }

    onChangeUploadType(event) {
          this.documentTypeOption = [];
          this.uploadDcoumentDetailsModel.doctype = "0";
          this.uploadPAN = false;
          this.uploadGst = false;
          this.uploadAddress = false;
          this.uploadCheque = false;
          this.uploadAADHAR = false;
        if (event == "new") {
            this.enableInput = false;
            this.documentTypeOption = [
                { name: "Aadhar", value: "aadhar" },
                { name: "Address", value: "address" },
                { name: "Cheque", value: "cheque" },
                { name: "GST", value: "gst" },
                { name: "PAN", value: "pan" },
            ];
            // this.onChangeDocumentType(this.docType);
        }
        if (event == "update") {
            this.enableInput = false;
            // this.onChangeDocumentType(this.docType);
            this.kycDocumentListApproved.map((el) => {
                this.documentTypeOption.push({
                    name:
                        el.docTypeId.charAt(0).toUpperCase() +
                        el.docTypeId.slice(1),
                    value: el.docTypeId,
                });
            });
        }
    }

    onChangeDocumentType(event) {
        // this.docType = event
        // if (this.enableInput == true) {
        if (event == "pan") {
            this.uploadPAN = true;
            this.uploadGst = false;
            this.uploadAddress = false;
            this.uploadCheque = false;
            this.uploadAADHAR = false;
            this.kycDocumentListApproved.forEach((element) => {
                if (element.docTypeId == "pan") {
                    if (element.kycDocDetails != null) {
                        this.uploadDcoumentDetailsModel.panNum =
                            this.uploadDcoumentDetailsModel.uploadType !== "new"
                                ? element.kycDocDetails.panNum
                                : "";
                        this.uploadDcoumentDetailsModel.cheqNum = "";
                        this.uploadDcoumentDetailsModel.bankName = "";
                        this.uploadDcoumentDetailsModel.accNum = "";
                        this.uploadDcoumentDetailsModel.ifscCode = "";
                        this.uploadDcoumentDetailsModel.shopAdd = "";
                        this.uploadDcoumentDetailsModel.aadharNum = "";
                        this.uploadDcoumentDetailsModel.gstNum = "";
                    }
                }
            });
        }
        if (event == "gst") {
            this.uploadPAN = false;
            this.uploadGst = true;
            this.uploadAddress = false;
            this.uploadCheque = false;
            this.uploadAADHAR = false;
            this.kycDocumentListApproved.forEach((element) => {
                if (element.docTypeId == "gst") {
                    if (element.kycDocDetails != null) {
                        this.uploadDcoumentDetailsModel.gstNum =
                            this.uploadDcoumentDetailsModel.uploadType !== "new"
                                ? element.kycDocDetails.gstNum
                                : "";
                        this.uploadDcoumentDetailsModel.cheqNum = "";
                        this.uploadDcoumentDetailsModel.bankName = "";
                        this.uploadDcoumentDetailsModel.accNum = "";
                        this.uploadDcoumentDetailsModel.ifscCode = "";
                        this.uploadDcoumentDetailsModel.panNum = "";
                        this.uploadDcoumentDetailsModel.shopAdd = "";
                        this.uploadDcoumentDetailsModel.aadharNum = "";
                    }
                }
            });
        }
        if (event == "aadhar") {
            this.uploadPAN = false;
            this.uploadGst = false;
            this.uploadAddress = false;
            this.uploadCheque = false;
            this.uploadAADHAR = true;
            this.kycDocumentListApproved.forEach((element) => {
                if (element.docTypeId == "aadhar") {
                    if (element.kycDocDetails != null) {
                        this.uploadDcoumentDetailsModel.aadharNum =
                            this.uploadDcoumentDetailsModel.uploadType !== "new"
                                ? element.kycDocDetails.aadharNum
                                : "";
                        this.uploadDcoumentDetailsModel.cheqNum = "";
                        this.uploadDcoumentDetailsModel.bankName = "";
                        this.uploadDcoumentDetailsModel.accNum = "";
                        this.uploadDcoumentDetailsModel.ifscCode = "";
                        this.uploadDcoumentDetailsModel.panNum = "";
                        this.uploadDcoumentDetailsModel.shopAdd = "";
                        this.uploadDcoumentDetailsModel.gstNum = "";
                    }
                }
            });
        }
        if (event == "address") {
            this.uploadPAN = false;
            this.uploadGst = false;
            this.uploadAddress = true;
            this.uploadCheque = false;
            this.uploadAADHAR = false;
            this.kycDocumentListApproved.forEach((element) => {
                if (element.docTypeId == "address") {
                    if (element.kycDocDetails != null) {
                        this.uploadDcoumentDetailsModel.shopAdd =
                            this.uploadDcoumentDetailsModel.uploadType !== "new"
                                ? element.kycDocDetails.shopAdd
                                : "";
                        this.uploadDcoumentDetailsModel.cheqNum = "";
                        this.uploadDcoumentDetailsModel.bankName = "";
                        this.uploadDcoumentDetailsModel.accNum = "";
                        this.uploadDcoumentDetailsModel.ifscCode = "";
                        this.uploadDcoumentDetailsModel.panNum = "";
                        this.uploadDcoumentDetailsModel.aadharNum = "";
                        this.uploadDcoumentDetailsModel.gstNum = "";
                    }
                }
            });
        }
        if (event == "cheque") {
            this.uploadPAN = false;
            this.uploadGst = false;
            this.uploadAddress = false;
            this.uploadCheque = true;
            this.uploadAADHAR = false;
            this.kycDocumentListApproved.forEach((element) => {
                if (element.docTypeId == "cheque") {
                    if (element.kycDocDetails != null) {
                        this.uploadDcoumentDetailsModel.cheqNum =
                            this.uploadDcoumentDetailsModel.uploadType !== "new"
                                ? element.kycDocDetails.cheqNum
                                : "";
                        this.uploadDcoumentDetailsModel.bankName =
                            this.uploadDcoumentDetailsModel.uploadType !== "new"
                                ? element.kycDocDetails.bankName
                                : "";
                        this.uploadDcoumentDetailsModel.accNum =
                            this.uploadDcoumentDetailsModel.uploadType !== "new"
                                ? element.kycDocDetails.accNum
                                : "";
                        this.uploadDcoumentDetailsModel.ifscCode =
                            this.uploadDcoumentDetailsModel.uploadType !== "new"
                                ? element.kycDocDetails.ifscCode
                                : "";
                        this.uploadDcoumentDetailsModel.panNum = "";
                        this.uploadDcoumentDetailsModel.shopAdd = "";
                        this.uploadDcoumentDetailsModel.aadharNum = "";
                        this.uploadDcoumentDetailsModel.gstNum = "";
                    }
                }
            });
        }
    }
    //   else {
    //     if (event == 'pan') {
    //       this.uploadPAN = true;
    //       this.uploadGst = false;
    //       this.uploadAddress = false;
    //       this.uploadCheque = false;
    //       this.uploadAADHAR = false;
    //       this.kycDocumentListApproved.forEach(element => {
    //         if (element.docTypeId == 'pan') {
    //           if (element.kycDocDetails != null) {
    //             this.uploadDcoumentDetailsModel.panNum = '';
    //             this.uploadDcoumentDetailsModel.cheqNum = '';
    //             this.uploadDcoumentDetailsModel.bankName = '';
    //             this.uploadDcoumentDetailsModel.accNum = '';
    //             this.uploadDcoumentDetailsModel.ifscCode = '';
    //             this.uploadDcoumentDetailsModel.shopAdd = '';
    //             this.uploadDcoumentDetailsModel.aadharNum = '';
    //             this.uploadDcoumentDetailsModel.gstNum = ''
    //           }
    //         }

    //       })
    //     }
    //     if (event == 'gst') {
    //       this.uploadPAN = false;
    //       this.uploadGst = true;
    //       this.uploadAddress = false;
    //       this.uploadCheque = false;
    //       this.uploadAADHAR = false;
    //       this.kycDocumentListApproved.forEach(element => {
    //         if (element.docTypeId == 'gst') {
    //           if (element.kycDocDetails != null) {
    //             this.uploadDcoumentDetailsModel.gstNum = '';
    //             this.uploadDcoumentDetailsModel.cheqNum = '';
    //             this.uploadDcoumentDetailsModel.bankName = '';
    //             this.uploadDcoumentDetailsModel.accNum = '';
    //             this.uploadDcoumentDetailsModel.ifscCode = '';
    //             this.uploadDcoumentDetailsModel.panNum = '';
    //             this.uploadDcoumentDetailsModel.shopAdd = '';
    //             this.uploadDcoumentDetailsModel.aadharNum = '';
    //           }
    //         }

    //       })
    //     }
    //     if (event == 'aadhar') {
    //       this.uploadPAN = false;
    //       this.uploadGst = false;
    //       this.uploadAddress = false;
    //       this.uploadCheque = false;
    //       this.uploadAADHAR = true;
    //       this.kycDocumentListApproved.forEach(element => {
    //         if (element.docTypeId == 'aadhar') {
    //           if (element.kycDocDetails != null) {
    //             this.uploadDcoumentDetailsModel.aadharNum = '';
    //             this.uploadDcoumentDetailsModel.cheqNum = '';
    //             this.uploadDcoumentDetailsModel.bankName = '';
    //             this.uploadDcoumentDetailsModel.accNum = '';
    //             this.uploadDcoumentDetailsModel.ifscCode = '';
    //             this.uploadDcoumentDetailsModel.panNum = '';
    //             this.uploadDcoumentDetailsModel.shopAdd = '';
    //             this.uploadDcoumentDetailsModel.gstNum = ''
    //           }
    //         }

    //       })
    //     }
    //     if (event == 'address') {
    //       this.uploadPAN = false;
    //       this.uploadGst = false;
    //       this.uploadAddress = true;
    //       this.uploadCheque = false;
    //       this.uploadAADHAR = false;
    //       this.kycDocumentListApproved.forEach(element => {
    //         if (element.docTypeId == 'address') {
    //           if (element.kycDocDetails != null) {
    //             this.uploadDcoumentDetailsModel.shopAdd = '';
    //             this.uploadDcoumentDetailsModel.cheqNum = '';
    //             this.uploadDcoumentDetailsModel.bankName = '';
    //             this.uploadDcoumentDetailsModel.accNum = '';
    //             this.uploadDcoumentDetailsModel.ifscCode = '';
    //             this.uploadDcoumentDetailsModel.panNum = '';
    //             this.uploadDcoumentDetailsModel.aadharNum = '';
    //             this.uploadDcoumentDetailsModel.gstNum = ''
    //           }
    //         }

    //       })
    //     }
    //     if (event == 'cheque') {
    //       this.uploadPAN = false;
    //       this.uploadGst = false;
    //       this.uploadAddress = false;
    //       this.uploadCheque = true;
    //       this.uploadAADHAR = false;
    //       this.kycDocumentListApproved.forEach(element => {
    //         if (element.docTypeId == 'cheque') {
    //           if (element.kycDocDetails != null) {
    //             this.uploadDcoumentDetailsModel.cheqNum = '';
    //             this.uploadDcoumentDetailsModel.bankName = '';
    //             this.uploadDcoumentDetailsModel.accNum = '';
    //             this.uploadDcoumentDetailsModel.ifscCode = '';
    //             this.uploadDcoumentDetailsModel.panNum = '';
    //             this.uploadDcoumentDetailsModel.shopAdd = '';
    //             this.uploadDcoumentDetailsModel.aadharNum = '';
    //             this.uploadDcoumentDetailsModel.gstNum = ''
    //           }
    //         }

    //       })
    //     }
    //   }
    // }

    uploadDocument() {
        // this.uploadDcoumentDetailsModel.fileid = document.id;
        this.uploadDcoumentDetailsModel.accountId =
            sessionStorage.getItem("view-account");
        this.uploadDcoumentDetailsModel.doctype = "0";
        this.uploadDcoumentDetailsModel.uploadType = "0";
    }

    updateDOcument(updateDocForm: NgForm) {
        if (this.documentType == "aadhar") {
            if (
                this.updateDcoumentDetailsModel.aadharNum == "" ||
                this.updateDcoumentDetailsModel.aadharNum == null
            ) {
                alert("Aadhar number is required.");
                return;
            }
            if (this.updateDcoumentDetailsModel.aadharNum.length != 14) {
                alert("Aadhar number must be 14 digit.");
                return;
            }
        }
        if (this.documentType == "address") {
            if (
                this.updateDcoumentDetailsModel.shopAdd == "" ||
                this.updateDcoumentDetailsModel.shopAdd == null
            ) {
                alert("Shop address is required.");
                return;
            }
        }
        if (this.documentType == "pan") {
            if (
                this.updateDcoumentDetailsModel.panNum == "" ||
                this.updateDcoumentDetailsModel.panNum == null
            ) {
                alert("PAN number is required.");
                return;
            }
            if (this.updateDcoumentDetailsModel.panNum.length != 10) {
                alert("Pan number must be 10 digit.");
                return;
            }
        }
        if (this.documentType == "gst") {
            if (
                this.updateDcoumentDetailsModel.gstNum == "" ||
                this.updateDcoumentDetailsModel.gstNum == null
            ) {
                alert("GST number is required.");
                return;
            }
            if (this.updateDcoumentDetailsModel.gstNum.length != 15) {
                alert("GST number must be 15 digit.");
                return;
            }
        }
        if (this.documentType == "cheque") {
            if (
                this.updateDcoumentDetailsModel.cheqNum == "" ||
                this.updateDcoumentDetailsModel.cheqNum == null
            ) {
                alert("Cheque number is required.");
                return;
            }
            if (this.updateDcoumentDetailsModel.cheqNum.length != 6) {
                alert("Cheque number must be 6 digit.");
                return;
            }
            if (
                this.updateDcoumentDetailsModel.bankName == "" ||
                this.updateDcoumentDetailsModel.bankName == null
            ) {
                alert("Bank name is required.");
                return;
            }
            if (
                this.updateDcoumentDetailsModel.accNum == "" ||
                this.updateDcoumentDetailsModel.accNum == null
            ) {
                alert("Account number is required.");
                return;
            }
            if (
                this.updateDcoumentDetailsModel.ifscCode == "" ||
                this.updateDcoumentDetailsModel.ifscCode == null
            ) {
                alert("IFSC code is required.");
                return;
            }
        }

        let data = {
            aadharNum: this.updateDcoumentDetailsModel.aadharNum,
            accNum: this.updateDcoumentDetailsModel.accNum.toUpperCase(),
            accountId: parseInt(this.updateDcoumentDetailsModel.accountId),
            bankName: this.updateDcoumentDetailsModel.bankName.toUpperCase(),
            cheqNum: this.updateDcoumentDetailsModel.cheqNum,
            createdOn: this.updateDcoumentDetailsModel.createdOn,
            fileId: parseInt(this.updateDcoumentDetailsModel.fileId),
            gstNum: this.updateDcoumentDetailsModel.gstNum,
            id: parseInt(this.updateDcoumentDetailsModel.id),
            ifscCode: this.updateDcoumentDetailsModel.ifscCode.toUpperCase(),
            lastUpdated: this.updateDcoumentDetailsModel.lastUpdated,
            panNum: this.updateDcoumentDetailsModel.panNum,
            shopAdd: this.updateDcoumentDetailsModel.shopAdd,
            tempAccId: this.updateDcoumentDetailsModel.tempAccId,
        };

        this.https.post("/kyc/save-details", data).subscribe((res) => {
            if (res.status == 200) {
                jQuery("#assignModel").modal("hide");
                Swal.fire({
                    title: "Details updated successfully",
                    timer: 3000,
                });
                updateDocForm.resetForm();
                this.getKycDetail();
            }
        });
    }
}
