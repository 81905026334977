import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClientService } from '../http-client-service.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { DatepickerOptions } from 'ng2-datepicker';


declare var jQuery:any;
  
@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class CtsEventComponent implements OnInit {
  // @ViewChild('ctsassignticket',{static:true}) table;
  // dataTable: any;
  // dtOptions: any;
  term: any;
  options: DatepickerOptions = {
    minYear: 2000,
    maxYear: 2030,
    displayFormat: 'MMM D[,] YYYY',
    barTitleFormat: 'MMMM YYYY',
    dayNamesFormat: 'dd',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    minDate: new Date(Date.now()), // Minimal selectable date
    maxDate: new Date(Date.now()),  // Maximal selectable date
    barTitleIfEmpty: 'Click to select a date',
    placeholder: 'Click to select a date', // HTML input placeholder attribute (default: '')
    addClass: 'form-control', // Optional, value to pass on to [ngClass] on the input field
    addStyle: {}, // Optional, value to pass to [ngStyle] on the input field
    fieldId: 'my-date-picker', // ID to assign to the input field. Defaults to datepicker-<counter>
    useEmptyBarTitle: false, // Defaults to true. If set to false then barTitleIfEmpty will be disregarded and a date will always be shown 
  };
  eventTypeList: any = [];
  eventType: any;
  date: Date = new Date();
  allEvents: any = [];
  usersList: any = [];
  selectedtype: any = [];
  selecteduser: any = [];
  attenduserusertypedata: any = [];
  tagUsersList: any = [
    {
      name: 'Sales',
      id: 'Sales'
    },  
    {
      name: 'Influencer',
      id: 'Influencer'
    },
    {
      name: 'CTS',
      id: 'CTS'
    },
    {
      name: 'IHB',
      id: 'IHB'
    }
  ];
  dropdownList = [];
  selectedItems = [];
  usertypedata = [];
  userlistdata = [];
  dropdownSettings:IDropdownSettings;
  userdropdownSettings:IDropdownSettings;
  attenduserdropdownSettings:IDropdownSettings;
  viewEventData: any = {};
  updateevent: any = {};
  view: boolean = false;
  constructor(private http: HttpClientService) {
    this.getAllEvents();
    this.getEventType();
  }

  readonly =  false;
  event: any = {};

  

 
  ngOnInit() {
    //  this.dataTable  = jQuery(this.table.nativeElement);
    //  this.dataTable.dataTable();
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
    this.userdropdownSettings = {
      singleSelection: false,
      idField: 'lastName',
      textField: 'firstName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false
    };
    this.attenduserdropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'firstName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false
    };
    //this.event.event_date = new Date(Date.now());
  }
  onItemSelect(item: any) {
    this.event.usertype= this.event.usertype?item['name']+","+this.event.usertype:item['name'];
    this.updateevent.usertype= this.updateevent.usertype?item['name']+","+this.updateevent.usertype:item['name'];    
    this.getTagUser();
  }
  onSelectAll(items: any) {
    for(let i=0;i<items.length;i++){
      this.event.usertype=  this.event.usertype?items[i].name+","+ this.event.usertype:items[i].name;
      this.updateevent.usertype=  this.updateevent.usertype?items[i].name+","+ this.updateevent.usertype:items[i].name;
    }
    this.getTagUser();
  }
  onItemSelectUser(item: any) {
    this.event.usertag= this.event.usertag?item['lastName']+","+this.event.usertag:item['lastName'];
    this.updateevent.usertag= this.updateevent.usertag?item['lastName']+","+this.updateevent.usertag:item['lastName'];
  }
  onSelectAllUser(items: any) {
    for(let i=0;i<items.length;i++){
      this.event.usertag=  this.event.usertag?items[i].lastName+","+ this.event.usertag:items[i].lastName;
      this.updateevent.usertag=  this.updateevent.usertag?items[i].lastName+","+ this.updateevent.usertag:items[i].lastName;
    }
    console.log("onSelectAll == " +this.event.usertag)
  }
  onAttendItemSelect(item: any) {
    this.updateevent.atended_user= this.updateevent.atended_user?item['id']+","+this.updateevent.atended_user:item['id'];
    console.log("onItemSelectUser == " +   this.updateevent.atended_user);
  }
  onAttendSelectAll(items: any) {
    for(let i=0;i<items.length;i++){
      this.updateevent.atended_user=  this.updateevent.atended_user?items[i].id+","+ this.updateevent.atended_user:items[i].id;
    }
    console.log("onSelectAll == " +this.updateevent.atended_user)
  }
  getAllEvents() {
    this.http.get('/ctswhiteevent/getevent').subscribe(data => {
      if (data.status == '200') {
        this.allEvents = data.data;
        this.allEvents.sort((a,b) => {return b.id -a.id});
        console.log(this.allEvents);
      }
    });
  }

  createInfluncer(form) {
    this.event.status = "planned";
    this.event.updatecomment = "";
    this.event.updatedby = sessionStorage.getItem("userid");
    this.event.createdby = sessionStorage.getItem("userid");
    this.http.post('/ctswhiteevent/createevent', this.event).subscribe(data => {
      if (data.status == '200' ) {
        Swal.fire({
          title: 'Event Created successfully',
          timer: 30000
        });
    this. getAllEvents() ;
    jQuery('.closebtn').click();
          }
    else{
      Swal.fire({
        title: 'Something Went Wrong',
        timer: 3000
      });
    }
    });
    form.reset();
    this.usertypedata = [];
    this.userlistdata = [];
  }
  
  updateInfluncer(form = null) {
    if(form){
      this.updateevent.status = "executed";
    } else{
      this.updateevent.status = "planned";
      this.updateevent.updatecomment = "";
    }
    this.updateevent.updatedby = sessionStorage.getItem("userid");
    this.http.post('/ctswhiteevent/editEvent', this.updateevent).subscribe(data => {
      if (data.status == '200' ) {
        Swal.fire({
          title: 'Event Created successfully',
          timer: 30000
        });
    this. getAllEvents() ;
    jQuery('.closebtn').click();
          }
    else{
      Swal.fire({
        title: 'Something Went Wrong',
        timer: 3000
      });
    }
    });
    form.reset();
    this.usertypedata = [];
    this.userlistdata = [];
  }

  pincodedata:any;
 
  getDataByPinCode(pincode) {
    if (pincode!=null && pincode.length == 6) {
      this.http.get('/geography-master/get-by-pin-code/' + pincode).subscribe(data => {
        if (data.status == '200') {
          if (data.data.length == 0) {
            Swal.fire({
              title: 'Pincode Is Incorrect',
              timer: 30000
            });
          }
          this.pincodedata = data.data;
          this.event.pincode=data.data[0].pinCode;    
          this.event.state = data.data[0].stateName;
          this.event.city = data.data[0].cityName;
          this.event.district = data.data[0].districtName;
          this.event.statecode=data.data[0].stateCode;
          if(this.view){
            this.updateevent.state = data.data[0].stateName;
            this.getTagUserUpdate();
          }
        }
      });
    }
  }
  getTagUserUpdate(){
    console.log(this.updateevent.district + this.updateevent.state + this.updateevent.usertype);
    if(this.updateevent.district && this.updateevent.state && this.updateevent.usertype){
      let str;
      str = "?type="+this.updateevent.usertype+"&name="+this.updateevent.state+"&distt="+this.updateevent.district;
      this.http.get('/ctswhitelead/getuserNew'+str).subscribe(data => {
        this.usersList = data.data;
        console.log(data);
        this.comparevalues();
      });
    } else{
      Swal.fire({
        title: 'Please select pincode and user type',
        timer: 30000
      });
    }
  }
  getTagUser(){
    if((this.event.district && this.event.state && this.event.usertype)){
      let str;
      str = "?type="+this.event.usertype+"&name="+this.event.state+"&distt="+this.event.district;
      this.http.get('/ctswhitelead/getuserNew'+str).subscribe(data => {
        this.usersList = data.data;
        console.log(data);;
      });
    } else{
      Swal.fire({
        title: 'Please select pincode and user type',
        timer: 30000
      });
    }
  }

  getViewEvent(data) {
    console.log(this.viewEventData.eventtype_id);
    this.view = true;
    this.http.get('/ctswhiteevent/viewEvent/'+data.id).subscribe(data => {
      if(data.status == '200'){
        this.viewEventData = data.data;
        this.updateevent = data.data;
        this.getDataByPinCode(this.updateevent.pincode);
        this.userSelect(data.data.usertype);
        this.userSelectTag(data.data.usertag);
        console.log(this.updateevent);
        console.log(this.viewEventData.eventtype_id);
      }
    });
  }
  comparevalues(){
    let arr =[];
    let val;
    Object.keys(this.updateevent.usertagdata).forEach(key => {
      console.log(this.updateevent.usertagdata[key]['lastName']);
      val = this.checkvalues(this.updateevent.usertagdata[key]['lastName']);
      console.log(val);
      if(val){
        arr.push(val);
      }
    });
    this.updateevent.usertagdata = arr;
    console.log(this.updateevent.usertagdata);
  }
  checkvalues(val){
    let x;
    Object.keys(this.usersList).forEach(key => {
      //console.log(this.usersList[key].lastName);
      if(val == this.usersList[key].lastName){
        x = this.usersList[key];
      }
    });
    return x;
  }
  userSelect(usertype){
    if(usertype){
      let x = usertype.split(",");
      let arr =[];
      Object.keys(x).forEach(key => {
        arr.push(
          {
            name: x[key],
            id: x[key]
          }
        );
      });
      this.updateevent.usertypedata = arr;
    }
  }
  userSelectTag(usertype){
    if(usertype){
      let x = usertype.split(",");
      let arr =[];
      Object.keys(x).forEach(key => {
        arr.push(
          {
            lastName: x[key]
          }
        );
      });
      this.updateevent.usertagdata = arr;
    }
  }
  updateEvent() {
    console.log('update event');
  }
  getEventType() {
    let div = sessionStorage.getItem("divison");
    let dep = sessionStorage.getItem("department");
    this.http.get('/ctslead/eventType?div='+div+'&dept='+dep).subscribe(data => {
      this.eventTypeList = data.data;
    });
  }
  reset(){
    this.viewEventData = {};
    this.updateevent = {};
    this.event ={};
    this.view = false;
    this.usertypedata = [];
    this.userlistdata = [];
    this.attenduserusertypedata = [];
  }
  validation(){
    if(!this.event.event_date || !this.event.eventtype_id || !this.event.pinCode || !this.event.usertype  || !this.event.usertag){
      return true;
    }
    return false;
  }
}


