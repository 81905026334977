import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from '../http-client-service.service';
import Swal from 'sweetalert2';
import { timeout } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Session } from 'protractor';
import { HeaderComponent } from 'src/app/layout/header/header.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private route: Router, private http: HttpClientService, private htt: HttpClient,private header:HeaderComponent) { }

  public arr = [];
  ngOnInit() {
    this.logout()
  }

  login = {
    username: '',
    password: ''
  }

  logout(){ 
    if(sessionStorage.getItem('userid') != null){
    this.http.get(`/auth/logout/${sessionStorage.getItem('userid')}`).subscribe(data => {
      if(data.status === 200){
        // console.log("data", data);
        sessionStorage.clear()
        this.route.navigate(['/login'])
      }
    }),(error) => {
      console.log("Error", error);
    }
  }
}
  
  loginData() {
    // this.http.post('/auth', this.login).subscribe(data => {
    //   if (data.status == '200') {
    //     console.log(data.data.token)
    //     sessionStorage.setItem("tkn", data.data.token)
    //     sessionStorage.setItem("loginId", data.data.user.loginId)
    //     sessionStorage.setItem("username", data.data.firstName)


    //     this.login.password = null;
    //     this.login.username = null

    //     Swal.fire({
    //       title: 'Login successfully',
    //       timer: 1500,

    //     });
    //     this.route.navigate(['/umber']);
    //   }

    //   else if (data.status='401'){
    //     Swal.fire({
    //       title: data.message,
    //       timer: 1500,

    //     });
    //   }
    sessionStorage.clear();
    // })
    this.http.post('/auth', this.login)
      .pipe(timeout(8000))
      .subscribe(response => {
        let authority;
        let role;
        if (response.status == '200') {
          sessionStorage.setItem("tkn", response.data.token)
          sessionStorage.setItem("userid", response.data.user.id)
          sessionStorage.setItem('divison', response.data.user.userDivisionId);
          sessionStorage.setItem('department', response.data.user.userDepartmentId);
          sessionStorage.setItem('stateCode', response.data.user.stateCode);
          sessionStorage.setItem("loginId", response.data.user.loginId);
          sessionStorage.setItem("username", response.data.user.firstName)
          sessionStorage.setItem('positionCode', response.data.user.positionCode)
          sessionStorage.setItem('employeeId',response.data.user.employeeId);
          sessionStorage.setItem('authorities',this.chehkCondition(response.data.user.authorities));
          authority = this.chehkCondition(response.data.user.authorities);
          role = response.data.user.loginId;
          //console.log();
          this.http.get('/user/byUserId/' + sessionStorage.getItem("userid")).subscribe(data => {
            if (data.status = '200') {

              sessionStorage.setItem("parentpositioncode", data.data.positionCode);
            }
          });



          this.login.password = null;
          this.login.username = null;

          Swal.fire({
            title: 'Login successfully',
            timer: 1500,

          });
          if(authority =='3' || role=='ctssubadmin'){
            this.route.navigate(['/umber/cts-target']);
          }
          else if(authority == '1' || role=='superadmin'){
            this.route.navigate(['/umber/users']);
            
          }
          else  if(authority == '2' || role=='ctswhitekycadmin'){
            this.route.navigate(['/umber/ctsKyc']);
            
          }
          else{
            this.route.navigate(['/umber/ctsrequest']);
          }
        }
        else if (response.status = '401') {
          Swal.fire({
            title: response.message,
            timer: 1500,

          });
        }
      }, (error) => {
        if (this.login.password == '' && this.login.username == '') {
          Swal.fire({
            title: 'Please Enter Credentials',
            timer: 1500,

          });
          return false;
        }
        sessionStorage.clear();
        Swal.fire({
          title: 'Invalid Credential',
          timer: 1500,

        });
      })


  }
  chehkCondition(obj) {
    let authority1 = false;
    let authority2 = false;
    let authority3 = false;
    let authority4 = false;
    Object.keys(obj).forEach(key => {
      // console.log(key,obj[key],obj[key].authority);
      // if(obj[key].authority == 'ROLE_ADMIN'){
      //   authority1 = true;
      //   authority2 = false;
      //   authority3 = false;
      //   authority4 = false;
      // }
      if(obj[key].authority == 'ROLE_SUPER_ADMIN'){
        authority2 = true;
        authority1 = false;
        authority3 = false;
        authority4 = false;
      }
      if(obj[key].authority == 'ROLE_CTS_ADMIN'){
        authority3 = true;
        authority1 = false;
        authority2 = false;
        authority4 = false;
      }
      if(obj[key].authority == 'ROLE_SUB_ADMIN'){
        authority4 = true;
        authority1 = false;
        authority2 = false;
        authority3 = false;
      }
    });
    if(authority2){
      return '1';
    }
    if(authority1){
      return '0';
    }
    if(authority3){
      return '2';
    }
    if(authority4){
      return '3';
    }
    // return (authority1 && authority2)?'1':'0';
  }

  gotToForgotPage(){
    this.route.navigate(['/forgot'])
    // alert("1")
  }

  goToPrivacyPolicy(){
    this.route.navigate(['/privacy-policy']);
  }
}
