
import {Injectable} from '@angular/core';
import {HttpClient,  HttpParams, HttpHeaders} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';
declare var jQuery:any;

@Injectable() 
export class HttpClientService {


  constructor(private http: HttpClient) {
    this.previousDateDisbaled();
  }
  BASE_ENDPOINT = environment.BASE_ENDPOINT;
  public token = null;


  createAuthorizationHeader(headers: HttpHeaders, endpoint: any): HttpHeaders {

    headers.set('Content-Type','application/json')  
    this.token =  sessionStorage.getItem('tkn');
    if(this.token !=null){
      headers.set('Access-Control-Allow-Origin','*')  
    return headers.set('x-auth-token', this.token);
    }
 
    else  
    return headers.set('Authorization', "");

  }

  getAuthorizationHeader(headers: HttpHeaders, endpoint: any): HttpHeaders {

    headers.set('Content-Type','multipart/form-data')  
    this.token =  sessionStorage.getItem('tkn');
    if(this.token !=null){
      headers.set('Access-Control-Allow-Origin','*')  
    return headers.set('x-auth-token', this.token);
    }
 
    else  
    return headers.set('Authorization', "");

  }

  get(url, param?: any, head?: any): Observable<any> {

    let apiEndPoint = this.BASE_ENDPOINT;
    


    if (param != null) {
      param = '?' + param;
    } else {  
      param = '';
    }
    let headers: HttpHeaders;

    if (!head) {
      headers = this.createAuthorizationHeader(new HttpHeaders(), apiEndPoint);
      
    } else {
      headers = head;
    }
    
    return this.http.get(apiEndPoint + url + param, {headers: headers});
  }


  delete(url, param?: any, head?: any): Observable<any> {

    let apiEndPoint = this.BASE_ENDPOINT;


    if (param != null) {
      param = '?' + param;
    } else {
      param = '';
    }
    let headers: HttpHeaders;

    if (!head) {
      headers = this.createAuthorizationHeader(new HttpHeaders(), apiEndPoint);

    } else {
      headers = head;
    }

    return this.http.delete(apiEndPoint + url + param, {headers: headers});
  }  

  put( url, param?: any,  head?: any ): Observable<any> {
    let apiEndPoint = this.BASE_ENDPOINT;

    let headers: HttpHeaders;
    if (!head) {
      headers = this.createAuthorizationHeader(new HttpHeaders(), apiEndPoint);
    } else {
      headers = head;
    }
    return this.http.put(apiEndPoint + url, param, {headers: headers});
  }


  post( url, param?: any,  head?: any ): Observable<any> {
    let apiEndPoint = this.BASE_ENDPOINT;

    let headers: HttpHeaders;
    if (!head) {
      headers = this.createAuthorizationHeader(new HttpHeaders(), apiEndPoint);
    } else {
      headers = head;
    }
    return this.http.post(apiEndPoint + url, param, {headers: headers});
  }


  postFile( url, param?: any,  head?: any ): Observable<any> {
    let apiEndPoint = this.BASE_ENDPOINT;

    let headers: HttpHeaders;
    if (!head) {
      headers = this.getAuthorizationHeader(new HttpHeaders(), apiEndPoint);
    } else {
      headers = head;
    }
    return this.http.post(apiEndPoint + url, param, {headers: headers});
  }

  // past date disabled
  previousDateDisbaled() {
    var todaysDate = new Date(); 
    var year = todaysDate.getFullYear(); 						
    var month = ("0" + (todaysDate.getMonth() + 1)).slice(-2);	
    var day = ("0" + todaysDate.getDate()).slice(-2);			
   	 var maxdate = (year +"-"+ month +"-"+ day);
   //jQuery('input[type="date"]').attr('min', maxDate);
};



}