import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  @Input() totalRecords: number = 0;  
  @Input() recordsPerPage: number = 0;  

  @Output() onPageChange: EventEmitter<number> = new EventEmitter();  

  public pages: number [] = [];  
  activePage:number;  

  get listPages() {

    const start = Math.max(0, this.activePage - 10)
    const end = Math.min(start + 15, this.pages.length - 1)

    const listPages = [];

    for (var i = start; i <= end; i++) {
      listPages.push(this.pages[i]);
    }

    return listPages;

  }

  ngOnChanges(){  
    const pageCount = this.getPageCount();  
    this.pages = this.getArrayOfPage(pageCount);  
    this.activePage = 1;  
    this.onPageChange.emit(1);  
  }  

  private  getPageCount(): number {  
    let totalPage:number = 0;  
      
    if(this.totalRecords > 0 && this.recordsPerPage > 0){  
      const pageCount = this.totalRecords / this.recordsPerPage;  
      const roundedPageCount = Math.floor(pageCount);  

      totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;  
    }  

    return totalPage;  
  }  

  private getArrayOfPage(pageCount : number) : number [] {  
    let pageArray : number [] = [];  

    if(pageCount > 0){  
        for(var i=1 ; i<= pageCount ; i++){  
          pageArray.push(i);  
        }  
    }  

    return pageArray;  
  }  

  onClickPage(pageNumber:number){  
      if(pageNumber < 1) return;
      if(pageNumber > this.pages.length) return;
      this.activePage = pageNumber;  
      console.log(this.activePage);
      this.onPageChange.emit(this.activePage);  
  } 

}
