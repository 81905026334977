
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable ,throwError} from 'rxjs';
import { tap, map,catchError } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClientService } from 'src/app/pages/http-client-service.service';

declare var $:any;
 

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
 
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem("tkn");
    
   //'x-auth-token': `Bearer ${token}`
 
    if (token) {
    req = req.clone({
      // setHeaders: {
      //   "X-Auth-Token":`${token}` ,
      //   // 'Access-Control-Allow-Origin' :' http://192.168.1.153:8080/umber-rest/'
      //   }
    });
  }
    
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // do stuff with response if you want
          if(event.body && event.body.message!=""){
            if ( event.body.message == 'Missing valid token...') {
            //this.mes.addmessage('ERROR', 'Session Expired, Login again');;
              // sessionStorage.clear();
              sessionStorage.removeItem("tkn")
            //  this.GL. = null;
            this.router.navigate(["/login"]);
           
            // setTimeout(function() {$("#modalLRForm").modal('show');}, 500); 
            }
          }
        }
        if (event instanceof HttpResponse&& (event.body.status == 403 ||event.body.status==401)) {
          // sessionStorage.clear();
          sessionStorage.removeItem("tkn")
          this.spinner.hide();
          this.router.navigate(["/login"]);
        }
        if (event instanceof HttpResponse&& (event.status == 403 ||event.status==401)) {
          // sessionStorage.clear();
          sessionStorage.removeItem("tkn")
          this.spinner.hide();
          this.router.navigate(["/login"]);
        }
      }),
      catchError((err: HttpErrorResponse) => {
        if(err.statusText == 'Unknown Error'&& err.status == 0){
          // sessionStorage.clear();
          sessionStorage.removeItem("tkn")
          this.spinner.hide();
          this.router.navigate(["/login"]);
        }
        if (err.status == 401||err.status == 403) {
          // Handle 401 error
          this.router.navigate(["/login"]);
        } else {
          return throwError(err);
        }
        if(err.statusText == 'Unknown Error'){
          this.router.navigate(["/login"]);
        }
      })
       );
  }
  constructor(public router:Router,private spinner: NgxSpinnerService,private http:HttpClientService) {

  }
}
