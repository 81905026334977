import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClientService } from '../../http-client-service.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTableDirective } from 'angular-datatables';



class DataTablesResponse {
    data: any[];
    draw: number;
    recordsFiltered: number;
    recordsTotal: number;
}

@Component({
    selector: 'app-cts-target',
    templateUrl: './cts-target.component.html',
    styleUrls: ['./cts-target.component.css']
})
export class CtsTargetComponent implements OnInit {
    targetList: [any];
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
    constructor(private http: HttpClient, private https: HttpClientService, private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.getTargetList();
    }

    getTargetList() {
        try {
            this.dtOptions = {
                pagingType: 'full_numbers',
                pageLength: 10,
                // serverSide: true,
                responsive: true,
                // processing: true,
                ajax: () => {
                    this.https.get('/reports/get-targets').subscribe(data => {
                    if (data.status == '200') {
                        this.targetList = data.data;
                    }
                })
                },
                columns: [
                    { data: "taluka", name: "Taluka" },
                    { data: "district", name: "District" },
                    { data: "state", name: "State" },
                    { data: "targetTalukaWiseSiteVisit", name: "Target Taluka Wise - Site visit" },
                    { data: "targetTalukaWiseClosedTickets", name: "Target Taluka Wise - Closed Tickets" },
                ]
            };
        } catch (error) {
            console.log(error);
        }
    }

    onFileSelect(event,type) {
        let url;
        if (event.target.files.length > 0 && type) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            switch (type) {
                case "one":
                    url = `/reports/uploadTagetExcel`
                    break;
            
                case "two":
                    url = `/reports/uploadMonthlyLeadTargetExcel`
                    break;
            
                case "three":
                    url = `/reports/uploadMonthlyClosedEventTargetExcel`
                    break;
            
                case "four":
                    url = `/reports/uploadMonthlyContractorActivationTargetExcel`
                    break;
            
                case "five":
                    url = `/reports/uploadMonthlyContractorEnrollmentTargetExcel`
                    break;
            
                default:
                    break;
            }
            if(url){
                this.https.postFile(url, formData).subscribe((res: any) => {
                    let str = '';
                    
                    event.srcElement.value = null;
                    if (res.status === 200) {
                        str += "";
                        let icon;
                        if(res.message == "Data has not been inserted due to blank data."){
                            icon="error"
                        }
                        else{
                            icon="success"
                        }
                        Swal.fire({
                            icon:icon,
                            title: str,
                            html: "<h4>"+res.message+"</h4>",
                            timer: 150000,
                            // title: res.message,
                            // html: str,
                            // timer: 150000,
                        });
                    }
                    if (res.status === 400) {
                        // str += res.message;
                        str += ''
                        // res.message = 'Error';
                        Swal.fire({
                            icon:'error',
                            title: str,
                            html: "<h4>"+res.message+"</h4>",
                            timer: 150000,
                            // title: res.message,
                            // html: str,
                            // timer: 150000,
                        });
                    }
                }, (err) => {
                    event.srcElement.value = null;
                    Swal.fire({
                        title: 'Error',
                        html: err.error ? err.error.message : 'Error saving account',
                        timer: 150000,
                    });
                });
            }
        }
    }

    downloadFile(type) {
        let url;
        if(type){
            switch (type) {
                case "one":
                    url = `/reports/target-template-download`
                    break;
            
                case "two":
                    // https://umber.jkcement.com/umber-rest/reports/monthly-leads-target-template-download
                    url = `/reports/monthly-leads-target-template-download`
                    break;
            
                case "three":
                    // https://umber.jkcement.com/umber-rest/reports/closed-event-target-template-download
                    url = `/reports/closed-event-target-template-download`
                    break;
            
                case "four":
                    // https://umber.jkcement.com/umber-rest/reports/contractor-activation-target-template-download
                    url = `/reports/contractor-activation-target-template-download`
                    break;
            
                case "five":
                    // https://umber.jkcement.com/umber-rest/reports/contractor-enrolment-target-template-download
                    url = `/reports/contractor-enrolment-target-template-download`
                    break;
            
                default:
                    break;
            }
        }
        if(url){
            window.location.href = `${environment.BASE_ENDPOINT}${url}`;
        }
    }
}
