import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { TicketComponent } from './pages/ticket/ticket.component';
import { CtsrequestComponent } from './pages/ctsrequest/ctsrequest.component';
import { LeadComponent } from './pages/lead/lead.component';
import { InfluncerComponent } from './pages/influncer/influncer.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { LoginComponent } from './pages/login/login.component';
import { UserComponent } from './pages/user/user.component';
import { MasterComponent } from './pages/master/master.component';
import { MasterModule } from './pages/master/master.module';
import { FulllayoutComponentComponent } from './pages/fulllayout-component/fulllayout-component.component';
import { HttpClientService } from './pages/http-client-service.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './TokenInterceptor';

import { NgxSpinnerModule } from "ngx-spinner";
import { ProfileComponent } from './pages/profile/profile.component';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { CtsWhiteModule } from './pages/cts-white/cts-white.module';
import { SharedModule } from './shared/shared.module';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
//import { TicketcreationComponent } from './pages/ticketcreation/ticketcreation.component';
//import { AllticketsComponent } from './pages/alltickets/alltickets.component';
import {CtsSubadminModule} from './pages/cts-subadmin/cts-subadmin.module'
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PrivacyPolicyNewComponent } from './pages/privacy-policy-new/privacy-policy-new.component';
import { InfluencerModule } from './pages/Inflencer/influencer/influencer.module';
import { SiteModule } from './pages/site/site.module';
import { VanModule } from './pages/van/van.module';
import { PrioritizationModule } from './pages/Priortization/prioritization/prioritization.module';
import { JcpModule } from './pages/JCP/jcp.module';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';


@NgModule({
  declarations: [
    AppComponent,

    LoginComponent,
    
    MasterComponent,
    MasterComponent,
    FulllayoutComponentComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyNewComponent,
    ForgotPasswordComponent,
    //TicketcreationComponent,
   // AllticketsComponent,

    //AccountsComponent,
    //ProfileComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    MasterModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    CtsWhiteModule,
    SharedModule,
    BrowserAnimationsModule,
    CtsSubadminModule,
    InfluencerModule,
    SiteModule,
    VanModule,
    PrioritizationModule,
    JcpModule
  ],
  providers: [     DatePipe, HttpClientService,{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
