import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JcpRoutingModule } from './jcp-routing.module';
import { JcpComponent } from './jcp/jcp.component';

import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DataTablesModule } from 'angular-datatables';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import  { NgxPaginationModule }  from "ngx-pagination";
import  { NgxSpinnerModule }  from "ngx-spinner";
import { CounterWiseJcpComponent } from './counter-wise-jcp/counter-wise-jcp.component';
import { MonthWiseVisitsComponent } from './month-wise-visits/month-wise-visits.component';

const routes: Routes = [
  {
    path: '', component: JcpComponent
  },
];

@NgModule({
  declarations: [
    JcpComponent,
    CounterWiseJcpComponent,
    MonthWiseVisitsComponent,
  ],
  imports: [
    CommonModule,
    JcpRoutingModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    AngularMultiSelectModule,
    DataTablesModule,
    Ng2SearchPipeModule,
    NgMultiSelectDropDownModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
  ]
})
export class JcpModule { }
