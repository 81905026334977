import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CtsSubadminRoutingModule } from './cts-subadmin-routing.module';
import { CtsTargetComponent } from './cts-target/cts-target.component';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
  declarations: [
    CtsTargetComponent
  ],
  imports: [
    CommonModule,
    CtsSubadminRoutingModule,
    DataTablesModule
  ]
})
export class CtsSubadminModule { }
