import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpClientService } from '../http-client-service.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
declare var jQuery:any;

@Component({
  selector: 'app-influncer',
  templateUrl: './influncer.component.html',
  styleUrls: ['./influncer.component.css']
})  
export class CtsInfluncerComponent implements OnInit {
  // @ViewChild('ctsassignticket',{static:true}) table;
  // dataTable: any;
  // dtOptions: any;

  allinfluncer: any = [];
  influncerview: any ={};
  preferredBrandsss: any =[];
  dropdownList = [];
  selectedItems = [];
  term: any;
  contactnumberExist: any = false;
  div: any = sessionStorage.getItem("divison");
  dep: any = sessionStorage.getItem("department");
 dropdownSettings:IDropdownSettings;
  constructor(private http: HttpClientService) {
    this.getAllInfluncer();
  }

  readonly =  false;
  influncer = {
    categoryId: '',
    name: '',
    tier: '',
    preferredBrand: '',
    monthlyPotentialMt: '',
    tradeSitesNumber: '',
    contact: '',
    email: '',
    status: '1',
    district:'',
    pincode:'',
    state:'',
    statecode:'',
    city:'',
    divisionId: this.div,
    departmentId: this.dep,
  }

  degree = {
    assignToId: '',
    categoryId: '',
    contactName: '',
    contactCategory: '',
    subCategoryId: '',
    stageConstruction: '',
    preferredDate: '',
    ctsSiteId: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    pincode: '',
    stateId: '',
    cityId: '',
    ctsGeogId: '',
    districtId: '',
    ihbName: '',
    ihbContatct: '',
    name: '',
    StatusId: '',
    subStatusId: '',
    statusId: 1,
    ticketId: '',
    batchNumber: '',
    batchProducts: '',
    purchaseDate: '',
    supplierName: '',
    supplierNumber: '',
    rescheduledate: '',
  }

 
  ngOnInit() {
    //  this.dataTable  = jQuery(this.table.nativeElement);
    //  this.dataTable.dataTable();

    this.dropdownList = [
      { item_id: 1, item_text: 'JK' },
      { item_id: 2, item_text: 'AMBUJA' },
      { item_id: 3, item_text: 'ACC' },
      { item_id: 4, item_text: 'SANGHI' },
      { item_id: 5, item_text: 'ULTRATECH' },
      { item_id: 6, item_text: 'WONDER' },
      { item_id: 7, item_text: 'BIRLA' },
      { item_id: 8, item_text: 'PERFECT PLUS' },
      { item_id: 9, item_text: 'NUVOCO' },
      { item_id: 10, item_text: 'MYCEM' },
      { item_id: 11, item_text: 'PRISM' },
      { item_id: 12, item_text: 'BANGUR' },
      { item_id: 13, item_text: 'MANGALAM' },
      { item_id: 14, item_text: 'ROCK' },
      { item_id: 15, item_text: 'SHREE' },
      { item_id: 16, item_text: 'SIDHEE' },
      { item_id: 17, item_text: 'INDIA' },
      { item_id: 18, item_text: 'SHRIRAM' },
      { item_id: 19, item_text: 'NIRMAX' },
      { item_id: 20, item_text: 'VASODATTA' },
      { item_id: 21, item_text: 'ZUARI' },
      { item_id: 22, item_text: 'RAMCO' },
      { item_id: 23, item_text: 'DALMIA' },
      { item_id: 24, item_text: 'KESORAM' },
      { item_id: 25, item_text: 'JSW' },
      { item_id: 26, item_text: 'CHETTINAD' },
      { item_id: 27, item_text: 'PENNA' },
      { item_id: 28, item_text: 'JAYPEE' },
      { item_id: 29, item_text: 'DURATON' },
      { item_id: 30, item_text: 'KAMAL' },
      {item_id:31,item_text:'Other'}


    ];
    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
    
  }
  onItemSelect(item: any) {
    // for(let i=0;i<item.length;i++){
    //   this.influncer.preferredBrand=  item[i].item_text+","+ this.influncer.preferredBrand
    //   }
    this.influncer.preferredBrand=JSON.stringify(item['item_text'])+","+this.influncer.preferredBrand;
    console.log("onItemSelect == " +   this.influncer.preferredBrand);
  }
  onSelectAll(items: any) {
  for(let i=0;i<items.length;i++){
    this.influncer.preferredBrand=  items[i].item_text+","+ this.influncer.preferredBrand
    }
    console.log("onSelectAll == " +this.influncer.preferredBrand)
  }


  getAllInfluncer() {
    //ctsinfluencer/getInfluencerList/3/3
    this.http.get('/ctswhiteinfluencer/get-influncer-web/'+this.div+'/'+this.dep+'/'+sessionStorage.getItem("stateCode")).subscribe(data => {
      if (data.status == '200') {
        //console.log(data.data)
        this.allinfluncer = data.data;
      }
    });
  }
  editAccount(conatct){
    this.http.get('/ctsinfluencer/getInfluencerProfileByCont/'+conatct+'/'+this.div+'/'+this.dep).subscribe(data => {
      if (data.status == '200') {
        console.log(data.data)
        this.influncerview = data.data;
        this.influncerview.potential = data.potential;
        this.influncerview.lifting = data.lifting;
      }
    });
    //this.influncer = view;
    //console.log(view);
  }
  readonlycondition() {
    if(this.influncer.categoryId == '33') {
      this.influncer.tradeSitesNumber = '1';
      this.readonly =  true;
    }else {
      this.readonly =  false;
    }
  }

  createInfluncer(form) {
   
    this.http.post('/ctswhiteinfluencer/save', this.influncer).subscribe(data => {
      if (data.status == '200' ) {
        Swal.fire({
          title: 'Influencer Created successfully',
          timer: 3000000
        });
    this. getAllInfluncer() ;
    this.resetcreateinfulencer();
    jQuery('.closebtn').click();
          }
    else{
      Swal.fire({
        title: 'Something Went Wrong',
        timer: 300000
      });
    }
    });
    form.reset();
  }
  
  site={
  
    stateName:'',
    cityName:'',
    districtName:''
  }

  pincodedata:any;
 
  getDataByPinCode(pincode) {
    if (pincode!=null && pincode.length == 6) {
      this.http.get('/geography-master/get-by-pin-code/' + pincode).subscribe(data => {
        if (data.status == '200') {
          if (data.data.length == 0) {
            Swal.fire({
              title: 'Pincode Is Incorrect',
              timer: 300000
            });
          }
          this.pincodedata = data.data;
          this.influncer.pincode=data.data[0].pinCode;    
          this.influncer.state = data.data[0].stateName;
          this.influncer.city = data.data[0].cityName;
          this.influncer.district = data.data[0].districtName;
          this.influncer.statecode=data.data[0].stateCode;
        }
      });
    }
  }

  checkContact(contact) {
    if(contact.length==10){
      this.http.get("/ctsinfluencer/fetchInfluencer/"+contact).subscribe(data => {
        if (data.status == '200' && data.data.length > 0) {
          this.degree.contactName = '';
          this.degree.contactCategory = '';
          this.contactnumberExist = true;
          this.influncer.contact = null;
        }
        else {
          this.contactnumberExist = false;
        }
      });
    }
  }

  resetcreateinfulencer() {
    this.influncer.categoryId= '';
    this.influncer.name= '';
    this.influncer.tier= '';
    this.influncer.preferredBrand= '';
    this.influncer.monthlyPotentialMt= '';
    this.influncer.tradeSitesNumber= '';
    this.influncer.contact= '';
    this.influncer.email= '';
    this.influncer.status= '1';
    this.influncer.district='';
    this.influncer.pincode='';
    this.influncer.state='';
    this.influncer.statecode='';
    this.influncer.city='';
    this.influncer.divisionId= this.div;
    this.influncer.departmentId= this.dep;
    this.preferredBrandsss = [];
    this.pincodedata = [];
  }
  validationcheck(){
    if(this.contactnumberExist || !this.influncer.name || !this.influncer.email || !this.influncer.pincode || !this.influncer.contact || !this.influncer.categoryId || !this.influncer.tier || !this.influncer.preferredBrand || !this.influncer.monthlyPotentialMt || !this.influncer.tradeSitesNumber){
      return true;
    }
    return false;
  }
}


