import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TicketComponent } from '../ticket/ticket.component';
import { UserComponent } from '../user/user.component';
import { InfluncerComponent } from '../influncer/influncer.component';
import { LeadComponent } from '../lead/lead.component';
import { ReportsComponent } from '../reports/reports.component';
import { NotificationComponent } from '../notification/notification.component';
import { AllticketsComponent } from '../alltickets/alltickets.component';
import { MasterComponent } from './master.component';
import { CtsrequestComponent } from '../ctsrequest/ctsrequest.component';
import { AddsiteComponent } from '../addsite/addsite.component';
import {ProfileComponent} from '../profile/profile.component';
import {AccountsComponent} from '../accounts/accounts.component';
import { TicketcreationComponent } from '../ticketcreation/ticketcreation.component';
import { SapCodeComponent } from '../sap-jobs/sap-jobs.component';
import { EventProfileComponent } from '../event-profile/event-profile.component';
import { GiftMasterComponent } from '../gift-master/gift-master.component';
import { LeadPriorityComponent } from '../lead-priority/lead-priority.component';


const routes: Routes = [
  {path: '', data: {title: 'Test'},
  children: [{
    path: '', component: TicketComponent},
    {path: 'alltickets', component: AllticketsComponent},
    {path: 'ticketcreation', component: TicketcreationComponent},
  {path: 'users', component: UserComponent},
  {path: 'sap-jobs', component: SapCodeComponent},
  {path: 'lead-priority', component: LeadPriorityComponent},
  {path: 'event/event-profile', component: EventProfileComponent},
  {path: 'event/gift-master', component: GiftMasterComponent},
  {path: 'influencer', component: InfluncerComponent},
  {path: 'ticketlead', component: LeadComponent},
  {path: 'reports', component: ReportsComponent},
  {path: 'notification', component: NotificationComponent}, 
  {path: 'ctsrequest', component: CtsrequestComponent},
  {path: '', component: AddsiteComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'accounts', component: AccountsComponent},
  ]},
  {
    path: 'cts',
    loadChildren: () => import('../cts-white/cts-white.module').then(m => m.CtsWhiteModule)
  },
  {
    path: 'accountMgmt',
    loadChildren: () => import('../account-management/account-management.module').then(m => m.AccountManagementModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterRoutingModule { }
