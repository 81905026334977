import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppserviceService } from 'src/app/appservice.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  role:any;
  authorities:any;
  div:any;
  dep:any;
  position_code;
  contentOrderBy:any=[];
  constructor(private router: Router,private apservice:AppserviceService) { 
    this.role=sessionStorage.getItem("loginId");
    this.authorities=sessionStorage.getItem("authorities");
    this.div=sessionStorage.getItem("divison");
    this.dep=sessionStorage.getItem("department");
    this.position_code=sessionStorage.getItem("positionCode");
    this.userroleset();
    if(!this.role){
      this.router.navigate(['/login']);
    }
  }
  public submenulist: any=[
    {faicon : 'fa fa-tachometer',path:'umber/lead/score-formula',name:'Score Formula', parentid:5},
    {faicon : 'fa fa-tachometer',path:'umber/lead/parameter-weightage-percentage',name:'Parameter Weightage', parentid:5},
    {faicon : 'fa fa-tachometer',path:'umber/lead/priortization-logic',name:'Prioritization Logic', parentid:5},
    {faicon : 'fa fa-tachometer',path:'umber/lead',name:'AO Wise Score', parentid:5},
    {faicon : 'fa fa-tachometer',path:'umber/lead-priority',name:'Lead Prioritization', parentid:5},
    {faicon : 'fa fa-tachometer',path:'umber/event/event-profile',name:'Event Profile', parentid:9},
    {faicon : 'fa fa-tachometer',path:'umber/event/gift-master',name:'Gift Master', parentid:9},
    // {faicon : 'fa fa-tachometer',path:'umber/van/profile',name:'VAN profile', parentid:7},
    // {faicon : 'fa fa-tachometer',path:'umber/van/trip-details',name:'VAN Trip Details', parentid:7},
  ];
  toggleShow = false;
  //user //6 1/admin ---user ticket
  // 2cts -- contentOrderBy
  // 3dmo--- contentOrderBy reorts
  // 4.influencer-- contentOrderBy influncer --
  // 5.ticketuser--- create ticket ticket llist
  // 6.subadmin----user ticket 

userroleset(){
console.log(this.role,"---------------")

 if( this.authorities == '3' || this.role=='ctssubadmin'){
  this.contentOrderBy = [
    { id: 2, path:'umber/cts-target',name: 'Target Report' , faicon : 'fa fa-dot-circle-o', submenu : ['user']},
    // { id: 6,path:'umber/accountMgmt', name: 'Accounts', faicon : 'fa fa-male' },
   ];
 }  
 else if(this.authorities == '1' || this.role=='superadmin'){
  this.contentOrderBy = [
    { id: 2, name: 'Users', path:'umber/users', faicon : 'fa fa-user', submenu : ['user'], isdropdown : false},
    { id: 6, name: 'Accounts', path:'umber/accountMgmt', faicon : 'fa fa-database', isdropdown : false },
    { id: 3, name: 'Influencer', path:'umber/influencers',  faicon : 'fa fa-sitemap', isdropdown : false },
    { id: 4, name: 'Site', path:'umber/site',  faicon : 'fa fa-map', isdropdown : false },
    { id: 7, name: 'Van', path:"umber/van/profile", faicon : 'fa fa-car', isdropdown : false },
    { id: 9, name: 'Event', faicon : 'fa fa-empire', isdropdown : true},
    { id: 5, name: 'Lead', faicon : 'fa fa-leaf', isdropdown : true},
    { id: 10, name: 'JCP', path:"umber/jcp", faicon : 'fa fa-truck', isdropdown : false},
    { id: 8, name: 'SAP Jobs', path:"umber/sap-jobs", faicon : 'fa fa-suitcase', isdropdown : false},
   ];
 }  
 else  if(this.authorities == '2' && this.role=='ctswhiteadmin'){
  this.contentOrderBy = [
    { id: 2, path:'umber/ctsReports',name: 'Reports' , faicon : 'fa fa-file-text'}
   ];
 }  
 else  if(this.authorities == '2' || this.role=='ctswhitekycadmin'){
  this.contentOrderBy = [
    { id: 12, path:'umber/ctsKyc',name: 'KYC' , faicon : 'fa fa-file-text'}
   ];
 } 
 else if(this.div =='3' && this.dep =='3')
  this.contentOrderBy = [
   // { id: 11, path:'umber/alltickets',name: ' All Tickets', faicon : 'fa fa-ticket' },
   // { id: 12, path:'umber/ticketcreation',name: ' Ticket Creations', faicon : 'fa fa-ticket' },
    { id: 8, path:'umber/ctsWhiteRequest',name: ' CTS White Ticket', faicon : 'fa fa-ticket' },
    //{ id: 11, path:'umber/ticket-creation',name: ' Ticket Creation', faicon : 'fa fa-ticket' },
    //{ id: 1,path:'umber/ticket', name: 'Tickets' , faicon : 'fa fa-tachometer', submenu : ['Dashboard']},
    //{ id: 2, path:'umber/users',name: 'Users' , faicon : 'fa fa-male', submenu : ['user']},
    { id: 3,path:'umber/ctsinfluencer', name: 'CTS White Influencer', faicon : 'fa fa-tachometer' },
    { id: 4,path:'umber/ctsLead', name: 'CTS White Leads', faicon : 'fa fa-tachometer' },
    //{ id: 5,path:'umber', name: 'Ticket', faicon : 'fa fa-tachometer' },
   { id: 6,path:'umber/events', name: 'CTS White Events', faicon : 'fa fa-tachometer' },
   { id: 9,path:'umber/ctswhite-individual-report', name: 'CTS White Reports', faicon : 'fa fa-file-text' },
  //  { id: 7, path:'umber/notification',name: 'Notification', faicon : 'fa fa-tachometer' }
    // ,{
    //   id: 9, path:'umber/profile',name: 'Profile', faicon : 'fa fa-tachometer'
    // },
    // { id: 10, path:'umber/accounts', name: 'Accounts', faicon : 'fa fa-tachometer' },
  ];

  else if(this.role =='admin'||this.role =='JKCCE1'||this.role =='JKCCE2'||this.role =='JKCCE3'||this.role =='JKCCE4' ||this.role =='JKCCE5'){
    this.contentOrderBy = [
     // { id: 11, path:'umber/alltickets',name: ' All Tickets', faicon : 'fa fa-ticket' },
     // { id: 12, path:'umber/ticketcreation',name: ' Ticket Creations', faicon : 'fa fa-ticket' },
      { id: 8, path:'umber/ctsrequest',name: ' CTS Ticket', faicon : 'fa fa-ticket' },
      //{ id: 11, path:'umber/ticket-creation',name: ' Ticket Creation', faicon : 'fa fa-ticket' },
      //{ id: 1,path:'umber/ticket', name: 'Tickets' , faicon : 'fa fa-tachometer', submenu : ['Dashboard']},
     // { id: 2, path:'umber/users',name: 'Users' , faicon : 'fa fa-male', submenu : ['user']},
      // { id: 3,path:'umber/influencer', name: 'Influencer', faicon : 'fa fa-tachometer' },
      { id: 4,path:'umber/ticketlead', name: 'Leads', faicon : 'fa fa-tachometer' },
      //{ id: 5,path:'umber', name: 'Ticket', faicon : 'fa fa-tachometer' },
    //  { id: 6,path:'umber/reports', name: 'Reports', faicon : 'fa fa-tachometer' },
     // { id: 7, path:'umber/notification',name: 'Notification', faicon : 'fa fa-tachometer' }
      // ,{
      //   id: 9, path:'umber/profile',name: 'Profile', faicon : 'fa fa-tachometer'
      // },
     // { id: 10, path:'umber/accounts', name: 'Accounts', faicon : 'fa fa-tachometer' },
    ];
  }
  }

  ngOnInit() {
  }

  

  

  navigate(url,id){
    if(url){
      this.apservice.setUrl(url);
      this.router.navigate(['/'+url]);
    }
    else{
      if(id){
        this.contentOrderBy.map(ele =>{
          if(ele.id === id){
            document.getElementById(ele.name).style.display="block" ;
          }
          else{
            document.getElementById(ele.name).style.display="none"
          }
        });
      }
    }
  }

}
