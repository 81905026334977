import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  openmodal(modalid){
    document.getElementById(modalid);
  }
}
