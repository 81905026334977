import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HttpClientService } from '../http-client-service.service';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-priortization',
    templateUrl: './priortization.component.html',
    styleUrls: ['./priortization.component.css']
})
export class PriortizationComponent implements OnInit {
    dtOptions: DataTables.Settings = {};
    cityList: any = [];
    districtList: any = [];
    ownerList: any = [];
    stateList: any = [];
    divisonList: any = [];
    statusList: any = [
        {
            id: 1,
            value: 'Active'
        },
        {
            id: 0,
            value: 'InActive'
        }
    ];
    accountData: any = {
        siteName: "",
        constructionStage: "",
        influencerCat: "",
        siteContact: "",
        state: "",
        district: "",
        city: "",
        pinCode: "",
        divisionId: "3"
    };
    siteData: any = {
        state: "",
        AO: "",
        leadType: "",
        leadValue: "",
        zone: ""
    };
    customerType: any = [];
    term: string = '';
    whiteDivision: boolean = false;
    dropdownSettings: any;
    usersList: any = [];
    createAcct: any = {};
    view: boolean = false;
    edit: boolean = false;
    // ABCClassificationList: any = [];
    InfluencerCategory: any = [];
    SiteCategory: any = [];
    constructionStage: any = [];
    InfluencerTier: any = [];
    preferredBrand: any = [];
    disableSAPCode: boolean;
    pager: { itemsPerPage: any; currentPage: any; totalItems: any; };
    perPage: any = 10;
    pageNumber: any = 1;
    addcreateAcct: any;
    mobileError: boolean = true;
    allOwnersList: any = [];
    pincodedata: any;
    pincodeError: string;
    sapcodeError: string = "";
    sapcodeVError: boolean = false;
    dropdownSettingsFilter: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
    primaryOwnerIdError: boolean;
    page: number = 1;
    itemsPerPage: number = 10;
    totalItems: any;
    timeout: any = null;
    errMsg: string = "";
    fromDate: any = new Date();
    toDate: any = new Date();
    downloadExcelFilter: any = {};
    dt: any;
    ct: any;
    taluka: any;
    locality: any;
    oldPincode: any;
    showAbcClassificationList: boolean = false;
    showCustomerTypeList: boolean = false;
    departmentList: any;
    validationErrorMsg: any;
    transferList = {
        createdBy: "",
        ids: []
    }
    transferUserList = [];
    userlist = [];
    rawJSON = [];
    aoWiseScoreData = [];
    tableHead = [];
    allAO_Socre = [];
    zoneList = [];
    leadValueList = [];
    leadId:any;

    constructor(
        private http: HttpClientService,
        private spinner: NgxSpinnerService,
        public datepipe: DatePipe
    ) { }

    ngOnInit() {
        // this.getAllAO();
        this.getLeadTypeList();
        // this.getLeadStates();
        this.getZoneList();
        // this.aoWiseScoreFormating();
        // this.getAllAOWiseScore();
        // this.getLeadStates();
        // this.getLeadStates();
        this.find();

        // this.downloadExcelFilter = {
        //     startDate: this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'),
        //     endDate: this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),
        // }

        // this.getAllOwners();
        // this.dtOptions = {
        //     pagingType: 'full_numbers',
        //     ordering: false
        // };

        // this.getStateList();
        // this.getDivisonList();
        // // this.getABCClassification(2);
        // // this.getCustomerType(2);
        // this.getDepartmentList();
        // this.getSiteCategory();
        // // debugger
        // this.getConstructionStage();
        // this.getInfluencerCategoryForSite();
        // this.getInfluencerTier();
        // this.getBrands();
        // this.getTransferUserList();
        // this.createAcct = {
        //     status: 1,
        //     divisionId:"3"
        // };
        // this.dropdownSettings = {
        //   singleSelection: false,
        //   text:"Select Owners",
        //   selectAllText:'Select All',
        //   unSelectAllText:'UnSelect All',
        //   enableSearchFilter: true,
        //   classes:"myclass custom-class"
        // };
        // this.dropdownSettings = {
        //     singleSelection: true,
        //     idField: 'employeeid',
        //     textField: 'name',
        //     selectAllText: 'Select All',
        //     unSelectAllText: 'UnSelect All',
        //     itemsShowLimit: 5,
        //     allowSearchFilter: true
        // };
        // this.dropdownSettingsFilter = {
        //     singleSelection: true,
        //     idField: 'userId',
        //     textField: 'userName',
        //     selectAllText: 'Select All',
        //     unSelectAllText: 'UnSelect All',
        //     itemsShowLimit: 5,
        //     allowSearchFilter: true,
        // };
    }

    // aoWiseScoreFormating(){
    //     console.log("ul",this.userlist);

    //     let x = [...new Set(this.userlist.map(item => item.zone))];
    //     console.log("x",x);

    //     const formatedData = this.tableHead.map((ele)=>(
    //         this.rawJSON.filter(obj => obj.leadType === ele)
    //     ))
    //     this.aoWiseScoreData = [];
    //     // this.tableHead = [...new Set(formatedData.map(item => item.leadType))];
    //     formatedData.map(ele=>{
    //     //   let x = [...new Set(ele.map(item => item.leadValue))]
    //       let x = [...new Map(ele.map(item =>[item.leadValue, item])).values()]
    //       this.aoWiseScoreData.push({
    //         leadType:x[0].leadType,
    //         LeadValue:x 
    //       })
    //     })
    // }

    objectToArray(obj) {
        const arr = [];
        Object.keys(obj).forEach(key => {
            arr.push(
                {
                    id: key,
                    value: obj[key]
                }
            );
        });
        return arr;
    }

    objectToArrayOwner(obj) {
        const arr = [];
        if (obj) {
            Object.keys(obj).forEach(key => {
                arr.push(
                    {
                        userId: obj[key][0],
                        userName: obj[key][1] + "(" + obj[key][3] + ")",
                        territoryCode: obj[key][2]
                    }
                );
            });
            return arr;
        }
    }

    getZoneList() {
        this.spinner.show();
        this.http.get('/lead-admin/findAllZones').subscribe(data => {
            this.zoneList = data
            // console.log("zone",this.zoneList);
            this.spinner.hide();
            // if (data.status == 200) {
            // this.stateList = this.objectToArray(data.data);
            // this.accountData.state = sessionStorage.getItem("stateCode");
            // this.find();
            // }
        });
    }

    getLeadStates(zone) {
        this.spinner.show();
        this.http.get(`/lead-admin/findAllStates?zone=${zone}`).subscribe(data => {
            this.stateList = data
            // console.log("state",this.stateList);

            this.spinner.hide();
            // if (data.status == 200) {
            // this.stateList = this.objectToArray(data.data);
            // this.accountData.state = sessionStorage.getItem("stateCode");
            // this.find();
            // }
        });
    }

    getAllAO(state) {
        this.spinner.show();
        this.http.get(`/lead-admin/findAllAO?state=${state}`).subscribe(data => {
            this.rawJSON = data
            this.spinner.hide();
            // if (data.status == 200) {
            // this.stateList = this.objectToArray(data.data);
            // this.accountData.state = sessionStorage.getItem("stateCode");
            // this.find();
            // }
        });
    }

    getLeadTypeList() {
        this.spinner.show();
        this.http.get('/lead-admin/findAllLeadType').subscribe(data => {
            this.tableHead = this.objectToArray(data);
            this.spinner.hide();
            // if (data.status == 200) {
            // this.stateList = this.objectToArray(data.data);
            // this.accountData.state = sessionStorage.getItem("stateCode");
            // this.find();
            // }
        });
    }

    getLeadValueList(leadType) {
        this.siteData.leadValue=""
        this.spinner.show();
        this.http.get(`/lead-admin/findAllLeadValue?leadType=${leadType}`).subscribe(data => {
            this.leadValueList = data
            // console.log("leadType",this.leadValueList);
            this.spinner.hide();
            // if (data.status == 200) {
            // this.stateList = this.objectToArray(data.data);
            // this.accountData.state = sessionStorage.getItem("stateCode");
            // this.find();
            // }
        });
    }



    getStateList() {
        this.spinner.show();
        this.http.get('/account-management/get-state').subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.stateList = this.objectToArray(data.data);
                // this.accountData.state = sessionStorage.getItem("stateCode");
                // this.find();
            }
        });
    }

    getCustomerType(id) {
        this.spinner.show();
        this.http.get(`/account-management/get-customer-group/${id}`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.customerType = this.objectToArray(data.data);
            }
        });
    }

    getAllAOWiseScore() {
        // https://umber.jkcement.com/umber-rest/admin/getAllLeads
        this.spinner.show();
        this.http.get(`/lead-admin/getAllLeads`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                // this.allAO_Socre = this.objectToArray(data.data);

            }
        });
    }

    getDivisonList() {
        this.spinner.show();
        this.http.get('/account-management/get-division').subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.divisonList = this.objectToArray(data.data);
            }
        });
    }

    stateSelected(stateId) {
        if (stateId) {
            sessionStorage.setItem('selected-state', stateId);
            this.getCityByState(stateId);
            this.getDistrictByState(stateId);
            this.getOwnerByState(stateId);
        }
    }

    getCityByState(id) {
        this.spinner.show();
        this.http.get(`/account-management/get-citybystateid?stateid=${id}`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.cityList = this.objectToArray(data.data);
            }
        });
    }

    getDistrictByState(id) {
        this.spinner.show();
        this.http.get(`/account-management/get-districtbystateid?stateid=${id}`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.districtList = this.objectToArray(data.data);
            }
        });
    }

    setOwnetArr(ownerList) {
        let arr = [];
        if (!Array.isArray(ownerList)) {
            arr = [ownerList];
        } else if (Array.isArray(ownerList)) {
            arr = ownerList.map(v => v.id);
        }
        const ownerArr = [];
        arr.forEach(v => {
            this.ownerList.forEach(element => {
                if (element.id == v) {
                    ownerArr.push(element.id);
                }
            });
        });
        return ownerArr;
    }

    getOwnerByState(id) {
        this.spinner.show();
        this.http.get(`/account-management/get-primaryOwnerbyStateid?stateid=${id}`).subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                //this.createAcct.territoryCode = data.data["user details"][0][2];
                this.ownerList = this.objectToArrayOwner(data.data["user details"]);
            }
        });
    }

    // getABCClassification(id) {
    //     this.spinner.show();
    //     this.http.get(`//account-management/get-abc-classification/${id}`).subscribe(data => {
    //         this.spinner.hide();
    //         if (data.status == 200) {
    //             this.ABCClassificationList = this.objectToArray(data.data);
    //         }
    //     });
    // }

    getSiteCategory() {
        this.spinner.show();
        this.http.get("/ctscategory/nwtcategory").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.SiteCategory = this.objectToArray(data.data);
            }
        });
    }

    getConstructionStage() {
        this.spinner.show();
        this.http.get("/nwtlead/getConstStage").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.constructionStage = this.objectToArray(data.data);
            }
        });
    }

    getInfluencerCategoryForSite() {
        this.spinner.show();
        this.http.get("/ctscategory/influencer-category").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.InfluencerCategory = this.objectToArray(data.data);
            }
        });
    }

    getInfluencerTier() {
        this.spinner.show();
        this.http.get("/ctswhiteinfluencer/fetchInfluencerTier/3/3").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.InfluencerTier = this.objectToArray(data.data);
            }
        });
    }
    getBrands() {
        this.spinner.show();
        this.http.get("/nwtlead/getBrand").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.preferredBrand = this.objectToArray(data.data);
            }
        });
    }

    searchUser() {
        if (this.term && this.term.length > 2) {
            this.spinner.show();
            this.http.get(`/account-management/get-by-customer-name/${this.term}`).subscribe(data => {
                this.spinner.hide();
                if (data.status == 200) {
                    this.usersList = data.data;
                }
            });
        } else if (this.term.length == 0) {
            this.find();
        }
    }

    getUserDetails() {
        this.spinner.show();
        this.usersList = [];
        this.http.get(`/account-management/all-account`).subscribe(data => {
            if (data) {
                this.usersList = data;
            }
            this.spinner.hide();
        });
    }

    reset() {
        this.edit = false;
        this.view = false;
        this.createAcct = {
            status: 1,
            divisionId: "3"
        };
        $('#createInfluencerModal').trigger('reset');
        this.cityList = [];
        this.districtList = [];
        this.ownerList = [];
        this.pincodedata = [];
        this.ct = [];
        this.dt = [];
        this.locality = [];
        this.taluka = [];
        this.oldPincode = '';
        this.showAbcClassificationList = false;
        this.showCustomerTypeList = false;
        this.stateSelected(sessionStorage.getItem('selected-state'));
    }

    onItemSelect(val) {
        // console.log(val, 'valueee');
    }

    onDropDownClose(val) {
        if (!this.createAcct.allAccountOwner || !this.createAcct.allAccountOwner.length) {
            this.primaryOwnerIdError = true;
        } else {
            this.primaryOwnerIdError = false;
        }
    }

    setPrimaryOwner(prmowner, otheracc) {
        const arrayV = [];
        if (prmowner) {
            arrayV.push({ userId: prmowner.id, userName: prmowner.firstName + " " + prmowner.lastName + "(" + prmowner.employeeId + ")", territoryCode: prmowner.territoryCode })
        }
        if (otheracc) {
            otheracc.forEach(function (value) {
                arrayV.push({ userId: Number(value.userId), userName: value.userName + "(" + value.accountOwner.employeeId + ")", territoryCode: value.territoryCode })
            });
        }
        return arrayV;
    }

    getStateCode(state) {
        const code = this.stateList.filter(ele => {
            if (ele.value === state) {
                return ele
            }
        })
        return code[0].id
    }

    emailValidation = () => {
        const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})/;
        if (this.createAcct.email) {
            const emailval = this.createAcct.email.match(validRegex);
            if (emailval) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return true
        }
    }

    mobileValidation = (type) => {
        let arr = ["6", "7", "8", "9"]
        if (this.createAcct.siteContact && type == "S") {
            if (arr.includes(this.createAcct.siteContact[0]) && this.createAcct.siteContact.length == 10) {
                return true
            }
            else {
                return false
            }
        }
        else if (this.createAcct.infContact && type == "I") {
            if (arr.includes(this.createAcct.infContact[0]) && this.createAcct.infContact.length == 10) {
                return true
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }

    validation() {
        // const mobValid = this.mobileValidation();
        if (!this.createAcct.siteName || this.createAcct.siteName == null) {
            this.validationErrorMsg = "Please Enter Site Name!"
            // console.log('1')
            return true;
        }
        if (this.createAcct.siteName.length < 3) {
            this.validationErrorMsg = "Please Enter Valid Site Name!"
            // console.log('1')
            return true;
        }
        if (!this.createAcct.siteContact) {
            this.validationErrorMsg = "Please Enter Site Contact Number!"
            return true;
        }
        if (this.createAcct.siteContact.length < 10) {
            this.validationErrorMsg = "Please Enter Valid Site Contact Number!"
            return true;
        }
        if (this.createAcct.siteContact.length == 10 && !this.mobileValidation("S")) {
            this.validationErrorMsg = "Please Enter Valid Site Contact Number!"
            return true;
        }
        if (!this.emailValidation()) {
            this.validationErrorMsg = "Please Enter Valid Email ID."
            return true;
        }
        if (!this.createAcct.constuctionStage) {
            this.validationErrorMsg = "Please Enter Constuction Stage."
            return true;
        }
        if (!this.createAcct.influName || this.createAcct.influName == null) {
            this.validationErrorMsg = "Please Enter Influencer Name!"
            // console.log('1')
            return true;
        }
        if (this.createAcct.influName.length < 3) {
            this.validationErrorMsg = "Please Enter Valid Influencer Name!"
            // console.log('1')
            return true;
        }
        if (!this.createAcct.infContact) {
            this.validationErrorMsg = "Please Enter Influencer Contact Number!"
            return true;
        }
        if (this.createAcct.infContact.length < 10) {
            this.validationErrorMsg = "Please Enter Valid Influencer Contact Number!"
            return true;
        }
        if (this.createAcct.infContact.length == 10 && !this.mobileValidation("I")) {
            this.validationErrorMsg = "Please Enter Valid Influencer Contact Number!"
            return true;
        }
        if (!this.createAcct.degree) {
            this.validationErrorMsg = "Please Enter Influencer Degree."
            return true;
        }
        if (!this.createAcct.categoryId) {
            this.validationErrorMsg = "Please Enter Influencer Category."
            return true;
        }
        if (!this.createAcct.pincode) {
            this.validationErrorMsg = "Please Enter Pincode."
            return true;
        }
        if (!this.createAcct.state) {
            this.validationErrorMsg = "Please Enter State."
            return true;
        }
        if (!this.createAcct.district) {
            this.validationErrorMsg = "Please Enter District."
            return true;
        }
        if (!this.createAcct.city) {
            this.validationErrorMsg = "Please Enter City."
            return true;
        }
        if (!this.createAcct.primaryOwner) {
            this.validationErrorMsg = "Please Enter Primary Owner."
            return true;
        }
        return false;
    }

    createUser(type) {
        const loggedIn_userId = sessionStorage.getItem("userid");
        if (this.validation()) {
            alert(this.validationErrorMsg);
        }
        else {
            this.spinner.show();
            this.filterPincode();
            const reqData = {
                divisionId: "3",
                accountNo: "",
                addressId: 0,
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                addressLine4: "",
                bankName: "",
                cityName: this.createAcct.city,
                constuctionStage: this.createAcct.constuctionStage,
                contact: this.createAcct.siteContact,
                createdById: loggedIn_userId,
                creationTime: 0,
                contactpersondetails: [
                    {
                        categoryId: this.createAcct.categoryId,
                        ihbContatct: this.createAcct.infContact,
                        ihbName: this.createAcct.influName,
                        isPrimary: "1"
                    }
                ],
                districtName: this.createAcct.district,
                email: this.createAcct.email,
                gst: "",
                ifsc: "",
                influencerDeg: this.createAcct.degree,
                name: this.createAcct.siteName,
                pan: "",
                pinCode: this.createAcct.pincode,
                primaryCts: this.createAcct.primaryOwner,
                siteDocList: [
                    {
                        documentNumber: "",
                        documentType: ""
                    }
                ],
                stateName: this.createAcct.state,
                updatedById: loggedIn_userId,
                updationTime: 0
            }
            if (type == 'create') {
                this.http.post(`/site/save`, reqData).subscribe(data => {
                    if (data.status == 200) {
                        Swal.fire({
                            icon: "success",
                            // title: reqData.id ? 'Account edited successfully' : 'Account created successfully',
                            title: 'Account Created Successfully!',
                            timer: 150000,
                        });
                    }
                    this.reset();
                    this.find();
                    (jQuery("#createAccount") as any).modal("hide");
                    this.spinner.hide();
                }, err => {
                    Swal.fire({
                        title: err.error ? err.error.message : 'Error saving details',
                        timer: 150000,
                    });
                    this.spinner.hide();
                });
            }
            else {
                //@ts-ignore
                reqData.id = this.createAcct.siteId,
                    //@ts-ignore
                    reqData.contactpersondetails[0].id = this.createAcct.influencerId;
                this.http.post(`/site/save`, reqData).subscribe(data => {
                    if (data.status == 200) {
                        Swal.fire({
                            icon: "success",
                            // title: reqData.id ? 'Account edited successfully' : 'Account created successfully',
                            title: 'Account Updated Successfully!',
                            timer: 150000,
                        });
                    }
                    this.reset();
                    this.find();
                    (jQuery("#createAccount") as any).modal("hide");
                    this.spinner.hide();
                }, err => {
                    Swal.fire({
                        title: err.error ? err.error.message : 'Error saving details',
                        timer: 150000,
                    });
                    this.spinner.hide();
                });

            }
        }

    }

    filterPincode() {
        let self = this;
        const data = this.pincodedata.filter(item => {
            if (item.cityName == this.createAcct.city && item.districtName == this.createAcct.district && item.locality == this.createAcct.locality && item.taluka == this.createAcct.taluka
            ) {
                self.createAcct.geographyMasterId = item.id
                return item
            }
        })
    }

    getDisable(name) {
        this.disableSAPCode = false
        // if (this.view || (this.edit && (name == 'CustomerType' || name == 'SAP') && (this.createAcct.customerGroupId ==2 || this.createAcct.divisionId ==3))) {
        if (this.view || (this.edit && ((name == 'SAPcode' || name == 'CustomerType') || (name == 'SAP' && this.createAcct.customerGroupId != 2 && this.createAcct.divisionId != 3)))) {
            return true;
        }
        else if (!this.view && !this.edit && name == 'PINCode') {
            if (this.createAcct.divisionId == null || this.createAcct.divisionId == "") {
                return true
            }
        }
        return false;
    }

    divisionSelected(id) {
        if (id) {
            this.createAcct.parentAccountCode = "";
            this.createAcct.sapCustomerCode = "";
            this.showAbcClassificationList = true;
            this.showCustomerTypeList = true;
            // this.getABCClassification(id);
            // this.getCustomerType(id);
            const val = id ? this.divisonList.find(o => o.id == id) : {};
            this.whiteDivision = val.value ? val.value.match(/white/gi) : false;
            if (this.whiteDivision) {
                this.dropdownSettings = {
                    singleSelection: false,
                    idField: 'userId',
                    textField: 'userName',
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 5,
                    allowSearchFilter: true
                }
            } else {
                this.dropdownSettings = {
                    singleSelection: true,
                    idField: 'userId',
                    textField: 'userName',
                    selectAllText: 'Select All',
                    unSelectAllText: 'UnSelect All',
                    itemsShowLimit: 3,
                    allowSearchFilter: true
                }
            }
            // this.setSAPCode();
        }
    }

    getDepartmentList() {
        this.http.get('/ctslead/getAllDepartment').subscribe(data => {
            if (data.status == '200') {
                this.departmentList = data.data;
            }
        })
    }

    getDepartmentId(department) {
        const departName = this.departmentList.filter(ele => ele.departmentName === department)
        return departName[0].id
    }

    getCategoryId(category) {
        const categoryId = this.SiteCategory.filter(ele => ele.value.name === category)
        return categoryId[0].value.id
    }

    editAccount(user, type) {
        this.reset();
        this.edit = type == 'edit' ? true : false;
        this.view = type == 'view' ? true : false;
        this.dropdownSettings.disabled = this.view;
        this.spinner.show();
        this.http.get(`/site/get-site-by-id/${user.id}`).subscribe(rdata => {
            this.spinner.hide();


            const data = { ...rdata.data };
            this.divisionSelected(data.divisionId);
            data.allAccountOwner = this.setPrimaryOwner(data.accountOwner, data.allAccountOwner);
            this.createAcct.categoryId = data.ctsSiteContactDetails[0].categoryId
            this.createAcct.city = data.address.city
            this.createAcct.constuctionStage = data.constuctionStage
            this.createAcct.degree = data.influencer_deg
            this.createAcct.district = data.address.district
            this.createAcct.divisionId = "3"
            this.createAcct.siteId = data.id,
                this.createAcct.influencerId = data.ctsSiteContactDetails[0].id,
                // this.createAcct.email = data.
                this.createAcct.infContact = data.ctsSiteContactDetails[0].ihbContatct
            this.createAcct.influName = data.ctsSiteContactDetails[0].ihbName
            this.createAcct.pincode = data.address.pinCode
            this.createAcct.primaryOwner = data.createdByName + "(" + data.createdById + ")"
            this.createAcct.siteContact = data.contact
            this.createAcct.siteName = data.name
            this.createAcct.state = data.address.state
            this.createAcct.status = 1;
            // console.log("edituser----data",data);
            // console.log("edituser",this.createAcct);

            // this.getAddressByPincode(data.address.pinCode, 'edit');
            //this.stateSelected(user.state);
            // this.checkMObileNum();
            ($('#createAccount') as any).modal('show');
        })

    }

    getCustomerTypeVal() {
        const val = this.customerType.find(o => o.id == this.createAcct.customerGroupId);
        return val ? val.value.match(/Stockist/gi) : false;
    }

    getSAPCode() {
        this.spinner.show();
        this.http.get(`/account-management/get-SAPCode`).subscribe(data => {
            // if (data.status == 200) {
            this.createAcct.sapCustomerCode = data;
            // }
            this.spinner.hide();
        });
    }

    preparePager() {
        this.pager = {
            itemsPerPage: this.perPage,
            currentPage: this.pageNumber,
            totalItems: this.usersList.length
        };
    }

    setSAPCode() {
        if (this.createAcct.customerGroupId != 1 && this.createAcct.customerGroupId != null && this.createAcct.divisionId == 3) {
            this.disableSAPCode = true;
            if (this.edit == false && this.createAcct.parentAccountCode != "") {
                this.getSAPCode();
            }
        } else {
            this.disableSAPCode = false;
        }
    }

    resetStockiestSAPCodeAndSAPCode() {
        this.setSAPCode();
        this.createAcct.sapCustomerCode = "";
        this.createAcct.parentAccountCode = "";
    }

    filters() {
        const data = this.accountData;
        let filter = false;
        Object.keys(data).forEach(key => {
            if (data[key]) {
                if (Array.isArray(data[key]) && data[key].length > 0) {
                    filter = true;
                }
                if (!Array.isArray(data[key])) {
                    filter = true;
                }
            }
        });
        return filter;
    }

    downloadExcelFormReset() {
        this.errMsg = ''
        this.downloadExcelFilter = {
            startDate: this.datepipe.transform(this.fromDate, 'yyyy-MM-dd'),
            endDate: this.datepipe.transform(this.toDate, 'yyyy-MM-dd'),
        }
    }

    filterObj(fd, td) {
        let url;
        if (this.filters()) {
            let filterParams = '?';
            Object.keys(this.accountData).forEach(key => {
                if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
                    if (!Array.isArray(this.accountData[key])) {
                        if (key == 'divisionId') {
                            filterParams += `divId=`;
                        } else {
                            filterParams += `${key}=`;
                        }
                        if (key == 'state') {
                            filterParams += `${this.findstatename(this.accountData[key])}&`;
                        } else {
                            filterParams += `${this.accountData[key]}&`;
                        }
                    } else {
                        if (this.accountData[key].length > 0) {
                            filterParams += `primaryowner=`;
                            Object.keys(this.accountData[key]).forEach(subkey => {
                                filterParams += `${this.accountData[key][subkey]['userId']},`;
                            });
                            //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
                            filterParams = filterParams.substring(0, filterParams.length - 1);
                            filterParams += `&`;
                        }
                    }
                }
            });
            // https://umber.jkcement.com/umber-rest/site/download-site?div=3&fromDate=2022-11-08&toDate=2022-11-09&state=Haryana&district=GURGAON&city&primaryowner
            filterParams = filterParams.substring(0, filterParams.length - 1);
            url = `${environment.BASE_ENDPOINT}/site/download-site${filterParams}&fromDate=${fd}&toDate=${td}`;
            return url
        }
        else {
            url = `${environment.BASE_ENDPOINT}/site/download-site?&fromDate=${fd}&toDate=${td}`;
            return url
        }

    }

    downloadExcelData() {
        let fd = this.downloadExcelFilter.startDate;
        let td = this.downloadExcelFilter.endDate;
        let FD = new Date(fd);
        let TD = new Date(td);
        let no_of_days;
        try {
            if (fd !== "" && td !== "") {
                if (FD.getTime() <= TD.getTime()) {
                    no_of_days = (TD.getTime() - FD.getTime()) / (1000 * 3600 * 24);
                    if (no_of_days <= 30) {
                        let url = this.filterObj(fd, td);
                        // window.location.href = environment.BASE_ENDPOINT+"/account-management/filter-download?fromDate="+fd+"&toDate="+td+"&div="+div+"&state="+stateId[0].value+"&city="+city+"&district="+district+"&primaryowner="+primaryowner;

                        window.location.href = url;

                        document.getElementById("downloadExcelClose").click();
                    }
                    else {
                        this.errMsg = "30 days limit exceed !"
                    }
                }
                else {
                    this.errMsg = "No records found in this time period!"
                }
            }
            else {
                this.errMsg = "All Fields are required!";
            }
        } catch (error) {
            console.error("Error", error);
        }

        // this.downloadExcelFormReset();

    }

    downloadFile(value?) {
        let url;
        if (!value && this.filters()) {
            let filterParams = '?';
            Object.keys(this.accountData).forEach(key => {
                if (this.accountData[key] || (Array.isArray(this.accountData[key]) && this.accountData[key].length)) {
                    if (!Array.isArray(this.accountData[key])) {
                        if (key == 'divisionId') {
                            filterParams += `div=`;
                        } else {
                            filterParams += `${key}=`;
                        }
                        if (key == 'state') {
                            filterParams += `${this.findstatename(this.accountData[key])}&`;
                        } else {
                            filterParams += `${this.accountData[key]}&`;
                        }
                    } else {
                        if (this.accountData[key].length > 0) {
                            filterParams += `primaryowner=`;
                            Object.keys(this.accountData[key]).forEach(subkey => {
                                filterParams += `${this.accountData[key][subkey]['userId']},`;
                            });
                            //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
                            filterParams = filterParams.substring(0, filterParams.length - 1);
                            filterParams += `&`;
                        }
                    }
                }
            });
            filterParams = filterParams.substring(0, filterParams.length - 1);
            url = `${environment.BASE_ENDPOINT}/account-management/filter-download${filterParams}`;
        } else {
            //https://umber.jkcement.com/umber-rest/lead-admin/lead-score-update-template-download
            // url = value ? `${environment.BASE_ENDPOINT}/account-management/download?key=${value}` :
            url = value ? `${environment.BASE_ENDPOINT}/lead-admin/lead-score-update-template-download` :
                `${environment.BASE_ENDPOINT}/lead-admin/lead-score-update-template-download`;
        }
        window.location.href = url;
    }

    findstatename(stateid) {
        let name = '';
        Object.keys(this.stateList).forEach(key => {
            if (this.stateList[key]['id'] == stateid) {
                name = this.stateList[key]['value'];
            }
        });
        return name;
    }

    getDivisionName(id) {
        if (this.divisonList.length && id) {
            const v = this.divisonList.find(val => val.id == id);
            return v ? v.value : id;
        }
        return id;
    }

    getCustomeTypeName(id) {
        if (this.customerType.length && id) {
            const v = this.customerType.find(val => val.id == id);
            return v ? v.value : id;
        }
        return id;
    }

    onFileSelect(event, type) {
        if (event.target.files.length > 0) {
            this.spinner.show();
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);
            this.http.post(`/lead-admin/checkUploadExcelForLeadScore`, formData).subscribe((res) => {
                if(res){
                    // https://umber.jkcement.com/umber-rest/lead-admin/uploadExcelForLeadScore
                    this.http.post(`/lead-admin/uploadExcelForLeadScore`, formData).subscribe((res) => {
                        this.spinner.hide();
                        Swal.fire({
                            icon:"success",
                            title: res.message === "succesfully updated lead score" ? "Succesfully Updated Lead Score" : res.message,
                            // html: str,
                            timer: 150000,
                        });
                        this.find();
        
                        // Resetting file input
                        event.target.value = "";
                    },
                        (err) => {
                            this.spinner.hide();
                            event.srcElement.value = null;
                            Swal.fire({
                                title: err.error ? err.error.message : 'Error saving account',
                                timer: 150000,
                            });
                        }
                    );
                }
                else{
                    this.spinner.hide();
                    Swal.fire({
                        icon:"error",
                        title: "Fields are empty in excel file.",
                        timer: 150000,
                    });
                    // Resetting file input
                    event.target.value = "";
                }
            })
        }
    }

    checkMObileNum() {
        if (String(this.createAcct.customerPhone).length > 9) {
            this.mobileError = false;
            return true;
            this.http.get(`/account-management/Check-Mob?mob=${this.createAcct.customerPhone}`).subscribe((res) => {
                const exists = 'Mobile Number Exists';
                if (res.message === exists) {
                    this.mobileError = true;
                }
            });
        }
        else {
            this.mobileError = true;
            return true;
        }
    }

    getAllOwners() {
        this.spinner.show();
        this.http.get(`/account-management/get-all-primaryowner`).subscribe((res) => {
            this.allOwnersList = this.objectToArrayOwner(res.data["user details"]);
            this.spinner.hide();
        });
    }

    getOwnerName(prmowner) {
        if (prmowner) {
            return prmowner.firstName + " " + prmowner.lastName + " (" + prmowner.employeeId + ")"
        }
        return '';
    }

    getOwnerTCode(id) {
        if (this.allOwnersList.length && id) {
            const arr = Array.isArray(id) ? id : id.split(',');
            const val = arr.map(o => {
                return this.allOwnersList.find(v => v.userId == o.userId) ? this.allOwnersList.find(v => v.userId == o.userId).territoryCode : o.userId;
            });
            return val;
        }
        return id;
    }

    find(event = null) {
        // state: "",
        // AO: "",
        // leadType: "",
        // leadValue: "",
        // zone: ""
        this.spinner.show();
        let filterParams = '?pageNo=' + (this.page - 1) + '&pageSize=' + this.itemsPerPage + '&zone=' + this.siteData.zone + '&state=' + this.siteData.state + '&leadType=' + this.siteData.leadType + '&leadValue=' + this.siteData.leadValue + '&AO='+ this.siteData.AO + '&';
        // // let filterParams = '?';
        // Object.keys(this.siteData).forEach(key => {
        //     if (this.siteData[key] || (Array.isArray(this.siteData[key]) && this.siteData[key].length)) {
        //         if (!Array.isArray(this.siteData[key])) {
        //             // if (key == 'divisionId') {
        //             //     filterParams += `divId=`;
        //             // } else {
        //                 filterParams += `${key}=`;
        //             // }
        //             // if (key == 'state') {
        //             //     // filterParams += `${this.findstatename(this.siteData[key])}&`;
        //             //     filterParams += `${this.siteData[key]}&`;
        //             // } else {
        //                 filterParams += `${this.siteData[key]}&`;
        //             // }
        //             //filterParams += `${this.siteData[key]}&`;
        //         } else {
        //             if (this.siteData[key].length > 0) {
        //                 filterParams += `primaryowner=`;
        //                 Object.keys(this.siteData[key]).forEach(subkey => {
        //                     filterParams += `${this.siteData[key][subkey]['userId']},`;
        //                 });
        //                 //filterParams = filterParams.substring(0, filterParams.lastIndexOf(" "));
        //                 filterParams = filterParams.substring(0, filterParams.length - 1);
        //                 filterParams += `&`;
        //             }
        //         }
        //     }
        // });
        filterParams = filterParams.substring(0, filterParams.length - 1);
        this.usersList = [];
        this.http.get(`/lead-admin/getAllLeads${filterParams}`).subscribe((res) => {

            this.usersList = res.reverse();
            // console.log("res",res);
            this.totalItems = res.length;
            this.spinner.hide();
        });
    }

    findFilter(event = null) {
        this.find();
    }

    resetFilters(event = null) {
        this.siteData = {
            state: '',
            leadType: "",
            AO: '',
            leadValue: '',
            zone: ''
        };
        this.stateList = [];
        this.leadValueList = [];
        this.rawJSON = [];
        this.find();
    }

    filterDropDown(type, val) {
        if (type == "edit") {
            this.CityChange(val, 'e');
            this.talukaChange(val, 'e');
            this.localityChange(val, 'e');
        }
        if (type == "dt") {
            this.CityChange(val);
            this.talukaChange(val);
            this.localityChange(val);
        }
        if (type == "ct") {
            this.talukaChange(val);
            this.localityChange(val);

        }
        if (type == "taluka") {
            this.localityChange(val);

        }

    }

    CityChange(val, type = null) {
        if (!type) {
            this.createAcct.city = '';
            this.createAcct.locality = '';
            this.createAcct.taluka = '';
        }
        if (this.createAcct.district) {
            this.ct = this.pincodedata.filter(ele => ele.districtName == this.createAcct.district);
            this.ct = [...new Map(this.ct.map(item =>
                [item.cityName, item])).values()];
            if (!type)
                this.createAcct.city = this.ct[0].cityName;
            // else
        }
    }
    talukaChange(val, type = null) {

        if (this.createAcct.city) {
            this.taluka = this.pincodedata.filter(ele => (ele.districtName == this.createAcct.district && ele.cityName == this.createAcct.city))
            this.taluka = [...new Map(this.taluka.map(item =>
                [item.taluka, item])).values()];
            if (!type)
                this.createAcct.taluka = this.taluka[0].taluka;
        }
    }
    localityChange(val, type = null) {

        if (this.createAcct.taluka) {
            this.locality = this.pincodedata.filter(ele => (ele.districtName == this.createAcct.district && ele.cityName == this.createAcct.city && ele.taluka == this.createAcct.taluka))
            this.locality = [...new Map(this.locality.map(item =>
                [item.locality, item])).values()];
            if (!type)
                this.createAcct.locality = this.locality[0].locality;
        }
    }

    getAddressByPincode(pincode, edituser = null) {
        if (pincode && String(pincode).length === 6 && (this.oldPincode != pincode) && (this.createAcct.divisionId = "3")) {
            this.spinner.show();
            this.oldPincode = pincode;
            // this.http.get(`/geography-master/get-by-pin-code/${this.createAcct.address.pinCode}`).subscribe((data) => {
            this.http.get(`/geography-master/get-by-pin-code/${pincode}/${this.createAcct.divisionId}`).subscribe((data) => {
                this.spinner.hide();
                if (data.status == '200') {
                    if (data.data.length == 0) {
                        this.pincodeError = 'Pincode Is Incorrect';
                    }
                    else {
                        this.pincodeError = '';
                        // this.pincodedata = data.data;
                        this.pincodedata = data.data;
                        this.dt = [...new Map(this.pincodedata.map(item =>
                            [item.districtName, item])).values()];

                        this.ct = [...new Map(this.pincodedata.map(item =>
                            [item.cityName, item])).values()];

                        this.taluka = [...new Map(this.pincodedata.map(item =>
                            [item.taluka, item])).values()];

                        this.locality = [...new Map(this.pincodedata.map(item =>
                            [item.locality, item])).values()];

                        if (!edituser) {
                            //this.createAcct.address.pincode = data.data[0].pinCode;
                            //this.createAcct.address = data.data[0];
                            // this.createAcct.address.pinCode = data.data[0].pinCode;
                            this.createAcct.state = data.data[0].stateName;
                            // this.stateSelected(data.data[0].stateCode);
                            this.createAcct.city = data.data[0].cityName;
                            this.createAcct.district = data.data[0].districtName;
                            this.createAcct.status = 1;
                            // this.createAcct.locality = data.data[0].locality;
                            // this.createAcct.taluka = data.data[0].taluka;
                            // this.createAcct.geographyMasterId = data.data[0].id;
                        }

                        //this.filterDropDown('dt',this.createAcct.address.district);
                        //this.filterDropDown('ct',this.createAcct.address.city);
                        //this.filterDropDown('taluka',this.createAcct.address.taluka);


                        if (edituser) {
                            this.createAcct.state = this.createAcct.state;
                            // this.stateSelected(data.data[0].stateCode);
                            this.createAcct.district = this.createAcct.district;
                            // this.createAcct.address.status = 1;

                            this.createAcct.city = this.createAcct.city;
                            // this.createAcct.locality = this.createAcct.locality;
                            // this.createAcct.taluka = this.createAcct.taluka;
                            this.filterDropDown('edit', this.createAcct.district);

                        }

                        let val = data.data[0].stateCode > 9 ? data.data[0].stateCode : '0' + data.data[0].stateCode;
                        this.getOwnerByState(val);
                    }
                }
            });
        }
    }

    getCheckSapCode(sapcode) {
        if (sapcode && String(sapcode).length >= 6) {
            this.spinner.show();
            this.http.get(`/account-management/Check-SAPCode?sapCode=${sapcode}`).subscribe((data) => {
                this.spinner.hide();
                if (data.message == 'SAP Code does not Exists') {
                    this.sapcodeError = 'SAP Code does not Exists';
                    this.sapcodeVError = true;
                } else {
                    this.sapcodeError = "";
                    this.setSAPCode();
                    this.sapcodeVError = false;
                }
            });
        }
    }

    onKeySearch(event: any) {
        this.page = 1;
        if (event.keyCode == 13) {
            this.find();
            this.timeout = null;
            return;
        }
        clearTimeout(this.timeout);
        var $this = this;
        this.timeout = setTimeout(function () {
            if (event.keyCode != 13) {
                $this.find();
            }
        }, 1000);
    }

    transferInfluencer() {
        if (this.transferList.createdBy !== "" || this.transferList.createdBy === null || this.transferList.createdBy === undefined) {
            this.spinner.show();//https://umber.jkcement.com/umber-rest/site/transfer-site?ids&createdBy
            this.http.put(`/site/transfer-site?ids=${this.transferList.ids}&createdBy=${this.transferList.createdBy}`).subscribe((data) => {
                this.spinner.hide();
                if (data.status === 200) {
                    Swal.fire({
                        icon: "success",
                        title: data.message,
                        timer: 150000,
                    });
                }
                // console.log("data",data);
            });
            (jQuery("#influencerTransfer") as any).modal("hide");
        }
    }

    addInfluencerInTheList(event, user) {
        if (this.transferList.ids.length == 0) {
            this.transferList.ids.push(user.id);
        }
        else if (this.transferList.ids.length > 0) {
            let exist = this.transferList.ids.filter(ele => ele === user.id);
            if (!exist[0]) {
                this.transferList.ids.push(user.id);
            }
            else {
                let indx = this.transferList.ids.indexOf(exist[0]);
                this.transferList.ids.splice(indx, 1)
            }
            // console.log("list", this.transferList);

        }
    }

    getTransferUserList() {
        this.spinner.show();
        this.http.get("/ctswhiteinfluencer/get-transfer-user").subscribe(data => {
            this.spinner.hide();
            if (data.status == 200) {
                this.userlist = data.data
                this.transferUserList = this.objectToArray(data.data);
            }
        });
    }

    openTransfer() {
        if (this.transferList.ids.length < 1) {
            Swal.fire({
                icon: "error",
                title: "Please select the site!",
                timer: 150000,
            });
        }
        else {
            // Swal.fire({
            //     icon:"success",
            //     title: "Transferred Successfully!",
            //     timer: 150000,
            // });
            (jQuery("#influencerTransfer") as any).modal("show");
        }
    }

    editScore(user) {
        this.createAcct.leadScore = user.leadScore ? user.leadScore : 0;
        this.leadId = user.id;
    }

    saveScore(user) {
        this.spinner.show();
            this.http.put(`/lead-admin/update-lead-scores/${user.id}`,{leadScore:this.createAcct.leadScore}).subscribe((data) => {
                if(data.status === 200){
                    Swal.fire({
                        icon:"success",
                        title: data.message,
                        timer: 150000,
                    });
                    this.find();
                    this.leadId = ""
                }
                else{
                    Swal.fire({
                        icon:"error",
                        title: data.message,
                        timer: 150000,
                    });
                }
                this.spinner.hide();
            });
    }

    disableInputField() {
        this.leadId = ""
    }

}
